import Axios from "axios";
const createApiFunctions = (baseURL: string, acceptLanguageValue?: string) => {
    const api = Axios.create({ withCredentials: true, baseURL, headers: acceptLanguageValue != null ? { "Accept-Language": acceptLanguageValue } : undefined });
    const apiFunctions = {
        Account: {
            LoginRedirect: Object.assign(
                (returnUrl: string) => api.get<any>(`/account/login`, { params: { returnUrl } }),
                { absoluteUrl: (returnUrl: string) => `${baseURL}/account/login?returnUrl=${returnUrl}`, requestSizeLimit: 3145728 }
            ),
            ResetMyPassword: Object.assign(
                (dto: server.dto.ResetMyPasswordInstruction) => api.post<server.dto.ResetMyPasswordOutcome>(`/account/ResetMyPassword`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/account/ResetMyPassword`, requestSizeLimit: 3145728 }
            ),
            Me: Object.assign(
                () => api.get<server.dto.UserSmall>(`/account/me`, undefined),
                { absoluteUrl: () => `${baseURL}/account/me`, requestSizeLimit: 3145728 }
            )
        },
        AdminOne: {
            IndexStorageAccount: Object.assign(
                () => api.get<any>(`/adminone/IndexStorageAccount`, undefined),
                { absoluteUrl: () => `${baseURL}/adminone/IndexStorageAccount`, requestSizeLimit: 3145728 }
            ),
            GenerateAzCopyScript: Object.assign(
                (sourceSasToken: string, destinationSasToken: string) => api.get<string>(`/adminone/GenerateAzCopyScript`, { params: { sourceSasToken, destinationSasToken } }),
                { absoluteUrl: (sourceSasToken: string, destinationSasToken: string) => `${baseURL}/adminone/GenerateAzCopyScript?sourceSasToken=${sourceSasToken}&destinationSasToken=${destinationSasToken}`, requestSizeLimit: 3145728 }
            ),
            DuplicateCompanyFilesAfterTransferCommand: Object.assign(
                () => api.get<string>(`/adminone/DuplicateFilesAfterTransfer/company`, undefined),
                { absoluteUrl: () => `${baseURL}/adminone/DuplicateFilesAfterTransfer/company`, requestSizeLimit: 3145728 }
            ),
            DuplicateContactFilesAfterTransferCommand: Object.assign(
                () => api.get<string>(`/adminone/DuplicateFilesAfterTransfer/contact`, undefined),
                { absoluteUrl: () => `${baseURL}/adminone/DuplicateFilesAfterTransfer/contact`, requestSizeLimit: 3145728 }
            ),
            DuplicateShowFilesAfterTransferCommand: Object.assign(
                () => api.get<string>(`/adminone/DuplicateFilesAfterTransfer/show`, undefined),
                { absoluteUrl: () => `${baseURL}/adminone/DuplicateFilesAfterTransfer/show`, requestSizeLimit: 3145728 }
            ),
            DuplicateFlightFilesAfterTransferCommand: Object.assign(
                () => api.get<string>(`/adminone/DuplicateFilesAfterTransfer/flight`, undefined),
                { absoluteUrl: () => `${baseURL}/adminone/DuplicateFilesAfterTransfer/flight`, requestSizeLimit: 3145728 }
            ),
            DuplicateTrainFilesAfterTransferCommand: Object.assign(
                () => api.get<string>(`/adminone/DuplicateFilesAfterTransfer/train`, undefined),
                { absoluteUrl: () => `${baseURL}/adminone/DuplicateFilesAfterTransfer/train`, requestSizeLimit: 3145728 }
            ),
            DuplicateDocumentFilesAfterTransferCommand: Object.assign(
                () => api.get<string>(`/adminone/DuplicateFilesAfterTransfer/document`, undefined),
                { absoluteUrl: () => `${baseURL}/adminone/DuplicateFilesAfterTransfer/document`, requestSizeLimit: 3145728 }
            ),
            GetRedisPing: Object.assign(
                () => api.get<string>(`/adminone/redis/ping`, undefined),
                { absoluteUrl: () => `${baseURL}/adminone/redis/ping`, requestSizeLimit: 3145728 }
            )
        },
        Advancing: {
            AcceptInvitation: Object.assign(
                (secret: string) => api.get<any>(`/advancing/invitation/${secret}/accept`, undefined),
                { absoluteUrl: (secret: string) => `${baseURL}/advancing/invitation/${secret}/accept`, requestSizeLimit: 3145728 }
            ),
            DeleteRecipient: Object.assign(
                (advancingInvitationId: number) => api.post<server.dto.AdvancingRequest>(`/advancing/invitations/${advancingInvitationId}/delete`, undefined, undefined),
                { absoluteUrl: (advancingInvitationId: number) => `${baseURL}/advancing/invitations/${advancingInvitationId}/delete`, requestSizeLimit: 3145728 }
            ),
            InviteRecipients: Object.assign(
                (dto: server.dto.InviteToAdvancingInstruction) => api.post<server.dto.AdvancingRequest>(`/advancing/invitations/addRecipient`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/advancing/invitations/addRecipient`, requestSizeLimit: 3145728 }
            ),
            MessageSeen: Object.assign(
                (advancingMessageId: number) => api.put<server.dto.AdvancingMessage>(`/advancing/messages/${advancingMessageId}/seen`, undefined, undefined),
                { absoluteUrl: (advancingMessageId: number) => `${baseURL}/advancing/messages/${advancingMessageId}/seen`, requestSizeLimit: 3145728 }
            ),
            AddMessage: Object.assign(
                (command: server.dto.SendAdvancingMessageInstruction) => api.post<server.dto.AdvancingMessage>(`/advancing/messages/add`, command, undefined),
                { absoluteUrl: () => `${baseURL}/advancing/messages/add`, requestSizeLimit: 3145728 }
            ),
            DeleteAdvancingRequest: Object.assign(
                (requestId: number) => api.delete(`/advancing/requests/${requestId}`, undefined),
                { absoluteUrl: (requestId: number) => `${baseURL}/advancing/requests/${requestId}`, requestSizeLimit: 3145728 }
            ),
            RemoveFormItem: Object.assign(
                (dto: server.dto.RemoveAdvancingFormItemInstruction) => api.post<server.dto.AdvancingRequest>(`/advancing/RemoveFormItem`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/advancing/RemoveFormItem`, requestSizeLimit: 3145728 }
            ),
            CreateAdvancingRequest: Object.assign(
                (dto: server.dto.CreateAdvancingRequestInstruction) => api.post<server.dto.Outcome>(`/advancing/requests/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/advancing/requests/create`, requestSizeLimit: 3145728 }
            ),
            ListForShow: Object.assign(
                (dto: server.dto.ListAdvancingForShowInstruction) => api.post<server.dto.PaginationWrapper<server.dto.AdvancingForList[]>>(`/advancing/listForShow`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/advancing/listForShow`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListAdvancingInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.AdvancingForList[], server.dto.ListAdvancingFilters>>(`/advancing/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/advancing/list`, requestSizeLimit: 3145728 }
            )
        },
        AdvancingFormTemplate: {
            Paginated: Object.assign(
                (dto: server.dto.ListAdvancingTemplateInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.AdvancingFormTemplateForList[], server.dto.ListAdvancingTemplateFilters>>(`/advancingformtemplate/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/advancingformtemplate/list`, requestSizeLimit: 3145728 }
            ),
            GetTemplate: Object.assign(
                (advancingFormTemplateId: number) => api.get<server.dto.AdvancingFormTemplate>(`/advancingformtemplate/${advancingFormTemplateId}`, undefined),
                { absoluteUrl: (advancingFormTemplateId: number) => `${baseURL}/advancingformtemplate/${advancingFormTemplateId}`, requestSizeLimit: 3145728 }
            ),
            CreateTemplate: Object.assign(
                (dto: server.dto.CreateAdvancingFormTemplateInstruction) => api.post<server.dto.AdvancingFormTemplate>(`/advancingformtemplate/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/advancingformtemplate/create`, requestSizeLimit: 3145728 }
            ),
            UpdateTemplate: Object.assign(
                (dto: server.dto.UpdateAdvancingFormTemplateInstruction) => api.post<server.dto.AdvancingFormTemplate>(`/advancingformtemplate/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/advancingformtemplate/update`, requestSizeLimit: 3145728 }
            ),
            DeleteTemplate: Object.assign(
                (advancingFormTemplateId: number) => api.delete(`/advancingformtemplate/${advancingFormTemplateId}`, undefined),
                { absoluteUrl: (advancingFormTemplateId: number) => `${baseURL}/advancingformtemplate/${advancingFormTemplateId}`, requestSizeLimit: 3145728 }
            )
        },
        AdvancingResponse: {
            Approve: Object.assign(
                (instruction: server.dto.ApproveAdvancingResponseInstruction) => api.post<server.dto.AdvancingResponse>(`/advancingresponse/approve`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/advancingresponse/approve`, requestSizeLimit: 3145728 }
            ),
            Decline: Object.assign(
                (instruction: server.dto.DeclineAdvancingResponseInstruction) => api.post<server.dto.AdvancingResponse>(`/advancingresponse/decline`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/advancingresponse/decline`, requestSizeLimit: 3145728 }
            ),
            Provide: Object.assign(
                (instruction: server.dto.ProvideAdvancingResponsesInstruction) => api.post<server.dto.AdvancingResponse[]>(`/advancingresponse/provide`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/advancingresponse/provide`, requestSizeLimit: 3145728 }
            )
        },
        AgeRestriction: {
            List: Object.assign(
                () => api.get<server.dto.AgeRestriction[]>(`/agerestriction/list`, undefined),
                { absoluteUrl: () => `${baseURL}/agerestriction/list`, requestSizeLimit: 3145728 }
            )
        },
        Airline: {
            List: Object.assign(
                (dto: server.dto.ListAirlineInstruction) => api.post<server.dto.PaginationWrapper<server.dto.Airline[]>>(`/airline/autocomplete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/airline/autocomplete`, requestSizeLimit: 3145728 }
            )
        },
        Airport: {
            GetAirport: Object.assign(
                (airportId: number) => api.get<server.dto.Airport>(`/airport/${airportId}`, undefined),
                { absoluteUrl: (airportId: number) => `${baseURL}/airport/${airportId}`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListAirportInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.Airport[], server.dto.ListAirportFilters>>(`/airport/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/airport/list`, requestSizeLimit: 3145728 }
            ),
            PaginatedSmall: Object.assign(
                (dto: server.dto.ListAirportInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.AirportSmall[], server.dto.ListAirportFilters>>(`/airport/listSmall`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/airport/listSmall`, requestSizeLimit: 3145728 }
            )
        },
        ArticleCategory: {
            Paginated: Object.assign(
                (dto: server.dto.ListArticleCategoryInstruction, section: server.dto.PromoSiteSections) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ArticleCategory[], server.dto.ListArticleCategoryFilters>>(`/articlecategory/${section}/list`, dto, undefined),
                { absoluteUrl: (section: server.dto.PromoSiteSections) => `${baseURL}/articlecategory/${section}/list`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (section: server.dto.PromoSiteSections) => api.put<server.dto.CreateArticleCategoryOutcome>(`/articlecategory/create`, undefined, { params: { section } }),
                { absoluteUrl: (section: server.dto.PromoSiteSections) => `${baseURL}/articlecategory/create?section=${section}`, requestSizeLimit: 3145728 }
            ),
            Save: Object.assign(
                (dto: server.dto.SaveArticleCategoryInstruction) => api.post<server.dto.Outcome>(`/articlecategory/save`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/articlecategory/save`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (articleCategoryId: number) => api.delete(`/articlecategory/${articleCategoryId}/delete`, undefined),
                { absoluteUrl: (articleCategoryId: number) => `${baseURL}/articlecategory/${articleCategoryId}/delete`, requestSizeLimit: 3145728 }
            ),
            DeleteTranslation: Object.assign(
                (articleCategoryId: number, language: server.dto.Languages) => api.delete(`/articlecategory/${articleCategoryId}/${language}/delete`, undefined),
                { absoluteUrl: (articleCategoryId: number, language: server.dto.Languages) => `${baseURL}/articlecategory/${articleCategoryId}/${language}/delete`, requestSizeLimit: 3145728 }
            )
        },
        Article: {
            Paginated: Object.assign(
                (dto: server.dto.ListArticleInstruction, section: server.dto.PromoSiteSections) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.Article[], server.dto.ListArticleFilters>>(`/article/${section}/list`, dto, undefined),
                { absoluteUrl: (section: server.dto.PromoSiteSections) => `${baseURL}/article/${section}/list`, requestSizeLimit: 3145728 }
            ),
            SupportByTopic: Object.assign(
                (topic: server.dto.ArticleTopics) => api.post<server.dto.ArticleTranslation[]>(`/article/SupportByTopic`, undefined, { params: { topic } }),
                { absoluteUrl: (topic: server.dto.ArticleTopics) => `${baseURL}/article/SupportByTopic?topic=${topic}`, requestSizeLimit: 3145728 }
            ),
            GetArticle: Object.assign(
                (articleId: number) => api.get<server.dto.Article>(`/article/${articleId}`, undefined),
                { absoluteUrl: (articleId: number) => `${baseURL}/article/${articleId}`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (dto: server.dto.CreateArticleInstruction) => api.post<server.dto.CreateOutcome>(`/article/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/article/create`, requestSizeLimit: 3145728 }
            ),
            Save: Object.assign(
                (dto: server.dto.SaveArticleTranslationInstruction) => api.post<server.dto.Outcome>(`/article/save`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/article/save`, requestSizeLimit: 3145728 }
            ),
            SetListImage: Object.assign(
                (articleId: number, listImageId: string) => api.post<any>(`/article/${articleId}/list/image`, undefined, { params: { listImageId } }),
                { absoluteUrl: (articleId: number, listImageId: string) => `${baseURL}/article/${articleId}/list/image?listImageId=${listImageId}`, requestSizeLimit: 3145728 }
            ),
            SetHeaderImage: Object.assign(
                (articleId: number, headerImageId: string) => api.post<any>(`/article/${articleId}/header/image`, undefined, { params: { headerImageId } }),
                { absoluteUrl: (articleId: number, headerImageId: string) => `${baseURL}/article/${articleId}/header/image?headerImageId=${headerImageId}`, requestSizeLimit: 3145728 }
            ),
            SetHeaderBackgroundHexColor: Object.assign(
                (articleId: number, hexColor: string) => api.post<any>(`/article/${articleId}/header/hexColor`, undefined, { params: { hexColor } }),
                { absoluteUrl: (articleId: number, hexColor: string) => `${baseURL}/article/${articleId}/header/hexColor?hexColor=${hexColor}`, requestSizeLimit: 3145728 }
            ),
            SoftDelete: Object.assign(
                (articleId: number) => api.delete(`/article/${articleId}`, undefined),
                { absoluteUrl: (articleId: number) => `${baseURL}/article/${articleId}`, requestSizeLimit: 3145728 }
            ),
            DeleteTranslation: Object.assign(
                (dto: server.dto.DeleteArticleTranslationInstruction) => api.post<any>(`/article/translation/delete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/article/translation/delete`, requestSizeLimit: 3145728 }
            ),
            UploadArticleImage: Object.assign(
                (instruction: server.dto.UploadArticleImageInstruction) => api.post<server.dto.FileUploadResult>(`/article/imageupload`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/article/imageupload`, requestSizeLimit: 10485760 }
            )
        },
        Artist: {
            Paginated: Object.assign(
                (dto: server.dto.ListArtistInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ArtistForList[], server.dto.ListArtistFilters>>(`/artist/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/artist/list`, requestSizeLimit: 3145728 }
            ),
            PaginatedSmall: Object.assign(
                (dto: server.dto.ListArtistInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ArtistSmall[], server.dto.ListArtistFilters>>(`/artist/listSmall`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/artist/listSmall`, requestSizeLimit: 3145728 }
            )
        },
        ArtistRider: {
            Delete: Object.assign(
                (artistRiderId: number) => api.delete(`/artistrider/${artistRiderId}`, undefined),
                { absoluteUrl: (artistRiderId: number) => `${baseURL}/artistrider/${artistRiderId}`, requestSizeLimit: 3145728 }
            ),
            Copy: Object.assign(
                (artistRiderId: number) => api.post<number>(`/artistrider/${artistRiderId}/copy`, undefined, undefined),
                { absoluteUrl: (artistRiderId: number) => `${baseURL}/artistrider/${artistRiderId}/copy`, requestSizeLimit: 3145728 }
            ),
            DownloadZip: Object.assign(
                () => api.get<any>(`/artistrider/download/all`, undefined),
                { absoluteUrl: () => `${baseURL}/artistrider/download/all`, requestSizeLimit: 3145728 }
            ),
            EraseHistory: Object.assign(
                (artistRiderId: number) => api.get<any>(`/artistrider/${artistRiderId}/history/erase`, undefined),
                { absoluteUrl: (artistRiderId: number) => `${baseURL}/artistrider/${artistRiderId}/history/erase`, requestSizeLimit: 3145728 }
            ),
            DownloadFile: Object.assign(
                (fileId: string, fileName: string) => api.get<any>(`/artistrider/${fileId}/download/${fileName}`, undefined),
                { absoluteUrl: (fileId: string, fileName: string) => `${baseURL}/artistrider/${fileId}/download/${fileName}`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListArtistRiderInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ArtistRiderForList[], server.dto.ListArtistRiderFilters>>(`/artistrider/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/artistrider/list`, requestSizeLimit: 3145728 }
            )
        },
        Authentication: {
            SignInWithCredentials: Object.assign(
                (credentials: server.dto.SignInCredentials) => api.post<server.dto.Outcome>(`/authentication/signin`, credentials, undefined),
                { absoluteUrl: () => `${baseURL}/authentication/signin`, requestSizeLimit: 3145728 }
            ),
            Signout: Object.assign(
                (returnUrl: string) => api.get<any>(`/authentication/signout`, { params: { returnUrl } }),
                { absoluteUrl: (returnUrl: string) => `${baseURL}/authentication/signout?returnUrl=${returnUrl}`, requestSizeLimit: 3145728 }
            ),
            Impersonate: Object.assign(
                (instruction: server.dto.ImpersonateInstruction) => api.post<server.dto.Outcome>(`/authentication/impersonate`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/authentication/impersonate`, requestSizeLimit: 3145728 }
            )
        },
        Availability: {
            GetAvailability: Object.assign(
                (dto: server.dto.ListAvailableDatesInstruction) => api.post<server.dto.ApiResponseWrapper<server.dto.AvailableDatesPerArtist[]>>(`/availability`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/availability`, requestSizeLimit: 3145728 }
            )
        },
        BankAccount: {
            DeleteBankAccount: Object.assign(
                (bankAccountId: number) => api.get<any>(`/bankaccount/${bankAccountId}/delete`, undefined),
                { absoluteUrl: (bankAccountId: number) => `${baseURL}/bankaccount/${bankAccountId}/delete`, requestSizeLimit: 3145728 }
            ),
            UpdateBankAccount: Object.assign(
                (dto: server.dto.UpdateBankAccountInstruction) => api.post<server.dto.Outcome>(`/bankaccount/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/bankaccount/update`, requestSizeLimit: 3145728 }
            ),
            CreateBankAccount: Object.assign(
                (dto: server.dto.CreateBankAccountInstruction) => api.post<server.dto.CreateOutcome>(`/bankaccount/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/bankaccount/create`, requestSizeLimit: 3145728 }
            ),
            GetBankAccount: Object.assign(
                (bankAccountId: number) => api.get<server.dto.BankAccount>(`/bankaccount/${bankAccountId}`, undefined),
                { absoluteUrl: (bankAccountId: number) => `${baseURL}/bankaccount/${bankAccountId}`, requestSizeLimit: 3145728 }
            ),
            ListBankAccount: Object.assign(
                (dto: server.dto.ListBankAccountInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.BankAccount[], server.dto.ListBankAccountFilters>>(`/bankaccount/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/bankaccount/list`, requestSizeLimit: 3145728 }
            ),
            ListBankAccountSmall: Object.assign(
                (dto: server.dto.ListBankAccountInstruction) => api.post<server.dto.PaginationWrapper<server.dto.BankAccountSmall[]>>(`/bankaccount/listSmall`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/bankaccount/listSmall`, requestSizeLimit: 3145728 }
            )
        },
        Bookkeeping: {
            GetBookkeepings: Object.assign(
                () => api.get<server.dto.BookkeepingSmall[]>(`/bookkeeping/list`, undefined),
                { absoluteUrl: () => `${baseURL}/bookkeeping/list`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (dto: server.dto.CreateBookkeepingInstruction) => api.post<server.dto.Bookkeeping>(`/bookkeeping/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/bookkeeping/create`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (bookkeepingId: number) => api.delete(`/bookkeeping/${bookkeepingId}/delete`, undefined),
                { absoluteUrl: (bookkeepingId: number) => `${baseURL}/bookkeeping/${bookkeepingId}/delete`, requestSizeLimit: 3145728 }
            ),
            GetItem: Object.assign(
                (bookkeepingId: number) => api.get<server.dto.Bookkeeping>(`/bookkeeping/${bookkeepingId}`, undefined),
                { absoluteUrl: (bookkeepingId: number) => `${baseURL}/bookkeeping/${bookkeepingId}`, requestSizeLimit: 3145728 }
            ),
            CodeExists: Object.assign(
                (code: string, bookkeepingId: number) => api.get<boolean>(`/bookkeeping/codeexists`, { params: { code, bookkeepingId } }),
                { absoluteUrl: (code: string, bookkeepingId: number) => `${baseURL}/bookkeeping/codeexists?code=${code}&bookkeepingId=${bookkeepingId}`, requestSizeLimit: 3145728 }
            ),
            GetExchangeRate: Object.assign(
                (bookkeepingId: number, currency: server.dto.Currencies) => api.get<server.dto.ExchangeRate>(`/bookkeeping/${bookkeepingId}/exchangerate`, { params: { currency } }),
                { absoluteUrl: (bookkeepingId: number, currency: server.dto.Currencies) => `${baseURL}/bookkeeping/${bookkeepingId}/exchangerate?currency=${currency}`, requestSizeLimit: 3145728 }
            ),
            GetCurrency: Object.assign(
                (bookkeepingId: number) => api.get<server.dto.Currency>(`/bookkeeping/${bookkeepingId}/currency`, undefined),
                { absoluteUrl: (bookkeepingId: number) => `${baseURL}/bookkeeping/${bookkeepingId}/currency`, requestSizeLimit: 3145728 }
            ),
            SetLogo: Object.assign(
                (instruction: server.dto.SetBookkeepingLogoInstruction) => api.post<server.dto.FileUploadResult>(`/bookkeeping/logo/set`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/bookkeeping/logo/set`, requestSizeLimit: 10485760 }
            ),
            GetLogo: Object.assign(
                (bookkeepingId: number) => api.get<server.dto.BookkeepingLogoFile>(`/bookkeeping/${bookkeepingId}/logo`, undefined),
                { absoluteUrl: (bookkeepingId: number) => `${baseURL}/bookkeeping/${bookkeepingId}/logo`, requestSizeLimit: 3145728 }
            ),
            UpdateDataExportConfig: Object.assign(
                (bookkeepingId: number, data: server.dto.SalesDataExportConfig) => api.post<server.dto.SalesDataExportConfig>(`/bookkeeping/${bookkeepingId}/dataexport/config`, data, undefined),
                { absoluteUrl: (bookkeepingId: number) => `${baseURL}/bookkeeping/${bookkeepingId}/dataexport/config`, requestSizeLimit: 3145728 }
            )
        },
        Brand: {
            DeleteBrand: Object.assign(
                (brandId: number) => api.get<server.dto.Outcome>(`/brand/${brandId}/delete`, undefined),
                { absoluteUrl: (brandId: number) => `${baseURL}/brand/${brandId}/delete`, requestSizeLimit: 3145728 }
            ),
            CreateBrand: Object.assign(
                () => api.post<server.dto.CreateOutcome>(`/brand/create`, undefined, undefined),
                { absoluteUrl: () => `${baseURL}/brand/create`, requestSizeLimit: 3145728 }
            ),
            GetBrand: Object.assign(
                (brandId: number) => api.get<server.dto.Brand>(`/brand/${brandId}`, undefined),
                { absoluteUrl: (brandId: number) => `${baseURL}/brand/${brandId}`, requestSizeLimit: 3145728 }
            ),
            List: Object.assign(
                (dto: server.dto.ListBrandInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.Brand[], server.dto.ListBrandFilters>>(`/brand/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/brand/list`, requestSizeLimit: 3145728 }
            ),
            ListBrandSmall: Object.assign(
                (dto: server.dto.ListBrandInstruction) => api.post<server.dto.BrandSmall[]>(`/brand/list/small`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/brand/list/small`, requestSizeLimit: 3145728 }
            ),
            SetLogo: Object.assign(
                (instruction: server.dto.SetBrandLogoInstruction) => api.post<server.dto.FileUploadResult>(`/brand/logo/set`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/brand/logo/set`, requestSizeLimit: 10485760 }
            ),
            SetIcon: Object.assign(
                (instruction: server.dto.SetBrandIconInstruction) => api.post<server.dto.FileUploadResult>(`/brand/icon/set`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/brand/icon/set`, requestSizeLimit: 10485760 }
            ),
            SetName: Object.assign(
                (dto: server.dto.SetBrandNameInstruction) => api.post<server.dto.Outcome>(`/brand/name/set`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/brand/name/set`, requestSizeLimit: 3145728 }
            ),
            SetFriendlyId: Object.assign(
                (dto: server.dto.SetBrandFriendlyIdInstruction) => api.post<server.dto.Outcome>(`/brand/friendlyid/set`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/brand/friendlyid/set`, requestSizeLimit: 3145728 }
            ),
            SetWebsite: Object.assign(
                (dto: server.dto.SetBrandWebsiteInstruction) => api.post<server.dto.Outcome>(`/brand/website/set`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/brand/website/set`, requestSizeLimit: 3145728 }
            ),
            SetAsDefaultForClient: Object.assign(
                (brandId: number) => api.post<void>(`/brand/${brandId}/default`, undefined, undefined),
                { absoluteUrl: (brandId: number) => `${baseURL}/brand/${brandId}/default`, requestSizeLimit: 3145728 }
            ),
            SetESignBackgroundFooterColor: Object.assign(
                (dto: server.dto.SetBrandESignFooterBackgroundColorInstruction) => api.post<server.dto.Outcome>(`/brand/eSignBackgroundFooterColor/set`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/brand/eSignBackgroundFooterColor/set`, requestSizeLimit: 3145728 }
            )
        },
        BusinessCentral: {
            GetCustomer: Object.assign(
                (number: string) => api.get<server.dto.ApiResponseWrapper<server.dto.BusinessCentralCustomer>>(`/businesscentral/GetCustomer`, { params: { number } }),
                { absoluteUrl: (number: string) => `${baseURL}/businesscentral/GetCustomer?number=${number}`, requestSizeLimit: 3145728 }
            ),
            ListProjects: Object.assign(
                (dto: server.dto.ListBusinessCentralProjectInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.BusinessCentralProject[], server.dto.ListBusinessCentralProjectFilters>>(`/businesscentral/ListProjects`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/businesscentral/ListProjects`, requestSizeLimit: 3145728 }
            ),
            CreateProject: Object.assign(
                (dto: server.dto.BusinessCentralProjectMutate) => api.post<server.dto.Outcome>(`/businesscentral/CreateProject`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/businesscentral/CreateProject`, requestSizeLimit: 3145728 }
            ),
            UpdateProject: Object.assign(
                (dto: server.dto.BusinessCentralProjectMutate) => api.post<server.dto.Outcome>(`/businesscentral/UpdateProject`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/businesscentral/UpdateProject`, requestSizeLimit: 3145728 }
            ),
            SyncBusinessCentralCustomers: Object.assign(
                () => api.get<any>(`/businesscentral/SyncCustomers`, undefined),
                { absoluteUrl: () => `${baseURL}/businesscentral/SyncCustomers`, requestSizeLimit: 3145728 }
            ),
            SyncBusinessCentralCostCenters: Object.assign(
                () => api.get<any>(`/businesscentral/SyncCostCenters`, undefined),
                { absoluteUrl: () => `${baseURL}/businesscentral/SyncCostCenters`, requestSizeLimit: 3145728 }
            ),
            SyncBusinessCentralProjects: Object.assign(
                () => api.get<any>(`/businesscentral/SyncProjects`, undefined),
                { absoluteUrl: () => `${baseURL}/businesscentral/SyncProjects`, requestSizeLimit: 3145728 }
            )
        },
        Checklist: {
            SetDocumentChecklistItemState: Object.assign(
                (dto: server.dto.SetDocumentChecklistItemStateInstruction) => api.post<any>(`/checklist/document/setState`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/checklist/document/setState`, requestSizeLimit: 3145728 }
            ),
            ListDocumentChecklistItems: Object.assign(
                (documentId: number) => api.post<server.dto.DocumentChecklistItem[]>(`/checklist/document/${documentId}/list`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/checklist/document/${documentId}/list`, requestSizeLimit: 3145728 }
            ),
            DeleteDocumentChecklistItem: Object.assign(
                (documentChecklistItemId: number) => api.post<any>(`/checklist/document/deleteItem`, undefined, { params: { documentChecklistItemId } }),
                { absoluteUrl: (documentChecklistItemId: number) => `${baseURL}/checklist/document/deleteItem?documentChecklistItemId=${documentChecklistItemId}`, requestSizeLimit: 3145728 }
            ),
            WhoCanReviewDocumentChecklistItem: Object.assign(
                (documentChecklistItemId: number) => api.post<server.dto.WhoCanReviewDocumentChecklistItem>(`/checklist/document/${documentChecklistItemId}/whoCanReview`, undefined, undefined),
                { absoluteUrl: (documentChecklistItemId: number) => `${baseURL}/checklist/document/${documentChecklistItemId}/whoCanReview`, requestSizeLimit: 3145728 }
            )
        },
        ChecklistPreset: {
            DeleteChecklistPresetItem: Object.assign(
                (checklistPresetItemId: number) => api.get<any>(`/checklistpreset/item/${checklistPresetItemId}/delete`, undefined),
                { absoluteUrl: (checklistPresetItemId: number) => `${baseURL}/checklistpreset/item/${checklistPresetItemId}/delete`, requestSizeLimit: 3145728 }
            ),
            UpdateChecklistPresetItem: Object.assign(
                (dto: server.dto.UpdateChecklistPresetItemInstruction) => api.post<server.dto.Outcome>(`/checklistpreset/item/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/checklistpreset/item/update`, requestSizeLimit: 3145728 }
            ),
            CreateChecklistPresetItem: Object.assign(
                (dto: server.dto.CreateChecklistPresetItemInstruction) => api.post<server.dto.CreateOutcome>(`/checklistpreset/item/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/checklistpreset/item/create`, requestSizeLimit: 3145728 }
            ),
            ListChecklistPresetItem: Object.assign(
                (checklistArea: server.dto.ChecklistAreas) => api.post<server.dto.ChecklistPresetItem[]>(`/checklistpreset/item/list`, undefined, { params: { checklistArea } }),
                { absoluteUrl: (checklistArea: server.dto.ChecklistAreas) => `${baseURL}/checklistpreset/item/list?checklistArea=${checklistArea}`, requestSizeLimit: 3145728 }
            ),
            UpdateChecklistPresetItemOrder: Object.assign(
                (dto: server.dto.UpdateChecklistPresetItemOrderInstruction) => api.post<any>(`/checklistpreset/item/sort`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/checklistpreset/item/sort`, requestSizeLimit: 3145728 }
            )
        },
        ClientConfiguration: {
            Update: Object.assign(
                (dto: server.dto.UpdateClientConfigurationInstruction) => api.post<server.dto.Outcome>(`/clientconfiguration/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/clientconfiguration/update`, requestSizeLimit: 3145728 }
            )
        },
        Client: {
            UpdateAccess: Object.assign(
                (clientId: number, access: server.dto.ClientAccesses) => api.post<server.dto.ApiResponseWrapper<server.dto.Client>>(`/client/${clientId}/access`, undefined, { params: { access } }),
                { absoluteUrl: (clientId: number, access: server.dto.ClientAccesses) => `${baseURL}/client/${clientId}/access?access=${access}`, requestSizeLimit: 3145728 }
            ),
            ResetAccess: Object.assign(
                (clientId: number) => api.post<server.dto.ApiResponseWrapper<server.dto.Client>>(`/client/${clientId}/access/reset`, undefined, undefined),
                { absoluteUrl: (clientId: number) => `${baseURL}/client/${clientId}/access/reset`, requestSizeLimit: 3145728 }
            ),
            SetClientNextSubscription: Object.assign(
                (clientId: number, dto: server.dto.UpdateSubscription) => api.post<server.dto.ApiResponseWrapper<server.dto.Client>>(`/client/${clientId}/subscriptions/next`, dto, undefined),
                { absoluteUrl: (clientId: number) => `${baseURL}/client/${clientId}/subscriptions/next`, requestSizeLimit: 3145728 }
            ),
            UpdatePayPerInvoiceAllowed: Object.assign(
                (clientId: number, allowed: boolean) => api.post<server.dto.ClientBillingDetails>(`/client/${clientId}/payPerInvoice`, undefined, { params: { allowed } }),
                { absoluteUrl: (clientId: number, allowed: boolean) => `${baseURL}/client/${clientId}/payPerInvoice?allowed=${allowed}`, requestSizeLimit: 3145728 }
            ),
            UpdatePayPerSepaAllowed: Object.assign(
                (clientId: number, allowed: boolean) => api.post<server.dto.ClientBillingDetails>(`/client/${clientId}/payPerSepa`, undefined, { params: { allowed } }),
                { absoluteUrl: (clientId: number, allowed: boolean) => `${baseURL}/client/${clientId}/payPerSepa?allowed=${allowed}`, requestSizeLimit: 3145728 }
            ),
            DeleteVatRateFromClient: Object.assign(
                (clientId: number, vatRateId: number) => api.delete(`/client/${clientId}/vatrates/${vatRateId}`, undefined),
                { absoluteUrl: (clientId: number, vatRateId: number) => `${baseURL}/client/${clientId}/vatrates/${vatRateId}`, requestSizeLimit: 3145728 }
            ),
            AddVatRateToClient: Object.assign(
                (clientId: number, vatRateId: number) => api.put<server.dto.ApiResponseWrapper<server.dto.VatRate[]>>(`/client/${clientId}/vatrates`, undefined, { params: { vatRateId } }),
                { absoluteUrl: (clientId: number, vatRateId: number) => `${baseURL}/client/${clientId}/vatrates?vatRateId=${vatRateId}`, requestSizeLimit: 3145728 }
            ),
            UpdateActiveSubscription: Object.assign(
                (clientId: number, dto: server.dto.UpdateSubscription) => api.post<server.dto.ApiResponseWrapper<server.dto.Client>>(`/client/${clientId}/subscriptions/updateactive`, dto, undefined),
                { absoluteUrl: (clientId: number) => `${baseURL}/client/${clientId}/subscriptions/updateactive`, requestSizeLimit: 3145728 }
            ),
            EndActiveAndStartNewSubscription: Object.assign(
                (clientId: number, dto: server.dto.UpdateSubscription) => api.post<server.dto.ApiResponseWrapper<server.dto.Client>>(`/client/${clientId}/subscriptions/startnew`, dto, undefined),
                { absoluteUrl: (clientId: number) => `${baseURL}/client/${clientId}/subscriptions/startnew`, requestSizeLimit: 3145728 }
            ),
            CreateNewClientWithTrial: Object.assign(
                (dto: server.dto.CreateClientWithTrialInstruction) => api.put<server.dto.CreateOutcome>(`/client/new/trial`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/client/new/trial`, requestSizeLimit: 3145728 }
            ),
            CreateNewClientWithPaidSubscription: Object.assign(
                (dto: server.dto.CreateClientWithPaidSubscriptionInstruction) => api.put<server.dto.CreateOutcome>(`/client/new/paid`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/client/new/paid`, requestSizeLimit: 3145728 }
            ),
            ListClientsWithPaymentMethodProblem: Object.assign(
                () => api.get<server.dto.ClientWithPaymentMethodProblem[]>(`/client/paymentmethodproblem/list`, undefined),
                { absoluteUrl: () => `${baseURL}/client/paymentmethodproblem/list`, requestSizeLimit: 3145728 }
            ),
            ListClients: Object.assign(
                () => api.get<server.dto.ClientSmall[]>(`/client/small/list`, undefined),
                { absoluteUrl: () => `${baseURL}/client/small/list`, requestSizeLimit: 3145728 }
            )
        },
        ClientOnboarding: {
            Dashboard: Object.assign(
                (dto: server.dto.ListClientOnboardingDashboardInstruction) => api.post<server.dto.ClientOnboardingDashboard>(`/clientonboarding/dashboard`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/clientonboarding/dashboard`, requestSizeLimit: 3145728 }
            ),
            Remove: Object.assign(
                (clientOnboardingId: number) => api.post<server.dto.Outcome>(`/clientonboarding/remove`, undefined, { params: { clientOnboardingId } }),
                { absoluteUrl: (clientOnboardingId: number) => `${baseURL}/clientonboarding/remove?clientOnboardingId=${clientOnboardingId}`, requestSizeLimit: 3145728 }
            )
        },
        ClientOnboardingOpen: {
            StartClientOnboarding: Object.assign(
                (dto: server.dto.StartClientOnboardingInstruction) => api.post<server.dto.Outcome>(`/clientonboardingopen/start`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/clientonboardingopen/start`, requestSizeLimit: 3145728 }
            ),
            SetYourDetails: Object.assign(
                (dto: server.dto.SetClientOnboardingYourDetailsInstruction) => api.post<server.dto.UpdateClientOnboardingOutcome>(`/clientonboardingopen/SetYourDetails`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/clientonboardingopen/SetYourDetails`, requestSizeLimit: 3145728 }
            ),
            SetSubscription: Object.assign(
                (dto: server.dto.SetClientOnboardingSubscriptionInstruction) => api.post<server.dto.UpdateClientOnboardingOutcome>(`/clientonboardingopen/SetSubscription`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/clientonboardingopen/SetSubscription`, requestSizeLimit: 3145728 }
            ),
            CreateSetupIntent: Object.assign(
                (dto: server.dto.CreateClientOnboardingStripeSetupIntentInstruction) => api.post<server.dto.CreateClientOnboardingStripeSetupIntentOutcome>(`/clientonboardingopen/stripe/setupintent/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/clientonboardingopen/stripe/setupintent/create`, requestSizeLimit: 3145728 }
            ),
            SetLifecycle: Object.assign(
                (dto: server.dto.SetClientOnboardingLifeCycleInstruction) => api.post<server.dto.UpdateClientOnboardingOutcome>(`/clientonboardingopen/SetLifecycle`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/clientonboardingopen/SetLifecycle`, requestSizeLimit: 3145728 }
            )
        },
        CobraAgency: {
            CreateShow: Object.assign(
                (dto: server.dto.CreateCobraAgencyShowInstruction) => api.post<server.dto.CreateCobraAgencyShowOutcomeV2>(`/cobraagency/createshow`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/cobraagency/createshow`, requestSizeLimit: 3145728 }
            )
        },
        Company: {
            Paginated: Object.assign(
                (dto: server.dto.ListCompanyInstruction) => api.post<server.dto.ListTemplateApiResponseWrapper<server.dto.CompanyForList[], server.dto.ListCompanyFilters>>(`/company/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/company/list`, requestSizeLimit: 3145728 }
            ),
            PaginatedSmall: Object.assign(
                (dto: server.dto.ListCompanyInstruction, type: server.dto.AddressTypes) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.CompanySmall[], server.dto.ListCompanyFilters>>(`/company/listSmall/${type}`, dto, undefined),
                { absoluteUrl: (type: server.dto.AddressTypes) => `${baseURL}/company/listSmall/${type}`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (companyId: number) => api.delete(`/company/${companyId}/delete`, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/company/${companyId}/delete`, requestSizeLimit: 3145728 }
            ),
            Restore: Object.assign(
                (companyId: number) => api.post<server.dto.Outcome>(`/company/${companyId}/restore`, undefined, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/company/${companyId}/restore`, requestSizeLimit: 3145728 }
            ),
            GetProcessedEmailTemplate: Object.assign(
                (emailTemplateId: number, companyId: number) => api.get<server.dto.ProcessedEmailTemplate>(`/company/emails/templates/${emailTemplateId}/process/${companyId}`, undefined),
                { absoluteUrl: (emailTemplateId: number, companyId: number) => `${baseURL}/company/emails/templates/${emailTemplateId}/process/${companyId}`, requestSizeLimit: 3145728 }
            ),
            SetBusinessCentralNumber: Object.assign(
                (dto: server.dto.SetBusinessCentralNumberInstruction) => api.post<server.dto.Outcome>(`/company/update/BusinessCentralNumber`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/company/update/BusinessCentralNumber`, requestSizeLimit: 3145728 }
            ),
            ExportItem: Object.assign(
                (companyId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => api.get<any>(`/company/export/item/${companyId}/${exportAction}/${clientFileName}`, { params: { wordTemplateId, preferredCulture } }),
                { absoluteUrl: (companyId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => `${baseURL}/company/export/item/${companyId}/${exportAction}/${clientFileName}?wordTemplateId=${wordTemplateId}&preferredCulture=${preferredCulture}`, requestSizeLimit: 3145728 }
            ),
            ExportList: Object.assign(
                (fileName: string, page: number, templateId: number) => api.get<any>(`/company/export/list/${fileName}`, { params: { page, templateId } }),
                { absoluteUrl: (fileName: string, page: number, templateId: number) => `${baseURL}/company/export/list/${fileName}?page=${page}&templateId=${templateId}`, requestSizeLimit: 3145728 }
            ),
            ExportEmails: Object.assign(
                (fileName: string) => api.get<any>(`/company/export/emails/${fileName}`, undefined),
                { absoluteUrl: (fileName: string) => `${baseURL}/company/export/emails/${fileName}`, requestSizeLimit: 3145728 }
            ),
            ListCompanyActivity: Object.assign(
                (dto: server.dto.ListItemActivityInstruction) => api.post<server.dto.PaginationWrapper<server.dto.CompanyActivity[]>>(`/company/activity`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/company/activity`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForList: Object.assign(
                (dto: server.dto.ListCompanyInstruction) => api.post<server.dto.ExportOption[]>(`/company/ExportOptionsForList`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/company/ExportOptionsForList`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForDetail: Object.assign(
                (companyId: number) => api.get<server.dto.ExportOption[]>(`/company/ExportOptionsForDetail/${companyId}`, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/company/ExportOptionsForDetail/${companyId}`, requestSizeLimit: 3145728 }
            )
        },
        CompanyToContact: {
            SetLink: Object.assign(
                (dto: server.dto.SetCompanyToContactLinkInstruction) => api.post<server.dto.Outcome>(`/companytocontact/SetLink`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/companytocontact/SetLink`, requestSizeLimit: 3145728 }
            ),
            DeleteLink: Object.assign(
                (dto: server.dto.DeleteCompanyToContactLinkInstruction) => api.post<server.dto.Outcome>(`/companytocontact/DeleteLink`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/companytocontact/DeleteLink`, requestSizeLimit: 3145728 }
            ),
            ListCompanyByContact: Object.assign(
                (dto: server.dto.ListCompanyByContactInstruction) => api.post<server.dto.PaginationWrapper<server.dto.CompanyWithWorkRelation[]>>(`/companytocontact/ListCompanyByContact`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/companytocontact/ListCompanyByContact`, requestSizeLimit: 3145728 }
            ),
            ListContactByCompany: Object.assign(
                (dto: server.dto.ListContactByCompanyInstruction) => api.post<server.dto.PaginationWrapper<server.dto.ContactWithWorkRelation[]>>(`/companytocontact/ListContactByCompany`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/companytocontact/ListContactByCompany`, requestSizeLimit: 3145728 }
            )
        },
        ContactAddressBook: {
            SearchAddressBook: Object.assign(
                (dto: server.dto.ListContactAddressBookInstruction) => api.post<server.dto.PaginationWrapper<server.dto.ContactAddressBookEntry[]>>(`/contactaddressbook/SearchAddressBook`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/contactaddressbook/SearchAddressBook`, requestSizeLimit: 3145728 }
            ),
            RelatedToItem: Object.assign(
                (dto: server.dto.ListContactAddressBookToItemInstruction) => api.post<server.dto.ContactAddressBookEntry[]>(`/contactaddressbook/related`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/contactaddressbook/related`, requestSizeLimit: 3145728 }
            )
        },
        Contact: {
            Paginated: Object.assign(
                (dto: server.dto.ListContactInstruction) => api.post<server.dto.ListTemplateApiResponseWrapper<server.dto.ContactForList[], server.dto.ListContactFilters>>(`/contact/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/contact/list`, requestSizeLimit: 3145728 }
            ),
            PaginatedSmall: Object.assign(
                (dto: server.dto.ListContactInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ContactSmallV2[], server.dto.ListContactFilters>>(`/contact/listSmall`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/contact/listSmall`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (contactId: number) => api.delete(`/contact/${contactId}/delete`, undefined),
                { absoluteUrl: (contactId: number) => `${baseURL}/contact/${contactId}/delete`, requestSizeLimit: 3145728 }
            ),
            Restore: Object.assign(
                (contactId: number) => api.post<server.dto.Outcome>(`/contact/${contactId}/restore`, undefined, undefined),
                { absoluteUrl: (contactId: number) => `${baseURL}/contact/${contactId}/restore`, requestSizeLimit: 3145728 }
            ),
            GetProcessedEmailTemplate: Object.assign(
                (emailTemplateId: number, contactId: number) => api.get<server.dto.ProcessedEmailTemplate>(`/contact/emails/templates/${emailTemplateId}/process/${contactId}`, undefined),
                { absoluteUrl: (emailTemplateId: number, contactId: number) => `${baseURL}/contact/emails/templates/${emailTemplateId}/process/${contactId}`, requestSizeLimit: 3145728 }
            ),
            Birthdays: Object.assign(
                (local: Date) => api.get<server.dto.ContactBirthday[]>(`/contact/birthdays`, { params: { local } }),
                { absoluteUrl: (local: Date) => `${baseURL}/contact/birthdays?local=${local}`, requestSizeLimit: 3145728 }
            ),
            Contact: Object.assign(
                (contactId: number) => api.get<server.dto.Contact>(`/contact/${contactId}`, undefined),
                { absoluteUrl: (contactId: number) => `${baseURL}/contact/${contactId}`, requestSizeLimit: 3145728 }
            ),
            Preview: Object.assign(
                (contactId: number) => api.get<server.dto.ContactPreview>(`/contact/${contactId}/preview`, undefined),
                { absoluteUrl: (contactId: number) => `${baseURL}/contact/${contactId}/preview`, requestSizeLimit: 3145728 }
            ),
            SetProfileImage: Object.assign(
                (instruction: server.dto.SetContactProfileImageInstruction) => api.post<server.dto.FileUploadResult>(`/contact/profileimage/set`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/contact/profileimage/set`, requestSizeLimit: 3145728 }
            ),
            ExportItem: Object.assign(
                (contactId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => api.get<any>(`/contact/export/item/${contactId}/${exportAction}/${clientFileName}`, { params: { wordTemplateId, preferredCulture } }),
                { absoluteUrl: (contactId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => `${baseURL}/contact/export/item/${contactId}/${exportAction}/${clientFileName}?wordTemplateId=${wordTemplateId}&preferredCulture=${preferredCulture}`, requestSizeLimit: 3145728 }
            ),
            ExportList: Object.assign(
                (fileName: string, page: number, templateId: number) => api.get<any>(`/contact/export/list/${fileName}`, { params: { page, templateId } }),
                { absoluteUrl: (fileName: string, page: number, templateId: number) => `${baseURL}/contact/export/list/${fileName}?page=${page}&templateId=${templateId}`, requestSizeLimit: 3145728 }
            ),
            ExportEmails: Object.assign(
                (fileName: string) => api.get<any>(`/contact/export/emails/${fileName}`, undefined),
                { absoluteUrl: (fileName: string) => `${baseURL}/contact/export/emails/${fileName}`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForList: Object.assign(
                (dto: server.dto.ListContactInstruction) => api.post<server.dto.ExportOption[]>(`/contact/ExportOptionsForList`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/contact/ExportOptionsForList`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForDetail: Object.assign(
                (contactId: number) => api.get<server.dto.ExportOption[]>(`/contact/ExportOptionsForDetail/${contactId}`, undefined),
                { absoluteUrl: (contactId: number) => `${baseURL}/contact/ExportOptionsForDetail/${contactId}`, requestSizeLimit: 3145728 }
            ),
            ListContactActivity: Object.assign(
                (dto: server.dto.ListItemActivityInstruction) => api.post<server.dto.PaginationWrapper<server.dto.ContactActivity[]>>(`/contact/activity`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/contact/activity`, requestSizeLimit: 3145728 }
            )
        },
        Contract: {
            Delete: Object.assign(
                (dto: server.dto.DeleteDocumentInstruction) => api.post<server.dto.Outcome>(`/contract/delete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/contract/delete`, requestSizeLimit: 3145728 }
            ),
            GetProcessedEmailTemplate: Object.assign(
                (emailTemplateId: number, documentId: number) => api.get<server.dto.ProcessedEmailTemplate>(`/contract/emails/templates/${emailTemplateId}/process/${documentId}`, undefined),
                { absoluteUrl: (emailTemplateId: number, documentId: number) => `${baseURL}/contract/emails/templates/${emailTemplateId}/process/${documentId}`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListDocumentInstruction) => api.post<server.dto.ListTemplateApiResponseWrapper<server.dto.DocumentForList[], server.dto.ListDocumentFilters>>(`/contract/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/contract/list`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForList: Object.assign(
                (dto: server.dto.ListDocumentInstruction) => api.post<server.dto.ExportOption[]>(`/contract/ExportOptionsForList`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/contract/ExportOptionsForList`, requestSizeLimit: 3145728 }
            ),
            ExportItem: Object.assign(
                (documentId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => api.get<any>(`/contract/export/item/${documentId}/${exportAction}/${clientFileName}`, { params: { wordTemplateId, preferredCulture } }),
                { absoluteUrl: (documentId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => `${baseURL}/contract/export/item/${documentId}/${exportAction}/${clientFileName}?wordTemplateId=${wordTemplateId}&preferredCulture=${preferredCulture}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomListGeneral: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/contract/export/list/custom/general/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/contract/export/list/custom/general/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportList: Object.assign(
                (fileName: string, page: number, templateId: number) => api.get<any>(`/contract/export/list/${fileName}`, { params: { page, templateId } }),
                { absoluteUrl: (fileName: string, page: number, templateId: number) => `${baseURL}/contract/export/list/${fileName}?page=${page}&templateId=${templateId}`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForDetail: Object.assign(
                (documentId: number) => api.get<server.dto.ExportOption[]>(`/contract/ExportOptionsForDetail/${documentId}`, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/contract/ExportOptionsForDetail/${documentId}`, requestSizeLimit: 3145728 }
            )
        },
        CostCenter: {
            UpdateCostCenter: Object.assign(
                (dto: server.dto.UpdateCostCenterInstruction) => api.post<server.dto.Outcome>(`/costcenter/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/costcenter/update`, requestSizeLimit: 3145728 }
            ),
            CreateCostCenter: Object.assign(
                (dto: server.dto.CreateCostCenterInstruction) => api.post<server.dto.CreateOutcome>(`/costcenter/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/costcenter/create`, requestSizeLimit: 3145728 }
            ),
            GetCostCenter: Object.assign(
                (costCenterId: number) => api.get<server.dto.CostCenter>(`/costcenter/${costCenterId}`, undefined),
                { absoluteUrl: (costCenterId: number) => `${baseURL}/costcenter/${costCenterId}`, requestSizeLimit: 3145728 }
            ),
            ListCostCenter: Object.assign(
                (dto: server.dto.ListCostCenterInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.CostCenter[], server.dto.ListCostCenterFilters>>(`/costcenter/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/costcenter/list`, requestSizeLimit: 3145728 }
            ),
            ListCostCenterActivity: Object.assign(
                (dto: server.dto.ListItemActivityInstruction) => api.post<server.dto.PaginationWrapper<server.dto.CostCenterActivity[]>>(`/costcenter/activity`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/costcenter/activity`, requestSizeLimit: 3145728 }
            )
        },
        Country: {
            List: Object.assign(
                () => api.get<server.dto.Country[]>(`/country/list`, undefined),
                { absoluteUrl: () => `${baseURL}/country/list`, requestSizeLimit: 3145728 }
            ),
            Autocomplete: Object.assign(
                (dto: server.dto.ListCountryInstruction) => api.post<server.dto.PaginationWrapper<server.dto.Country[]>>(`/country/autocomplete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/country/autocomplete`, requestSizeLimit: 3145728 }
            ),
            GetCountry: Object.assign(
                (value: server.dto.Countries) => api.get<server.dto.Country>(`/country/${value}`, undefined),
                { absoluteUrl: (value: server.dto.Countries) => `${baseURL}/country/${value}`, requestSizeLimit: 3145728 }
            )
        },
        Currency: {
            List: Object.assign(
                () => api.get<server.dto.Currency[]>(`/currency/list`, undefined),
                { absoluteUrl: () => `${baseURL}/currency/list`, requestSizeLimit: 3145728 }
            )
        },
        CustomTranslation: {
            Store: Object.assign(
                (dto: server.dto.StoreCustomTranslationInstruction) => api.post<server.dto.CustomTranslation[]>(`/customtranslation/store`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/customtranslation/store`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (clientId: number, translation: server.dto.Translations) => api.put<server.dto.CustomTranslation[]>(`/customtranslation/client/${clientId}/translations/${translation}/delete`, undefined, undefined),
                { absoluteUrl: (clientId: number, translation: server.dto.Translations) => `${baseURL}/customtranslation/client/${clientId}/translations/${translation}/delete`, requestSizeLimit: 3145728 }
            ),
            GetTranslation: Object.assign(
                (clientId: number, translation: server.dto.Translations, language: server.dto.Languages) => api.get<server.dto.CustomTranslation>(`/customtranslation/client/${clientId}/translations/${translation}/${language}`, undefined),
                { absoluteUrl: (clientId: number, translation: server.dto.Translations, language: server.dto.Languages) => `${baseURL}/customtranslation/client/${clientId}/translations/${translation}/${language}`, requestSizeLimit: 3145728 }
            )
        },
        Dashboard: {
            ShowsFromWebform: Object.assign(
                (page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.ShowFromWebform[]>>(`/dashboard/showsfromwebform`, { params: { page, per_page } }),
                { absoluteUrl: (page: number, per_page: number) => `${baseURL}/dashboard/showsfromwebform?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ShowsFromExternalInput: Object.assign(
                (page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.ShowFromExternalUser[]>>(`/dashboard/showsfromexternalinput`, { params: { page, per_page } }),
                { absoluteUrl: (page: number, per_page: number) => `${baseURL}/dashboard/showsfromexternalinput?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            ESignForMe: Object.assign(
                () => api.get<server.dto.ESignForMeDashboard>(`/dashboard/eSignForMe`, undefined),
                { absoluteUrl: () => `${baseURL}/dashboard/eSignForMe`, requestSizeLimit: 3145728 }
            )
        },
        Diagnostic: {
            SetCache: Object.assign(
                (value: string) => api.get<string>(`/diagnostic/cache/set`, { params: { value } }),
                { absoluteUrl: (value: string) => `${baseURL}/diagnostic/cache/set?value=${value}`, requestSizeLimit: 3145728 }
            ),
            GetCache: Object.assign(
                () => api.get<string>(`/diagnostic/cache/get`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/cache/get`, requestSizeLimit: 3145728 }
            ),
            ManagedCrash: Object.assign(
                () => api.get<void>(`/diagnostic/Exception/Managed`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/Exception/Managed`, requestSizeLimit: 3145728 }
            ),
            ManagedBackgroundThreadCrash: Object.assign(
                () => api.get<void>(`/diagnostic/Exception/ManagedBackgroundThread`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/Exception/ManagedBackgroundThread`, requestSizeLimit: 3145728 }
            ),
            LogSentryWarning: Object.assign(
                () => api.get<void>(`/diagnostic/SentryWarning`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/SentryWarning`, requestSizeLimit: 3145728 }
            ),
            Culture: Object.assign(
                () => api.get<string>(`/diagnostic/Culture`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/Culture`, requestSizeLimit: 3145728 }
            ),
            Health: Object.assign(
                () => api.get<any>(`/diagnostic/Health`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/Health`, requestSizeLimit: 3145728 }
            ),
            TestSendServiceBusCommand: Object.assign(
                () => api.post<string>(`/diagnostic/Test/ServiceBus`, undefined, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/Test/ServiceBus`, requestSizeLimit: 3145728 }
            ),
            SendTestSignalR: Object.assign(
                (badgeCount: number, recipientUserId: number) => api.post<string>(`/diagnostic/Test/SignalR`, undefined, { params: { badgeCount, recipientUserId } }),
                { absoluteUrl: (badgeCount: number, recipientUserId: number) => `${baseURL}/diagnostic/Test/SignalR?badgeCount=${badgeCount}&recipientUserId=${recipientUserId}`, requestSizeLimit: 3145728 }
            ),
            SendTestNotification: Object.assign(
                (dto: server.dto.SendTestNotificationInstruction) => api.post<string>(`/diagnostic/Test/Notification`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/Test/Notification`, requestSizeLimit: 3145728 }
            ),
            Serialize: Object.assign(
                () => api.get<server.dto.SerializationCheck>(`/diagnostic/Serialize`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/Serialize`, requestSizeLimit: 3145728 }
            ),
            MockEmail: Object.assign(
                (type: server.dto.MakeMockEmails) => api.get<any>(`/diagnostic/mockEmail`, { params: { type } }),
                { absoluteUrl: (type: server.dto.MakeMockEmails) => `${baseURL}/diagnostic/mockEmail?type=${type}`, requestSizeLimit: 3145728 }
            ),
            ListClaims: Object.assign(
                () => api.get<string[]>(`/diagnostic/Claims`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/Claims`, requestSizeLimit: 3145728 }
            ),
            AuthorizationContext: Object.assign(
                () => api.get<string[]>(`/diagnostic/AuthorizationContext`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/AuthorizationContext`, requestSizeLimit: 3145728 }
            ),
            GeoCodeHealth: Object.assign(
                () => api.get<server.dto.GeoCodeHealth>(`/diagnostic/geocode/health`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/geocode/health`, requestSizeLimit: 3145728 }
            ),
            SendBadge: Object.assign(
                (recipientId: number, badgeCount: number) => api.post<any>(`/diagnostic/NotificationHub/SendBadge`, undefined, { params: { recipientId, badgeCount } }),
                { absoluteUrl: (recipientId: number, badgeCount: number) => `${baseURL}/diagnostic/NotificationHub/SendBadge?recipientId=${recipientId}&badgeCount=${badgeCount}`, requestSizeLimit: 3145728 }
            ),
            SendTestMobileNotification: Object.assign(
                (recipientId: number) => api.post<any>(`/diagnostic/NotificationHub/SendNotification`, undefined, { params: { recipientId } }),
                { absoluteUrl: (recipientId: number) => `${baseURL}/diagnostic/NotificationHub/SendNotification?recipientId=${recipientId}`, requestSizeLimit: 3145728 }
            ),
            GetNotificationHubRegistrations: Object.assign(
                () => api.get<any>(`/diagnostic/NotificationHub/Registrations`, undefined),
                { absoluteUrl: () => `${baseURL}/diagnostic/NotificationHub/Registrations`, requestSizeLimit: 3145728 }
            )
        },
        Disclaimer: {
            Approve: Object.assign(
                (disclaimer: server.dto.Disclaimers) => api.post<server.dto.Outcome>(`/disclaimer/${disclaimer}/approve`, undefined, undefined),
                { absoluteUrl: (disclaimer: server.dto.Disclaimers) => `${baseURL}/disclaimer/${disclaimer}/approve`, requestSizeLimit: 3145728 }
            )
        },
        Document: {
            Lock: Object.assign(
                (documentId: number) => api.post<any>(`/document/${documentId}/lock`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/document/${documentId}/lock`, requestSizeLimit: 3145728 }
            ),
            Unlock: Object.assign(
                (documentId: number) => api.post<any>(`/document/${documentId}/unlock`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/document/${documentId}/unlock`, requestSizeLimit: 3145728 }
            ),
            ListDocumentsByShow: Object.assign(
                (dto: server.dto.ListDocumentsByShowInstruction) => api.post<server.dto.DocumentForList[]>(`/document/listByShow`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/document/listByShow`, requestSizeLimit: 3145728 }
            )
        },
        EmailMessage: {
            SendEmailMessageCommand: Object.assign(
                (instruction: server.dto.SendEmailMessageInstruction) => api.post<server.dto.SendEmailMessageOutcome>(`/emailmessage/send`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/emailmessage/send`, requestSizeLimit: 3145728 }
            ),
            DeleteEmailMessage: Object.assign(
                (emailMessageId: number) => api.delete(`/emailmessage/${emailMessageId}`, undefined),
                { absoluteUrl: (emailMessageId: number) => `${baseURL}/emailmessage/${emailMessageId}`, requestSizeLimit: 3145728 }
            )
        },
        ESignAgencyDashboard: {
            Dashboard: Object.assign(
                (dto: server.dto.ListESignAgencyDashboardInstruction) => api.post<server.dto.ESignAgencyDashboard>(`/esignagencydashboard/dashboard`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esignagencydashboard/dashboard`, requestSizeLimit: 3145728 }
            )
        },
        ESign: {
            Create: Object.assign(
                (dto: server.dto.CreateESignInstruction) => api.post<string>(`/esign/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/create`, requestSizeLimit: 3145728 }
            ),
            CreateFromShow: Object.assign(
                (dto: server.dto.CreateESignFromShowInstruction) => api.post<string>(`/esign/create/fromshow`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/create/fromshow`, requestSizeLimit: 3145728 }
            ),
            UpdateDocumentDetails: Object.assign(
                (dto: server.dto.UpdateESignDocumentDetailsInstruction) => api.post<server.dto.Outcome>(`/esign/document/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/document/update`, requestSizeLimit: 3145728 }
            ),
            SetAllowAutoSign: Object.assign(
                (dto: server.dto.SetESignAllowAutoSignInstruction) => api.post<any>(`/esign/document/allowAutoSign/set`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/document/allowAutoSign/set`, requestSizeLimit: 3145728 }
            ),
            ChangeESignToDraftStage: Object.assign(
                (documentId: number) => api.post<any>(`/esign/document/${documentId}/toDraft`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/esign/document/${documentId}/toDraft`, requestSizeLimit: 3145728 }
            ),
            ChangeESignToReviewStage: Object.assign(
                (documentId: number) => api.post<any>(`/esign/document/${documentId}/toReview`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/esign/document/${documentId}/toReview`, requestSizeLimit: 3145728 }
            ),
            ChangeESignToOutForSignature: Object.assign(
                (documentId: number) => api.post<any>(`/esign/document/${documentId}/toOutForSignature`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/esign/document/${documentId}/toOutForSignature`, requestSizeLimit: 3145728 }
            ),
            ChangeESignToDiscardedStage: Object.assign(
                (documentId: number) => api.post<any>(`/esign/document/${documentId}/toDiscarded`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/esign/document/${documentId}/toDiscarded`, requestSizeLimit: 3145728 }
            ),
            ManualComplete: Object.assign(
                (instruction: server.dto.UploadCompletedESignPdfInstruction) => api.post<server.dto.FileUploadResult>(`/esign/document/manualComplete`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/esign/document/manualComplete`, requestSizeLimit: 209715200 }
            ),
            SetParticipantFromUser: Object.assign(
                (dto: server.dto.SetESignParticipantFromUserInstruction) => api.post<server.dto.SetESignParticipantOutcome>(`/esign/participant/set/fromUser`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/participant/set/fromUser`, requestSizeLimit: 3145728 }
            ),
            SetParticipantFromContact: Object.assign(
                (dto: server.dto.SetESignParticipantFromContactInstruction) => api.post<server.dto.SetESignParticipantOutcome>(`/esign/participant/set/fromContact`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/participant/set/fromContact`, requestSizeLimit: 3145728 }
            ),
            DeleteParticipant: Object.assign(
                (eSignParticipantId: number) => api.post<any>(`/esign/participant/${eSignParticipantId}/delete`, undefined, undefined),
                { absoluteUrl: (eSignParticipantId: number) => `${baseURL}/esign/participant/${eSignParticipantId}/delete`, requestSizeLimit: 3145728 }
            ),
            ListParticipants: Object.assign(
                (dto: server.dto.ListESignParticipantInstruction) => api.post<server.dto.PaginationWrapper<server.dto.ESignParticipant[]>>(`/esign/participant/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/participant/list`, requestSizeLimit: 3145728 }
            ),
            GetLastSignature: Object.assign(
                () => api.get<server.dto.LastSignature>(`/esign/signature/last`, undefined),
                { absoluteUrl: () => `${baseURL}/esign/signature/last`, requestSizeLimit: 3145728 }
            ),
            ListESignFiles: Object.assign(
                (documentId: number, fileGroup: server.dto.ESignFileGroups) => api.get<server.dto.ESignFile[]>(`/esign/document/${documentId}/files/${fileGroup}/list`, undefined),
                { absoluteUrl: (documentId: number, fileGroup: server.dto.ESignFileGroups) => `${baseURL}/esign/document/${documentId}/files/${fileGroup}/list`, requestSizeLimit: 3145728 }
            ),
            CreateESignFileFromTemplate: Object.assign(
                (dto: server.dto.CreateESignFileFromTemplateInstruction) => api.post<server.dto.ESignFile[]>(`/esign/file/create/fromTemplate`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/file/create/fromTemplate`, requestSizeLimit: 3145728 }
            ),
            CreateESignFilesFromStorage: Object.assign(
                (dto: server.dto.CreateESignFileFromStorageInstruction) => api.post<server.dto.ESignFile[]>(`/esign/file/create/fromStorage`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/file/create/fromStorage`, requestSizeLimit: 3145728 }
            ),
            CreateESignFilesFromUpload: Object.assign(
                (instruction: server.dto.UploadMultipleESignFileInstruction) => api.post<server.dto.FileUploadResult[]>(`/esign/file/create/fromUpload`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/esign/file/create/fromUpload`, requestSizeLimit: 209715200 }
            ),
            DeleteESignFile: Object.assign(
                (eSignFileId: number) => api.post<any>(`/esign/file/${eSignFileId}/delete`, undefined, undefined),
                { absoluteUrl: (eSignFileId: number) => `${baseURL}/esign/file/${eSignFileId}/delete`, requestSizeLimit: 3145728 }
            ),
            UpdateESignFileName: Object.assign(
                (dto: server.dto.UpdateESignFileNameInstruction) => api.post<server.dto.ESignFile[]>(`/esign/file/update/name`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/file/update/name`, requestSizeLimit: 3145728 }
            ),
            UpdateESignFileOrder: Object.assign(
                (dto: server.dto.UpdateESignFileOrderInstruction) => api.post<any>(`/esign/file/sort`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/file/sort`, requestSizeLimit: 3145728 }
            ),
            DownloadESignFile: Object.assign(
                (fileId: string, fileName: string) => api.get<any>(`/esign/file/${fileId}/download/${fileName}`, undefined),
                { absoluteUrl: (fileId: string, fileName: string) => `${baseURL}/esign/file/${fileId}/download/${fileName}`, requestSizeLimit: 3145728 }
            ),
            DownloadStitchedFile: Object.assign(
                (documentId: number, fileName: string, inline: boolean) => api.get<any>(`/esign/document/${documentId}/downloadStitched/${fileName}`, { params: { inline } }),
                { absoluteUrl: (documentId: number, fileName: string, inline: boolean) => `${baseURL}/esign/document/${documentId}/downloadStitched/${fileName}?inline=${inline}`, requestSizeLimit: 3145728 }
            ),
            ListESignForMe: Object.assign(
                (dto: server.dto.ListESignForMeInstruction) => api.post<server.dto.PaginationWrapper<server.dto.ESignForMe[]>>(`/esign/forMe/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/forMe/list`, requestSizeLimit: 3145728 }
            ),
            ListESignActivity: Object.assign(
                (dto: server.dto.ListItemActivityInstruction) => api.post<server.dto.PaginationWrapper<server.dto.ESignActivity[]>>(`/esign/document/activity`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/document/activity`, requestSizeLimit: 3145728 }
            ),
            ListReminders: Object.assign(
                (documentId: number) => api.get<server.dto.DocumentReminder[]>(`/esign/document/${documentId}/reminder`, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/esign/document/${documentId}/reminder`, requestSizeLimit: 3145728 }
            ),
            DeleteAllReminders: Object.assign(
                (documentId: number) => api.post<any>(`/esign/document/${documentId}/reminder/clear/all`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/esign/document/${documentId}/reminder/clear/all`, requestSizeLimit: 3145728 }
            ),
            DeleteLastReminder: Object.assign(
                (documentId: number) => api.post<any>(`/esign/document/${documentId}/reminder/clear/last`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/esign/document/${documentId}/reminder/clear/last`, requestSizeLimit: 3145728 }
            ),
            AddReminder: Object.assign(
                (dto: server.dto.AddDocumentReminderInstruction) => api.post<server.dto.Outcome>(`/esign/reminder/add`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/reminder/add`, requestSizeLimit: 3145728 }
            ),
            UpdateReminder: Object.assign(
                (dto: server.dto.UpdateDocumentReminderInstruction) => api.post<server.dto.Outcome>(`/esign/reminder/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/esign/reminder/update`, requestSizeLimit: 3145728 }
            )
        },
        ESignOpen: {
            PlaceSignature: Object.assign(
                (secret: string, dto: server.dto.PlaceSignatureInstruction) => api.post<server.dto.PlaceSignatureOutcome>(`/esign/open/participant/${secret}/signature`, dto, undefined),
                { absoluteUrl: (secret: string) => `${baseURL}/esign/open/participant/${secret}/signature`, requestSizeLimit: 3145728 }
            ),
            DownloadESignPdfBySecret: Object.assign(
                (secret: string, fileName: string, inline: boolean) => api.get<any>(`/esign/open/participant/${secret}/download/pdf/${fileName}`, { params: { inline } }),
                { absoluteUrl: (secret: string, fileName: string, inline: boolean) => `${baseURL}/esign/open/participant/${secret}/download/pdf/${fileName}?inline=${inline}`, requestSizeLimit: 3145728 }
            ),
            DownloadOtherFileBySecret: Object.assign(
                (secret: string, fileId: string, fileName: string, inline: boolean) => api.get<any>(`/esign/open/participant/${secret}/download/${fileId}/other/${fileName}`, { params: { inline } }),
                { absoluteUrl: (secret: string, fileId: string, fileName: string, inline: boolean) => `${baseURL}/esign/open/participant/${secret}/download/${fileId}/other/${fileName}?inline=${inline}`, requestSizeLimit: 3145728 }
            ),
            UploadSignatureFile: Object.assign(
                (instruction: server.dto.UploadSignatureInstruction, secret: string) => api.post<server.dto.FileUploadResult>(`/esign/open/participant/${secret}/signature/upload`, instruction, undefined),
                { absoluteUrl: (secret: string) => `${baseURL}/esign/open/participant/${secret}/signature/upload`, requestSizeLimit: 10485760 }
            ),
            DownloadSignatureFile: Object.assign(
                (fileId: string) => api.get<any>(`/esign/open/signature/${fileId}/download/signature.png`, undefined),
                { absoluteUrl: (fileId: string) => `${baseURL}/esign/open/signature/${fileId}/download/signature.png`, requestSizeLimit: 3145728 }
            )
        },
        ExcelTemplate: {
            DeleteExcelTemplate: Object.assign(
                (excelTemplateId: number) => api.get<any>(`/exceltemplate/${excelTemplateId}/delete`, undefined),
                { absoluteUrl: (excelTemplateId: number) => `${baseURL}/exceltemplate/${excelTemplateId}/delete`, requestSizeLimit: 3145728 }
            ),
            CreateExcelTemplate: Object.assign(
                (dto: server.dto.CreateExcelTemplateInstruction) => api.post<server.dto.CreateUpdateExcelTemplateOutcome>(`/exceltemplate/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/exceltemplate/create`, requestSizeLimit: 3145728 }
            ),
            UpdateExcelTemplate: Object.assign(
                (dto: server.dto.UpdateExcelTemplateInstruction) => api.post<server.dto.CreateUpdateExcelTemplateOutcome>(`/exceltemplate/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/exceltemplate/update`, requestSizeLimit: 3145728 }
            ),
            GetExcelTemplate: Object.assign(
                (excelTemplateId: number) => api.get<server.dto.ExcelTemplate>(`/exceltemplate/${excelTemplateId}`, undefined),
                { absoluteUrl: (excelTemplateId: number) => `${baseURL}/exceltemplate/${excelTemplateId}`, requestSizeLimit: 3145728 }
            ),
            DeleteColumn: Object.assign(
                (dto: server.dto.DeleteExcelTemplateColumnInstruction) => api.post<server.dto.Outcome>(`/exceltemplate/column/delete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/exceltemplate/column/delete`, requestSizeLimit: 3145728 }
            ),
            StoreColumn: Object.assign(
                (dto: server.dto.StoreExcelTemplateColumnInstruction) => api.post<server.dto.Outcome>(`/exceltemplate/column/store`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/exceltemplate/column/store`, requestSizeLimit: 3145728 }
            ),
            UpdateColumnOrder: Object.assign(
                (dto: server.dto.UpdateExcelTemplateColumnOrderInstruction) => api.post<server.dto.Outcome>(`/exceltemplate/column/order`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/exceltemplate/column/order`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListExcelTemplateInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ExcelTemplate[], server.dto.ListExcelTemplateFilters>>(`/exceltemplate/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/exceltemplate/list`, requestSizeLimit: 3145728 }
            )
        },
        ExternalCalendar: {
            Paginated: Object.assign(
                (dto: server.dto.ListExternalCalendarInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ExternalCalendarForList[], server.dto.ListExternalCalendarFilters>>(`/externalcalendar/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/externalcalendar/list`, requestSizeLimit: 3145728 }
            ),
            SubscribeExternalCalendar: Object.assign(
                (dto: server.dto.SubscribeExternalICalendarInstruction) => api.post<server.dto.CreateOutcome>(`/externalcalendar/SubscribeExternalICalendar`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/externalcalendar/SubscribeExternalICalendar`, requestSizeLimit: 3145728 }
            ),
            SubscribeGoogleCalendar: Object.assign(
                (dto: server.dto.SubscribeGoogleCalendarInstruction) => api.post<server.dto.CreateOutcome>(`/externalcalendar/SubscribeGoogleCalendar`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/externalcalendar/SubscribeGoogleCalendar`, requestSizeLimit: 3145728 }
            ),
            ExternalEvent: Object.assign(
                (externalEventId: number) => api.get<server.dto.ExternalEventPreview>(`/externalcalendar/events/${externalEventId}`, undefined),
                { absoluteUrl: (externalEventId: number) => `${baseURL}/externalcalendar/events/${externalEventId}`, requestSizeLimit: 3145728 }
            )
        },
        ExternalIdentity: {
            Disconnect: Object.assign(
                (dto: server.dto.DisconnectExternalIdentityInstruction) => api.post<server.dto.Outcome>(`/externalidentity/disconnect`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/externalidentity/disconnect`, requestSizeLimit: 3145728 }
            ),
            SignIn: Object.assign(
                (dto: server.dto.SignInExternalIdentityInstruction) => api.post<server.dto.Outcome>(`/externalidentity/signin`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/externalidentity/signin`, requestSizeLimit: 3145728 }
            )
        },
        FavoriteArtist: {
            Add: Object.assign(
                (instruction: server.dto.AddFavoriteArtistInstruction) => api.put<server.dto.Outcome>(`/favoriteartist/add`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/favoriteartist/add`, requestSizeLimit: 3145728 }
            ),
            Remove: Object.assign(
                (instruction: server.dto.RemoveFavoriteArtistInstruction) => api.post<server.dto.Outcome>(`/favoriteartist/remove`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/favoriteartist/remove`, requestSizeLimit: 3145728 }
            )
        },
        FavoriteSubject: {
            ListSubjects: Object.assign(
                () => api.get<server.dto.FavoriteSubject[]>(`/favoritesubject`, undefined),
                { absoluteUrl: () => `${baseURL}/favoritesubject`, requestSizeLimit: 3145728 }
            ),
            DeleteSubject: Object.assign(
                (id: number) => api.delete(`/favoritesubject/${id}`, undefined),
                { absoluteUrl: (id: number) => `${baseURL}/favoritesubject/${id}`, requestSizeLimit: 3145728 }
            ),
            CreateSubject: Object.assign(
                (dto: server.dto.CreateFavoriteSubjectInstruction) => api.post<server.dto.FavoriteSubject[]>(`/favoritesubject/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/favoritesubject/create`, requestSizeLimit: 3145728 }
            ),
            UpdateSubject: Object.assign(
                (dto: server.dto.UpdateFavoriteSubjectInstruction) => api.put<server.dto.FavoriteSubject[]>(`/favoritesubject`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/favoritesubject`, requestSizeLimit: 3145728 }
            )
        },
        File: {
            UploadAdvancingFiles: Object.assign(
                (instruction: server.dto.UploadMultipleAdvancingFileInstruction) => api.post<server.dto.AdvancingRequest>(`/file/advancing`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/file/advancing`, requestSizeLimit: 209715200 }
            ),
            DeleteAdvancingFile: Object.assign(
                (advancingRequestId: number, clientFileId: number) => api.delete(`/file/advancing/${advancingRequestId}/${clientFileId}`, undefined),
                { absoluteUrl: (advancingRequestId: number, clientFileId: number) => `${baseURL}/file/advancing/${advancingRequestId}/${clientFileId}`, requestSizeLimit: 3145728 }
            ),
            DownloadEmailAttachments: Object.assign(
                (secret: string, fileName: string) => api.get<any>(`/file/email/${secret}/download/${fileName}`, undefined),
                { absoluteUrl: (secret: string, fileName: string) => `${baseURL}/file/email/${secret}/download/${fileName}`, requestSizeLimit: 3145728 }
            ),
            DownloadClientFile: Object.assign(
                (fileId: string, fileName: string) => api.get<any>(`/file/clientfile/${fileId}/download/${fileName}`, undefined),
                { absoluteUrl: (fileId: string, fileName: string) => `${baseURL}/file/clientfile/${fileId}/download/${fileName}`, requestSizeLimit: 3145728 }
            ),
            DeleteClientFile: Object.assign(
                (clientFileId: number) => api.delete(`/file/clientfile/${clientFileId}/delete`, undefined),
                { absoluteUrl: (clientFileId: number) => `${baseURL}/file/clientfile/${clientFileId}/delete`, requestSizeLimit: 3145728 }
            ),
            SetClientFileProperties: Object.assign(
                (dto: server.dto.SetClientFilePropertiesInstruction) => api.post<any>(`/file/clientfile/setproperties`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/file/clientfile/setproperties`, requestSizeLimit: 3145728 }
            ),
            UploadSystemWideClientFiles: Object.assign(
                (instruction: server.dto.UploadMultipleSystemWideFileInstruction) => api.post<server.dto.FileUploadResult[]>(`/file/clientfile/systemwide/upload`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/file/clientfile/systemwide/upload`, requestSizeLimit: 209715200 }
            ),
            ListSystemWideClientFiles: Object.assign(
                (page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.File[]>>(`/file/clientfile/systemwide/list`, { params: { page, per_page } }),
                { absoluteUrl: (page: number, per_page: number) => `${baseURL}/file/clientfile/systemwide/list?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            UploadClientFiles: Object.assign(
                (instruction: server.dto.UploadMultipleClientFileInstruction) => api.post<server.dto.FileUploadResult[]>(`/file/clientfile/files/upload`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/file/clientfile/files/upload`, requestSizeLimit: 209715200 }
            ),
            SetFlightFileProperties: Object.assign(
                (file: server.dto.File) => api.post<server.dto.File>(`/file/flight`, file, undefined),
                { absoluteUrl: () => `${baseURL}/file/flight`, requestSizeLimit: 3145728 }
            ),
            DownloadFlightFile: Object.assign(
                (fileId: string, fileName: string) => api.get<any>(`/file/flight/${fileId}/download/${fileName}`, undefined),
                { absoluteUrl: (fileId: string, fileName: string) => `${baseURL}/file/flight/${fileId}/download/${fileName}`, requestSizeLimit: 3145728 }
            ),
            DeleteFlightFile: Object.assign(
                (flightFileId: number) => api.delete(`/file/flight/${flightFileId}`, undefined),
                { absoluteUrl: (flightFileId: number) => `${baseURL}/file/flight/${flightFileId}`, requestSizeLimit: 3145728 }
            ),
            UploadFlightFiles: Object.assign(
                (instruction: server.dto.UploadMultipleFlightFileInstruction) => api.post<server.dto.FileUploadResult[]>(`/file/flight/upload`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/file/flight/upload`, requestSizeLimit: 10485760 }
            ),
            SetTrainFileProperties: Object.assign(
                (file: server.dto.File) => api.post<server.dto.File>(`/file/train`, file, undefined),
                { absoluteUrl: () => `${baseURL}/file/train`, requestSizeLimit: 3145728 }
            ),
            DownloadTrainFile: Object.assign(
                (fileId: string, fileName: string) => api.get<any>(`/file/train/${fileId}/download/${fileName}`, undefined),
                { absoluteUrl: (fileId: string, fileName: string) => `${baseURL}/file/train/${fileId}/download/${fileName}`, requestSizeLimit: 3145728 }
            ),
            DeleteTrainFile: Object.assign(
                (trainFileId: number) => api.delete(`/file/train/${trainFileId}`, undefined),
                { absoluteUrl: (trainFileId: number) => `${baseURL}/file/train/${trainFileId}`, requestSizeLimit: 3145728 }
            ),
            UploadTrainFiles: Object.assign(
                (instruction: server.dto.UploadMultipleTrainFileInstruction) => api.post<server.dto.FileUploadResult[]>(`/file/train/upload`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/file/train/upload`, requestSizeLimit: 10485760 }
            ),
            BatchDelete: Object.assign(
                () => api.put<any>(`/file/myselection/delete/all`, undefined, undefined),
                { absoluteUrl: () => `${baseURL}/file/myselection/delete/all`, requestSizeLimit: 3145728 }
            ),
            BatchChangeVisibleToExternal: Object.assign(
                (value: boolean) => api.put<any>(`/file/myselection/visibletoexternal`, undefined, { params: { value } }),
                { absoluteUrl: (value: boolean) => `${baseURL}/file/myselection/visibletoexternal?value=${value}`, requestSizeLimit: 3145728 }
            ),
            ExportMyClientFileSelectionInZip: Object.assign(
                (fileName: string) => api.get<any>(`/file/myselection/export/${fileName}`, undefined),
                { absoluteUrl: (fileName: string) => `${baseURL}/file/myselection/export/${fileName}`, requestSizeLimit: 3145728 }
            )
        },
        FilterPreset: {
            DeleteFilterPreset: Object.assign(
                (filterPresetId: number) => api.get<any>(`/filterpreset/${filterPresetId}/delete`, undefined),
                { absoluteUrl: (filterPresetId: number) => `${baseURL}/filterpreset/${filterPresetId}/delete`, requestSizeLimit: 3145728 }
            ),
            CreateFilterPresetForShow: Object.assign(
                (dto: server.dto.CreateFilterPresetInstruction<server.dto.ListShowInternalInstruction>) => api.put<any>(`/filterpreset/show/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/show/create`, requestSizeLimit: 3145728 }
            ),
            CreateFilterPresetForPlanningByWeek: Object.assign(
                (dto: server.dto.CreateFilterPresetInstruction<server.dto.PlanningByWeekForInternalQuery>) => api.put<any>(`/filterpreset/planningByWeek/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/planningByWeek/create`, requestSizeLimit: 3145728 }
            ),
            CreateFilterPresetForPlanningByMonth: Object.assign(
                (dto: server.dto.CreateFilterPresetInstruction<server.dto.PlanningByMonthForInternalQuery>) => api.put<any>(`/filterpreset/planningByMonth/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/planningByMonth/create`, requestSizeLimit: 3145728 }
            ),
            CreateFilterPresetForPlanningByQuarter: Object.assign(
                (dto: server.dto.CreateFilterPresetInstruction<server.dto.PlanningByQuarterForInternalQuery>) => api.put<any>(`/filterpreset/planningByQuarter/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/planningByQuarter/create`, requestSizeLimit: 3145728 }
            ),
            CreateFilterPresetForInvoice: Object.assign(
                (dto: server.dto.CreateFilterPresetInstruction<server.dto.ListInvoiceInstruction>) => api.put<any>(`/filterpreset/invoice/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/invoice/create`, requestSizeLimit: 3145728 }
            ),
            CreateFilterPresetForCompany: Object.assign(
                (dto: server.dto.CreateFilterPresetInstruction<server.dto.ListCompanyInstruction>) => api.put<any>(`/filterpreset/company/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/company/create`, requestSizeLimit: 3145728 }
            ),
            CreateFilterPresetForContact: Object.assign(
                (dto: server.dto.CreateFilterPresetInstruction<server.dto.ListContactInstruction>) => api.put<any>(`/filterpreset/contact/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/contact/create`, requestSizeLimit: 3145728 }
            ),
            CreateFilterPresetForDocument: Object.assign(
                (dto: server.dto.CreateFilterPresetInstruction<server.dto.ListDocumentInstruction>) => api.put<any>(`/filterpreset/document/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/document/create`, requestSizeLimit: 3145728 }
            ),
            ListFilterPresetsForShow: Object.assign(
                () => api.get<server.dto.FilterPreset<server.dto.ListShowInternalInstruction>[]>(`/filterpreset/show/list`, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/show/list`, requestSizeLimit: 3145728 }
            ),
            ListFilterPresetsForPlanningByWeek: Object.assign(
                () => api.get<server.dto.FilterPreset<server.dto.PlanningByWeekForInternalQuery>[]>(`/filterpreset/planningByWeek/list`, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/planningByWeek/list`, requestSizeLimit: 3145728 }
            ),
            ListFilterPresetsForPlanningByMonth: Object.assign(
                () => api.get<server.dto.FilterPreset<server.dto.PlanningByMonthForInternalQuery>[]>(`/filterpreset/planningByMonth/list`, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/planningByMonth/list`, requestSizeLimit: 3145728 }
            ),
            ListFilterPresetsForPlanningByQuarter: Object.assign(
                () => api.get<server.dto.FilterPreset<server.dto.PlanningByQuarterForInternalQuery>[]>(`/filterpreset/planningByQuarter/list`, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/planningByQuarter/list`, requestSizeLimit: 3145728 }
            ),
            ListFilterPresetsForInvoice: Object.assign(
                () => api.get<server.dto.FilterPreset<server.dto.ListInvoiceInstruction>[]>(`/filterpreset/invoice/list`, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/invoice/list`, requestSizeLimit: 3145728 }
            ),
            ListFilterPresetsForCompany: Object.assign(
                () => api.get<server.dto.FilterPreset<server.dto.ListCompanyInstruction>[]>(`/filterpreset/company/list`, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/company/list`, requestSizeLimit: 3145728 }
            ),
            ListFilterPresetsForContact: Object.assign(
                () => api.get<server.dto.FilterPreset<server.dto.ListContactInstruction>[]>(`/filterpreset/contact/list`, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/contact/list`, requestSizeLimit: 3145728 }
            ),
            ListFilterPresetsForQuote: Object.assign(
                () => api.get<server.dto.FilterPreset<server.dto.ListDocumentInstruction>[]>(`/filterpreset/quote/list`, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/quote/list`, requestSizeLimit: 3145728 }
            ),
            ListFilterPresetsForPurchaseAgreement: Object.assign(
                () => api.get<server.dto.FilterPreset<server.dto.ListDocumentInstruction>[]>(`/filterpreset/purchaseAgreement/list`, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/purchaseAgreement/list`, requestSizeLimit: 3145728 }
            ),
            ListFilterPresetsForContract: Object.assign(
                () => api.get<server.dto.FilterPreset<server.dto.ListDocumentInstruction>[]>(`/filterpreset/contract/list`, undefined),
                { absoluteUrl: () => `${baseURL}/filterpreset/contract/list`, requestSizeLimit: 3145728 }
            )
        },
        Flight: {
            Paginated: Object.assign(
                (dto: server.dto.ListFlightInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.FlightForList[], server.dto.ListFlightFilters>>(`/flight/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/flight/list`, requestSizeLimit: 3145728 }
            )
        },
        FreeFieldProfile: {
            Paginated: Object.assign(
                (dto: server.dto.ListFreeFieldProfileInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.FreeFieldProfileForList[], server.dto.ListFreeFieldProfileFilters>>(`/freefieldprofile/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/freefieldprofile/list`, requestSizeLimit: 3145728 }
            ),
            PaginatedSmall: Object.assign(
                (dto: server.dto.ListFreeFieldProfileInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.FreeFieldProfileSmall[], server.dto.ListFreeFieldProfileFilters>>(`/freefieldprofile/listSmall`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/freefieldprofile/listSmall`, requestSizeLimit: 3145728 }
            ),
            GetProfile: Object.assign(
                (freeFieldProfileId: number) => api.get<server.dto.FreeFieldProfile>(`/freefieldprofile/${freeFieldProfileId}`, undefined),
                { absoluteUrl: (freeFieldProfileId: number) => `${baseURL}/freefieldprofile/${freeFieldProfileId}`, requestSizeLimit: 3145728 }
            ),
            ListUserToClientsByProfile: Object.assign(
                (freeFieldProfileId: number) => api.get<server.dto.UserToClientSmall[]>(`/freefieldprofile/${freeFieldProfileId}/userToClients`, undefined),
                { absoluteUrl: (freeFieldProfileId: number) => `${baseURL}/freefieldprofile/${freeFieldProfileId}/userToClients`, requestSizeLimit: 3145728 }
            ),
            CreateProfile: Object.assign(
                (dto: server.dto.CreateFreeFieldProfileInstruction) => api.post<server.dto.CreateOutcome>(`/freefieldprofile/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/freefieldprofile/create`, requestSizeLimit: 3145728 }
            ),
            UpdateProfile: Object.assign(
                (dto: server.dto.UpdateFreeFieldProfileInstruction) => api.post<server.dto.Outcome>(`/freefieldprofile/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/freefieldprofile/update`, requestSizeLimit: 3145728 }
            ),
            SetLevelForFreeFields: Object.assign(
                (dto: server.dto.SetPermissionLevelForFreeFieldsInstruction) => api.post<server.dto.Outcome>(`/freefieldprofile/setPermissionLevel`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/freefieldprofile/setPermissionLevel`, requestSizeLimit: 3145728 }
            ),
            DeleteFreeFieldProfile: Object.assign(
                (freeFieldProfileId: number, moveToFreefieldProfileId: number) => api.delete(`/freefieldprofile/${freeFieldProfileId}`, { params: { moveToFreefieldProfileId } }),
                { absoluteUrl: (freeFieldProfileId: number, moveToFreefieldProfileId: number) => `${baseURL}/freefieldprofile/${freeFieldProfileId}?moveToFreefieldProfileId=${moveToFreefieldProfileId}`, requestSizeLimit: 3145728 }
            ),
            RemoveFreeFieldProfileForUser: Object.assign(
                (dto: server.dto.RemoveFreeFieldProfileForUserInstruction) => api.post<server.dto.Outcome>(`/freefieldprofile/RemoveFreeFieldProfileForUser`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/freefieldprofile/RemoveFreeFieldProfileForUser`, requestSizeLimit: 3145728 }
            )
        },
        Google: {
            SignIn: Object.assign(
                (returnUrl: string) => api.get<any>(`/google/signin`, { params: { returnUrl } }),
                { absoluteUrl: (returnUrl: string) => `${baseURL}/google/signin?returnUrl=${returnUrl}`, requestSizeLimit: 3145728 }
            ),
            SignInOrRegister: Object.assign(
                (returnUrl: string) => api.get<any>(`/google/register`, { params: { returnUrl } }),
                { absoluteUrl: (returnUrl: string) => `${baseURL}/google/register?returnUrl=${returnUrl}`, requestSizeLimit: 3145728 }
            ),
            Connect: Object.assign(
                () => api.get<any>(`/google/connect`, undefined),
                { absoluteUrl: () => `${baseURL}/google/connect`, requestSizeLimit: 3145728 }
            ),
            ConnectCalendar: Object.assign(
                () => api.get<any>(`/google/connect/calendar`, undefined),
                { absoluteUrl: () => `${baseURL}/google/connect/calendar`, requestSizeLimit: 3145728 }
            ),
            MyDetails: Object.assign(
                () => api.get<server.dto.GoogleIdentityUserDetails>(`/google/details`, undefined),
                { absoluteUrl: () => `${baseURL}/google/details`, requestSizeLimit: 3145728 }
            )
        },
        GroundTransport: {
            Paginated: Object.assign(
                (dto: server.dto.ListGroundTransportInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.GroundTransportForList[], server.dto.ListGroundTransportFilters>>(`/groundtransport/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/groundtransport/list`, requestSizeLimit: 3145728 }
            ),
            GetGroundTransport: Object.assign(
                (groundTransportId: number) => api.get<server.dto.GroundTransport>(`/groundtransport/${groundTransportId}`, undefined),
                { absoluteUrl: (groundTransportId: number) => `${baseURL}/groundtransport/${groundTransportId}`, requestSizeLimit: 3145728 }
            )
        },
        Guestlist: {
            ExportXslx: Object.assign(
                (showId: number) => api.get<any>(`/guestlist/export/xlsx/${showId}`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/guestlist/export/xlsx/${showId}`, requestSizeLimit: 3145728 }
            )
        },
        ICalendarFeed: {
            List: Object.assign(
                (dto: server.dto.ListICalendarFeedInstruction) => api.post<server.dto.PaginationWrapper<server.dto.ICalendarFeed[]>>(`/icalendarfeed/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/icalendarfeed/list`, requestSizeLimit: 3145728 }
            ),
            Update: Object.assign(
                (dto: server.dto.UpdateICalendarFeedInstruction) => api.post<server.dto.FeedRecordPeek>(`/icalendarfeed/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/icalendarfeed/update`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (dto: server.dto.CreateICalendarFeedInstruction) => api.put<server.dto.Outcome>(`/icalendarfeed/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/icalendarfeed/create`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (iCalendarFeedId: number) => api.delete(`/icalendarfeed/${iCalendarFeedId}`, undefined),
                { absoluteUrl: (iCalendarFeedId: number) => `${baseURL}/icalendarfeed/${iCalendarFeedId}`, requestSizeLimit: 3145728 }
            ),
            EmailOverview: Object.assign(
                (userId: number) => api.get<server.dto.SendEmailMessageOutcome>(`/icalendarfeed/emailoverview`, { params: { userId } }),
                { absoluteUrl: (userId: number) => `${baseURL}/icalendarfeed/emailoverview?userId=${userId}`, requestSizeLimit: 3145728 }
            )
        },
        ICalendarTemplate: {
            List: Object.assign(
                (dto: server.dto.ListICalendarTemplateInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ICalendarTemplateSmall[], server.dto.ListICalendarTemplateFilters>>(`/icalendartemplate/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/icalendartemplate/list`, requestSizeLimit: 3145728 }
            ),
            Copy: Object.assign(
                (iCalendarTemplateId: number) => api.post<number>(`/icalendartemplate/${iCalendarTemplateId}/copy`, undefined, undefined),
                { absoluteUrl: (iCalendarTemplateId: number) => `${baseURL}/icalendartemplate/${iCalendarTemplateId}/copy`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (iCalendarTemplateId: number) => api.delete(`/icalendartemplate/${iCalendarTemplateId}`, undefined),
                { absoluteUrl: (iCalendarTemplateId: number) => `${baseURL}/icalendartemplate/${iCalendarTemplateId}`, requestSizeLimit: 3145728 }
            )
        },
        Invoice: {
            Delete: Object.assign(
                (dto: server.dto.DeleteInvoiceInstruction) => api.post<server.dto.Outcome>(`/invoice/delete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/invoice/delete`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListInvoiceInstruction) => api.post<server.dto.ListTemplateApiResponseWrapper<server.dto.InvoiceForList[], server.dto.ListInvoiceFilters>>(`/invoice/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/invoice/list`, requestSizeLimit: 3145728 }
            ),
            ChargeByStripe: Object.assign(
                (invoiceId: number) => api.get<server.dto.Outcome>(`/invoice/${invoiceId}/stripe-charge`, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/invoice/${invoiceId}/stripe-charge`, requestSizeLimit: 3145728 }
            ),
            SendSubscriptionInvoice: Object.assign(
                (invoiceId: number) => api.get<string>(`/invoice/sendsubscriptioninvoice/${invoiceId}`, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/invoice/sendsubscriptioninvoice/${invoiceId}`, requestSizeLimit: 3145728 }
            ),
            GetAdminOnePanelData: Object.assign(
                (invoiceId: number) => api.get<server.dto.AdminOneInvoicePanelData>(`/invoice/${invoiceId}/adminone`, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/invoice/${invoiceId}/adminone`, requestSizeLimit: 3145728 }
            ),
            ListInvoiceByShow: Object.assign(
                (showId: number) => api.get<server.dto.InvoiceForList[]>(`/invoice/listByShow/${showId}`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/invoice/listByShow/${showId}`, requestSizeLimit: 3145728 }
            ),
            GetProcessedEmailTemplate: Object.assign(
                (emailTemplateId: number, invoiceId: number) => api.get<server.dto.ProcessedEmailTemplate>(`/invoice/emails/templates/${emailTemplateId}/process/${invoiceId}`, undefined),
                { absoluteUrl: (emailTemplateId: number, invoiceId: number) => `${baseURL}/invoice/emails/templates/${emailTemplateId}/process/${invoiceId}`, requestSizeLimit: 3145728 }
            ),
            ExportItem: Object.assign(
                (invoiceId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => api.get<any>(`/invoice/export/item/${invoiceId}/${exportAction}/${clientFileName}`, { params: { wordTemplateId, preferredCulture } }),
                { absoluteUrl: (invoiceId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => `${baseURL}/invoice/export/item/${invoiceId}/${exportAction}/${clientFileName}?wordTemplateId=${wordTemplateId}&preferredCulture=${preferredCulture}`, requestSizeLimit: 3145728 }
            ),
            ExportList: Object.assign(
                (fileName: string, page: number, templateId: number) => api.get<any>(`/invoice/export/list/${fileName}`, { params: { page, templateId } }),
                { absoluteUrl: (fileName: string, page: number, templateId: number) => `${baseURL}/invoice/export/list/${fileName}?page=${page}&templateId=${templateId}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomListGeneral: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/invoice/export/list/custom/general/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/invoice/export/list/custom/general/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomInvoiceListSalesTax: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/invoice/export/list/custom/salestax/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/invoice/export/list/custom/salestax/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomInvoiceListForAllArtistAgency: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/invoice/export/list/custom/AllArtistAgency/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/invoice/export/list/custom/AllArtistAgency/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomInvoiceListForTemporarySecretary1: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/invoice/export/list/custom/temporarysecretary1/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/invoice/export/list/custom/temporarysecretary1/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomInvoiceListForTemporarySecretary2: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/invoice/export/list/custom/temporarysecretary2/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/invoice/export/list/custom/temporarysecretary2/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForList: Object.assign(
                (dto: server.dto.ListInvoiceInstruction) => api.post<server.dto.ExportOption[]>(`/invoice/ExportOptionsForList`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/invoice/ExportOptionsForList`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForDetail: Object.assign(
                (invoiceId: number) => api.get<server.dto.ExportOption[]>(`/invoice/ExportOptionsForDetail/${invoiceId}`, undefined),
                { absoluteUrl: (invoiceId: number) => `${baseURL}/invoice/ExportOptionsForDetail/${invoiceId}`, requestSizeLimit: 3145728 }
            )
        },
        ItemAccess: {
            PaginatedUserAccessToArtist: Object.assign(
                (dto: server.dto.ListUserByItemAccessInstruction) => api.post<server.dto.PaginationWrapper<server.dto.UserAccessToArtist[]>>(`/itemaccess/PaginatedUserAccessToArtist`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/itemaccess/PaginatedUserAccessToArtist`, requestSizeLimit: 3145728 }
            )
        },
        Itinerary: {
            Export: Object.assign(
                (fileName: string, dto: server.dto.ExportItineraryInstruction) => api.post<any>(`/itinerary/export/${fileName}`, dto, undefined),
                { absoluteUrl: (fileName: string) => `${baseURL}/itinerary/export/${fileName}`, requestSizeLimit: 3145728 }
            )
        },
        MergeField: {
            ListAvailable: Object.assign(
                (dto: server.dto.ListAvailableMergeFieldInstruction) => api.post<server.dto.AvailableMergeFieldTopic[]>(`/mergefield/available`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/mergefield/available`, requestSizeLimit: 3145728 }
            )
        },
        MessageTemplate: {
            DeleteMessageTemplate: Object.assign(
                (messageTemplateId: number) => api.delete(`/messagetemplate/${messageTemplateId}/delete`, undefined),
                { absoluteUrl: (messageTemplateId: number) => `${baseURL}/messagetemplate/${messageTemplateId}/delete`, requestSizeLimit: 3145728 }
            ),
            UpdateMessageTemplate: Object.assign(
                (dto: server.dto.UpdateMessageTemplateInstruction) => api.post<server.dto.Outcome>(`/messagetemplate/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/messagetemplate/update`, requestSizeLimit: 3145728 }
            ),
            CreateMessageTemplate: Object.assign(
                (type: server.dto.MessageTemplateTypes) => api.post<number>(`/messagetemplate/create`, undefined, { params: { type } }),
                { absoluteUrl: (type: server.dto.MessageTemplateTypes) => `${baseURL}/messagetemplate/create?type=${type}`, requestSizeLimit: 3145728 }
            ),
            GetMessageTemplate: Object.assign(
                (messageTemplateId: number) => api.get<server.dto.MessageTemplate>(`/messagetemplate/${messageTemplateId}`, undefined),
                { absoluteUrl: (messageTemplateId: number) => `${baseURL}/messagetemplate/${messageTemplateId}`, requestSizeLimit: 3145728 }
            ),
            SetMessageTemplateTranslation: Object.assign(
                (dto: server.dto.SetMessageTemplateTranslationInstruction) => api.post<server.dto.Outcome>(`/messagetemplate/translation/set`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/messagetemplate/translation/set`, requestSizeLimit: 3145728 }
            ),
            DeleteMessageTemplateTranslation: Object.assign(
                (dto: server.dto.DeleteMessageTemplateTranslationInstruction) => api.post<any>(`/messagetemplate/translation/delete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/messagetemplate/translation/delete`, requestSizeLimit: 3145728 }
            ),
            ListMessageTemplate: Object.assign(
                (dto: server.dto.ListMessageTemplateInstruction) => api.post<server.dto.PaginationWrapper<server.dto.MessageTemplate[]>>(`/messagetemplate/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/messagetemplate/list`, requestSizeLimit: 3145728 }
            )
        },
        MySelection: {
            Add: Object.assign(
                (instruction: server.dto.AddToMySelectionInstruction) => api.put<server.dto.Outcome>(`/myselection/add`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/myselection/add`, requestSizeLimit: 3145728 }
            ),
            Remove: Object.assign(
                (instruction: server.dto.RemoveFromMySelectionInstruction) => api.post<number>(`/myselection/remove`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/myselection/remove`, requestSizeLimit: 3145728 }
            ),
            ClearAll: Object.assign(
                (mainArea: server.dto.MainAreas) => api.post<any>(`/myselection/clearall`, undefined, { params: { mainArea } }),
                { absoluteUrl: (mainArea: server.dto.MainAreas) => `${baseURL}/myselection/clearall?mainArea=${mainArea}`, requestSizeLimit: 3145728 }
            ),
            Count: Object.assign(
                (mainArea: server.dto.MainAreas) => api.get<number>(`/myselection/count`, { params: { mainArea } }),
                { absoluteUrl: (mainArea: server.dto.MainAreas) => `${baseURL}/myselection/count?mainArea=${mainArea}`, requestSizeLimit: 3145728 }
            ),
            Warning: Object.assign(
                (dto: server.dto.FetchWarningForMySelectionInstruction) => api.post<string>(`/myselection/warning`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/myselection/warning`, requestSizeLimit: 3145728 }
            ),
            BatchChangeTags: Object.assign(
                (instruction: server.dto.BatchChangeTagsForMySelectionInstruction) => api.post<string>(`/myselection/tags`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/myselection/tags`, requestSizeLimit: 3145728 }
            ),
            BatchChangeOwner: Object.assign(
                (instruction: server.dto.BatchSetOwnerForMySelectionInstruction) => api.post<string>(`/myselection/owner`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/myselection/owner`, requestSizeLimit: 3145728 }
            ),
            BatchSetPublication: Object.assign(
                (instruction: server.dto.BatchSetShowPublicationForMySelectionInstruction) => api.post<string>(`/myselection/publication`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/myselection/publication`, requestSizeLimit: 3145728 }
            ),
            BatchChangeStatus: Object.assign(
                (instruction: server.dto.BatchSetStatusForMySelectionInstruction) => api.post<string>(`/myselection/status`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/myselection/status`, requestSizeLimit: 3145728 }
            ),
            BatchDelete: Object.assign(
                (instruction: server.dto.BatchDeleteForMySelectionInstruction) => api.post<string>(`/myselection/delete`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/myselection/delete`, requestSizeLimit: 3145728 }
            )
        },
        Note: {
            List: Object.assign(
                (instruction: server.dto.ListNoteInstruction) => api.post<server.dto.PaginationWrapper<server.dto.Note[]>>(`/note/list`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/note/list`, requestSizeLimit: 3145728 }
            ),
            CreateNote: Object.assign(
                (instruction: server.dto.CreateNoteInstruction) => api.put<server.dto.Note>(`/note/create`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/note/create`, requestSizeLimit: 3145728 }
            ),
            DeleteNote: Object.assign(
                (noteId: number) => api.delete(`/note/${noteId}`, undefined),
                { absoluteUrl: (noteId: number) => `${baseURL}/note/${noteId}`, requestSizeLimit: 3145728 }
            ),
            UpdateNote: Object.assign(
                (instruction: server.dto.UpdateNoteInstruction) => api.post<server.dto.Note>(`/note/update`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/note/update`, requestSizeLimit: 3145728 }
            )
        },
        Notification: {
            ListNotifications: Object.assign(
                (dto: server.dto.ListNotificationInstruction) => api.post<server.dto.PaginationWrapper<server.dto.Notification[]>>(`/notification/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/notification/list`, requestSizeLimit: 3145728 }
            ),
            MarkRead: Object.assign(
                (notifications: number[], read: boolean) => api.post<any>(`/notification/read/${read}`, notifications, undefined),
                { absoluteUrl: (read: boolean) => `${baseURL}/notification/read/${read}`, requestSizeLimit: 3145728 }
            ),
            UpdateSettings: Object.assign(
                (settings: server.dto.NotificationSettings) => api.post<server.dto.NotificationSettings>(`/notification/settings`, settings, undefined),
                { absoluteUrl: () => `${baseURL}/notification/settings`, requestSizeLimit: 3145728 }
            ),
            Hide: Object.assign(
                (notifications: number[]) => api.post<any>(`/notification/hide`, notifications, undefined),
                { absoluteUrl: () => `${baseURL}/notification/hide`, requestSizeLimit: 3145728 }
            ),
            MarkAllRead: Object.assign(
                (channel: server.dto.NotificationChannels) => api.post<any>(`/notification/allread`, undefined, { params: { channel } }),
                { absoluteUrl: (channel: server.dto.NotificationChannels) => `${baseURL}/notification/allread?channel=${channel}`, requestSizeLimit: 3145728 }
            )
        },
        OnlineInvoice: {
            Create: Object.assign(
                (dto: server.dto.CreateOnlineInvoiceInstruction) => api.post<string>(`/onlineinvoice/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/onlineinvoice/create`, requestSizeLimit: 3145728 }
            ),
            CreateFromShow: Object.assign(
                (dto: server.dto.CreateOnlineInvoiceFromShowInstruction) => api.post<string>(`/onlineinvoice/create/fromshow`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/onlineinvoice/create/fromshow`, requestSizeLimit: 3145728 }
            ),
            UpdateDocumentDetails: Object.assign(
                (dto: server.dto.UpdateInvoiceDocumentDetailsInstruction) => api.post<server.dto.Outcome>(`/onlineinvoice/document/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/onlineinvoice/document/update`, requestSizeLimit: 3145728 }
            ),
            ChangeOnlineInvoiceToDraftStage: Object.assign(
                (documentId: number) => api.post<any>(`/onlineinvoice/document/${documentId}/toDraft`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/onlineinvoice/document/${documentId}/toDraft`, requestSizeLimit: 3145728 }
            ),
            ChangeOnlineInvoiceToReviewStage: Object.assign(
                (documentId: number) => api.post<server.dto.Outcome>(`/onlineinvoice/document/${documentId}/toReview`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/onlineinvoice/document/${documentId}/toReview`, requestSizeLimit: 3145728 }
            ),
            ChangeOnlineInvoiceToOutstanding: Object.assign(
                (documentId: number) => api.post<any>(`/onlineinvoice/document/${documentId}/toOutstanding`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/onlineinvoice/document/${documentId}/toOutstanding`, requestSizeLimit: 3145728 }
            ),
            DownloadPdf: Object.assign(
                (documentId: number, fileName: string, inline: boolean) => api.get<any>(`/onlineinvoice/document/${documentId}/download/pdf/${fileName}`, { params: { inline } }),
                { absoluteUrl: (documentId: number, fileName: string, inline: boolean) => `${baseURL}/onlineinvoice/document/${documentId}/download/pdf/${fileName}?inline=${inline}`, requestSizeLimit: 3145728 }
            ),
            SetParticipantFromContact: Object.assign(
                (dto: server.dto.SetInvoiceParticipantFromContactInstruction) => api.post<server.dto.SetInvoiceParticipantOutcome>(`/onlineinvoice/participant/set/fromContact`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/onlineinvoice/participant/set/fromContact`, requestSizeLimit: 3145728 }
            ),
            DeleteParticipant: Object.assign(
                (invoiceParticipantId: number) => api.post<any>(`/onlineinvoice/participant/${invoiceParticipantId}/delete`, undefined, undefined),
                { absoluteUrl: (invoiceParticipantId: number) => `${baseURL}/onlineinvoice/participant/${invoiceParticipantId}/delete`, requestSizeLimit: 3145728 }
            ),
            ListParticipants: Object.assign(
                (dto: server.dto.ListInvoiceParticipantInstruction) => api.post<server.dto.PaginationWrapper<server.dto.InvoiceParticipant[]>>(`/onlineinvoice/participant/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/onlineinvoice/participant/list`, requestSizeLimit: 3145728 }
            ),
            ListInvoiceActivity: Object.assign(
                (dto: server.dto.ListItemActivityInstruction) => api.post<server.dto.PaginationWrapper<server.dto.InvoiceActivity[]>>(`/onlineinvoice/document/activity`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/onlineinvoice/document/activity`, requestSizeLimit: 3145728 }
            ),
            ListReminders: Object.assign(
                (documentId: number) => api.get<server.dto.DocumentReminder[]>(`/onlineinvoice/document/${documentId}/reminder`, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/onlineinvoice/document/${documentId}/reminder`, requestSizeLimit: 3145728 }
            ),
            DeleteAllReminders: Object.assign(
                (documentId: number) => api.post<any>(`/onlineinvoice/document/${documentId}/reminder/clear/all`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/onlineinvoice/document/${documentId}/reminder/clear/all`, requestSizeLimit: 3145728 }
            ),
            DeleteLastReminder: Object.assign(
                (documentId: number) => api.post<any>(`/onlineinvoice/document/${documentId}/reminder/clear/last`, undefined, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/onlineinvoice/document/${documentId}/reminder/clear/last`, requestSizeLimit: 3145728 }
            ),
            AddReminder: Object.assign(
                (dto: server.dto.AddDocumentReminderInstruction) => api.post<server.dto.Outcome>(`/onlineinvoice/reminder/add`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/onlineinvoice/reminder/add`, requestSizeLimit: 3145728 }
            ),
            UpdateReminder: Object.assign(
                (dto: server.dto.UpdateDocumentReminderInstruction) => api.post<server.dto.Outcome>(`/onlineinvoice/reminder/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/onlineinvoice/reminder/update`, requestSizeLimit: 3145728 }
            )
        },
        OnlineInvoiceOpen: {
            DownloadOnlineInvoicePdfBySecret: Object.assign(
                (secret: string, fileName: string, inline: boolean) => api.get<any>(`/onlineinvoice/open/participant/${secret}/download/pdf/${fileName}`, { params: { inline } }),
                { absoluteUrl: (secret: string, fileName: string, inline: boolean) => `${baseURL}/onlineinvoice/open/participant/${secret}/download/pdf/${fileName}?inline=${inline}`, requestSizeLimit: 3145728 }
            )
        },
        Payment: {
            Paginated: Object.assign(
                (dto: server.dto.ListPaymentInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.PaymentForList[], server.dto.ListPaymentFilters>>(`/payment/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/payment/list`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (dto: server.dto.CreatePaymentInstruction) => api.put<server.dto.CreateOutcome>(`/payment/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/payment/create`, requestSizeLimit: 3145728 }
            ),
            Update: Object.assign(
                (dto: server.dto.UpdatePaymentInstruction) => api.post<server.dto.Outcome>(`/payment/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/payment/update`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (dto: server.dto.DeletePaymentInstruction) => api.post<server.dto.Outcome>(`/payment/delete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/payment/delete`, requestSizeLimit: 3145728 }
            ),
            ExportPaymentList: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/payment/export/list/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/payment/export/list/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomPaymentListForTemporarySecretary: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/payment/export/list/custom/temporarysecretary/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/payment/export/list/custom/temporarysecretary/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForList: Object.assign(
                (dto: server.dto.ListPaymentInstruction) => api.post<server.dto.ExportOption[]>(`/payment/ExportOptionsForList`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/payment/ExportOptionsForList`, requestSizeLimit: 3145728 }
            )
        },
        PaymentTerm: {
            ListOptions: Object.assign(
                () => api.get<server.dto.PaymentTermOption[]>(`/paymentterm/option/list`, undefined),
                { absoluteUrl: () => `${baseURL}/paymentterm/option/list`, requestSizeLimit: 3145728 }
            )
        },
        Planning: {
            PlanningByDayForInternal: Object.assign(
                (dto: server.dto.PlanningByDayForInternalQuery) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.AppointmentForPlanner[], server.dto.PlanningByDayForInternalFilters>>(`/planning/PlanningByDayForInternal`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planning/PlanningByDayForInternal`, requestSizeLimit: 3145728 }
            ),
            PlanningByDayForExternal: Object.assign(
                (dto: server.dto.PlanningByDayForExternalQuery) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.AppointmentForPlanner[], server.dto.PlanningByDayForExternalFilters>>(`/planning/PlanningByDayForExternal`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planning/PlanningByDayForExternal`, requestSizeLimit: 3145728 }
            ),
            PlanningByMonthForInternal: Object.assign(
                (dto: server.dto.PlanningByMonthForInternalQuery) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.AppointmentsForDay[], server.dto.PlanningByMonthForInternalFilters>>(`/planning/PlanningByMonthForInternal`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planning/PlanningByMonthForInternal`, requestSizeLimit: 3145728 }
            ),
            PlanningByMonthForExternal: Object.assign(
                (dto: server.dto.PlanningByMonthForExternalQuery) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.AppointmentsForDay[], server.dto.PlanningByMonthForExternalFilters>>(`/planning/PlanningByMonthForExternal`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planning/PlanningByMonthForExternal`, requestSizeLimit: 3145728 }
            ),
            PlanningByQuarterForInternal: Object.assign(
                (dto: server.dto.PlanningByQuarterForInternalQuery) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.PlannerDay[], server.dto.PlanningByQuarterForInternalFilters>>(`/planning/PlanningByQuarterForInternal`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planning/PlanningByQuarterForInternal`, requestSizeLimit: 3145728 }
            ),
            PlanningByQuarterForExternal: Object.assign(
                (dto: server.dto.PlanningByQuarterForExternalQuery) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.PlannerDay[], server.dto.PlanningByQuarterForExternalFilters>>(`/planning/PlanningByQuarterForExternal`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planning/PlanningByQuarterForExternal`, requestSizeLimit: 3145728 }
            ),
            PlanningByQuarterExportOptions: Object.assign(
                () => api.post<server.dto.ExportOption[]>(`/planning/PlanningByQuarter/ExportOptions`, undefined, undefined),
                { absoluteUrl: () => `${baseURL}/planning/PlanningByQuarter/ExportOptions`, requestSizeLimit: 3145728 }
            ),
            ExportQuarterlyPlanning: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/planning/PlanningByQuarter/export/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/planning/PlanningByQuarter/export/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            PlanningByWeekForInternal: Object.assign(
                (dto: server.dto.PlanningByWeekForInternalQuery) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.AppointmentsForDay[], server.dto.PlanningByWeekForInternalFilters>>(`/planning/PlanningByWeekForInternal`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planning/PlanningByWeekForInternal`, requestSizeLimit: 3145728 }
            ),
            PlanningForItinerary: Object.assign(
                (dto: server.dto.PlanningForItineraryQuery) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.PlannerDay[], server.dto.PlanningForItineraryFilters>>(`/planning/PlanningForItinerary`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planning/PlanningForItinerary`, requestSizeLimit: 3145728 }
            )
        },
        PlanningMap: {
            PaginatedShow: Object.assign(
                (dto: server.dto.ListShowInternalInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ShowForPlanningMap[], server.dto.ListShowInternalFilters>>(`/planningmap/show`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planningmap/show`, requestSizeLimit: 3145728 }
            ),
            PaginatedVenue: Object.assign(
                (dto: server.dto.ListVenueInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.VenueForPlanningMap[], server.dto.ListVenueFilters>>(`/planningmap/venue`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planningmap/venue`, requestSizeLimit: 3145728 }
            ),
            PaginatedAirport: Object.assign(
                (dto: server.dto.ListAirportInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.AirportForPlanningMap[], server.dto.ListAirportFilters>>(`/planningmap/airport`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/planningmap/airport`, requestSizeLimit: 3145728 }
            )
        },
        ProductGroup: {
            Paginated: Object.assign(
                (dto: server.dto.ListProductGroupInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ProductGroupForList[], server.dto.ListProductGroupFilters>>(`/productgroup/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/productgroup/list`, requestSizeLimit: 3145728 }
            )
        },
        Production: {
            Paginated: Object.assign(
                (dto: server.dto.ListProductionInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ProductionForList[], server.dto.ListProductionFilters>>(`/production/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/production/list`, requestSizeLimit: 3145728 }
            ),
            PaginatedSmall: Object.assign(
                (dto: server.dto.ListProductionInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ProductionSmallV2[], server.dto.ListProductionFilters>>(`/production/listSmall`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/production/listSmall`, requestSizeLimit: 3145728 }
            ),
            Production: Object.assign(
                (productionId: number) => api.get<server.dto.Production>(`/production/${productionId}`, undefined),
                { absoluteUrl: (productionId: number) => `${baseURL}/production/${productionId}`, requestSizeLimit: 3145728 }
            ),
            ExportList: Object.assign(
                (fileName: string, page: number, templateId: number) => api.get<any>(`/production/export/list/${fileName}`, { params: { page, templateId } }),
                { absoluteUrl: (fileName: string, page: number, templateId: number) => `${baseURL}/production/export/list/${fileName}?page=${page}&templateId=${templateId}`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForList: Object.assign(
                (dto: server.dto.ListProductionInstruction) => api.post<server.dto.ExportOption[]>(`/production/ExportOptionsForList`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/production/ExportOptionsForList`, requestSizeLimit: 3145728 }
            )
        },
        ProductTemplates: {
            Paginated: Object.assign(
                (dto: server.dto.ListProductTemplateInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ProductTemplate[], server.dto.ListProductTemplateFilters>>(`/producttemplates/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/producttemplates/list`, requestSizeLimit: 3145728 }
            )
        },
        Promoter: {
            Paginated: Object.assign(
                (dto: server.dto.ListPromoterInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.PromoterForList[], server.dto.ListPromoterFilters>>(`/promoter/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/promoter/list`, requestSizeLimit: 3145728 }
            ),
            PaginatedSmall: Object.assign(
                (dto: server.dto.ListPromoterInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.CompanySmall[], server.dto.ListPromoterFilters>>(`/promoter/listSmall`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/promoter/listSmall`, requestSizeLimit: 3145728 }
            )
        },
        PurchaseAgreement: {
            Delete: Object.assign(
                (dto: server.dto.DeleteDocumentInstruction) => api.post<server.dto.Outcome>(`/purchaseagreement/delete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/purchaseagreement/delete`, requestSizeLimit: 3145728 }
            ),
            GetProcessedEmailTemplate: Object.assign(
                (emailTemplateId: number, documentId: number) => api.get<server.dto.ProcessedEmailTemplate>(`/purchaseagreement/emails/templates/${emailTemplateId}/process/${documentId}`, undefined),
                { absoluteUrl: (emailTemplateId: number, documentId: number) => `${baseURL}/purchaseagreement/emails/templates/${emailTemplateId}/process/${documentId}`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListDocumentInstruction) => api.post<server.dto.ListTemplateApiResponseWrapper<server.dto.DocumentForList[], server.dto.ListDocumentFilters>>(`/purchaseagreement/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/purchaseagreement/list`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForList: Object.assign(
                (dto: server.dto.ListDocumentInstruction) => api.post<server.dto.ExportOption[]>(`/purchaseagreement/ExportOptionsForList`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/purchaseagreement/ExportOptionsForList`, requestSizeLimit: 3145728 }
            ),
            ExportItem: Object.assign(
                (documentId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => api.get<any>(`/purchaseagreement/export/item/${documentId}/${exportAction}/${clientFileName}`, { params: { wordTemplateId, preferredCulture } }),
                { absoluteUrl: (documentId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => `${baseURL}/purchaseagreement/export/item/${documentId}/${exportAction}/${clientFileName}?wordTemplateId=${wordTemplateId}&preferredCulture=${preferredCulture}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomListGeneral: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/purchaseagreement/export/list/custom/general/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/purchaseagreement/export/list/custom/general/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportList: Object.assign(
                (fileName: string, page: number, templateId: number) => api.get<any>(`/purchaseagreement/export/list/${fileName}`, { params: { page, templateId } }),
                { absoluteUrl: (fileName: string, page: number, templateId: number) => `${baseURL}/purchaseagreement/export/list/${fileName}?page=${page}&templateId=${templateId}`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForDetail: Object.assign(
                (documentId: number) => api.get<server.dto.ExportOption[]>(`/purchaseagreement/ExportOptionsForDetail/${documentId}`, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/purchaseagreement/ExportOptionsForDetail/${documentId}`, requestSizeLimit: 3145728 }
            )
        },
        Quote: {
            Delete: Object.assign(
                (dto: server.dto.DeleteDocumentInstruction) => api.post<server.dto.Outcome>(`/quote/delete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/quote/delete`, requestSizeLimit: 3145728 }
            ),
            GetProcessedEmailTemplate: Object.assign(
                (emailTemplateId: number, documentId: number) => api.get<server.dto.ProcessedEmailTemplate>(`/quote/emails/templates/${emailTemplateId}/process/${documentId}`, undefined),
                { absoluteUrl: (emailTemplateId: number, documentId: number) => `${baseURL}/quote/emails/templates/${emailTemplateId}/process/${documentId}`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListDocumentInstruction) => api.post<server.dto.ListTemplateApiResponseWrapper<server.dto.DocumentForList[], server.dto.ListDocumentFilters>>(`/quote/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/quote/list`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForList: Object.assign(
                (dto: server.dto.ListDocumentInstruction) => api.post<server.dto.ExportOption[]>(`/quote/ExportOptionsForList`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/quote/ExportOptionsForList`, requestSizeLimit: 3145728 }
            ),
            ExportItem: Object.assign(
                (documentId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => api.get<any>(`/quote/export/item/${documentId}/${exportAction}/${clientFileName}`, { params: { wordTemplateId, preferredCulture } }),
                { absoluteUrl: (documentId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => `${baseURL}/quote/export/item/${documentId}/${exportAction}/${clientFileName}?wordTemplateId=${wordTemplateId}&preferredCulture=${preferredCulture}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomListGeneral: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/quote/export/list/custom/general/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/quote/export/list/custom/general/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportList: Object.assign(
                (fileName: string, page: number, templateId: number) => api.get<any>(`/quote/export/list/${fileName}`, { params: { page, templateId } }),
                { absoluteUrl: (fileName: string, page: number, templateId: number) => `${baseURL}/quote/export/list/${fileName}?page=${page}&templateId=${templateId}`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForDetail: Object.assign(
                (documentId: number) => api.get<server.dto.ExportOption[]>(`/quote/ExportOptionsForDetail/${documentId}`, undefined),
                { absoluteUrl: (documentId: number) => `${baseURL}/quote/ExportOptionsForDetail/${documentId}`, requestSizeLimit: 3145728 }
            )
        },
        Recurring: {
            DataHousekeeping: Object.assign(
                () => api.get<server.dto.Outcome>(`/recurring/DataHousekeeping`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/DataHousekeeping`, requestSizeLimit: 3145728 }
            ),
            ESignProcessSignAutomatically: Object.assign(
                () => api.get<string>(`/recurring/ESignProcessSignAutomatically`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/ESignProcessSignAutomatically`, requestSizeLimit: 3145728 }
            ),
            EndTemporaryClientAccess: Object.assign(
                () => api.get<server.dto.Outcome>(`/recurring/EndTemporaryClientAccess`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/EndTemporaryClientAccess`, requestSizeLimit: 3145728 }
            ),
            ProcessGeoCode: Object.assign(
                () => api.get<server.dto.Outcome>(`/recurring/ProcessGeoCode`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/ProcessGeoCode`, requestSizeLimit: 3145728 }
            ),
            ProcessVatNumbers: Object.assign(
                () => api.get<server.dto.Outcome>(`/recurring/ProcessVatNumbers`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/ProcessVatNumbers`, requestSizeLimit: 3145728 }
            ),
            RenewUpcomingSubscriptions: Object.assign(
                () => api.get<string>(`/recurring/RenewUpcomingSubscriptions`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/RenewUpcomingSubscriptions`, requestSizeLimit: 3145728 }
            ),
            SendUserTaskReminderEmails: Object.assign(
                () => api.get<string>(`/recurring/SendUserTaskReminderEmails`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/SendUserTaskReminderEmails`, requestSizeLimit: 3145728 }
            ),
            SendDocumentReminderEmails: Object.assign(
                () => api.get<string>(`/recurring/SendDocumentReminderEmails`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/SendDocumentReminderEmails`, requestSizeLimit: 3145728 }
            ),
            SyncAirlinesFromFlightStats: Object.assign(
                () => api.get<server.dto.Outcome>(`/recurring/SyncAirlinesFromFlightStats`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/SyncAirlinesFromFlightStats`, requestSizeLimit: 3145728 }
            ),
            SyncAirportsFromFlightStats: Object.assign(
                () => api.get<server.dto.Outcome>(`/recurring/SyncAirportsFromFlightStats`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/SyncAirportsFromFlightStats`, requestSizeLimit: 3145728 }
            ),
            SyncDataToCobraDb: Object.assign(
                () => api.get<server.dto.Outcome>(`/recurring/SyncDataToCobraDb`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/SyncDataToCobraDb`, requestSizeLimit: 3145728 }
            ),
            SyncExchangeRatesFromFixer: Object.assign(
                () => api.get<server.dto.Outcome>(`/recurring/SyncExchangeRatesFromFixer`, undefined),
                { absoluteUrl: () => `${baseURL}/recurring/SyncExchangeRatesFromFixer`, requestSizeLimit: 3145728 }
            ),
            SyncUserLastActivity: Object.assign(
                (withinMinutes: number) => api.get<string>(`/recurring/SyncUserLastActivity`, { params: { withinMinutes } }),
                { absoluteUrl: (withinMinutes: number) => `${baseURL}/recurring/SyncUserLastActivity?withinMinutes=${withinMinutes}`, requestSizeLimit: 3145728 }
            )
        },
        Register: {
            SelfRegisterFromInvitation: Object.assign(
                (dto: server.dto.SelfRegisterInstruction) => api.post<server.dto.SelfRegisterOutcome>(`/register`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/register`, requestSizeLimit: 3145728 }
            ),
            ValidateUsername: Object.assign(
                (username: string) => api.get<server.dto.UsernameValidationResult>(`/register/usernameexists/${username}`, undefined),
                { absoluteUrl: (username: string) => `${baseURL}/register/usernameexists/${username}`, requestSizeLimit: 3145728 }
            )
        },
        SalesData: {
            Export: Object.assign(
                (bookkeepingId: number, year: number, month: number, type: any) => api.get<any>(`/salesdata/export/bookkeeping/${bookkeepingId}/${year}/${month}/${type}`, undefined),
                { absoluteUrl: (bookkeepingId: number, year: number, month: number, type: any) => `${baseURL}/salesdata/export/bookkeeping/${bookkeepingId}/${year}/${month}/${type}`, requestSizeLimit: 3145728 }
            )
        },
        Show: {
            GetShow: Object.assign(
                (showId: number) => api.get<server.dto.Show>(`/show/${showId}`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/show/${showId}`, requestSizeLimit: 3145728 }
            ),
            GetShowTooltipData: Object.assign(
                (showId: number) => api.get<server.dto.ShowTooltipData>(`/show/${showId}/tooltip`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/show/${showId}/tooltip`, requestSizeLimit: 3145728 }
            ),
            PaginatedInternalEnvironment: Object.assign(
                (dto: server.dto.ListShowInternalInstruction) => api.post<server.dto.ListTemplateApiResponseWrapper<server.dto.ShowForListInternal[], server.dto.ListShowInternalFilters>>(`/show/internalenvironment/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/show/internalenvironment/list`, requestSizeLimit: 3145728 }
            ),
            PaginatedExternalEnvironment: Object.assign(
                (dto: server.dto.ListShowExternalInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.ShowForListExternal[], server.dto.ListShowExternalFilters>>(`/show/externalenvironment/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/show/externalenvironment/list`, requestSizeLimit: 3145728 }
            ),
            GetLastModified: Object.assign(
                (page: number, per_page: number) => api.get<server.dto.PaginationWrapper<server.dto.ShowLastModified[]>>(`/show/lastmodified`, { params: { page, per_page } }),
                { absoluteUrl: (page: number, per_page: number) => `${baseURL}/show/lastmodified?page=${page}&per_page=${per_page}`, requestSizeLimit: 3145728 }
            ),
            GetMiniCalendarData: Object.assign(
                (year: number, month: number, dto: server.dto.ListShowInternalInstruction) => api.post<server.dto.MiniCalendar>(`/show/minicalendar/${year}/${month}`, dto, undefined),
                { absoluteUrl: (year: number, month: number) => `${baseURL}/show/minicalendar/${year}/${month}`, requestSizeLimit: 3145728 }
            ),
            InsertForInternalEnvironment: Object.assign(
                (show: server.dto.InsertShowInternalEnvironmentInstruction) => api.post<server.dto.CreateOutcome>(`/show/internalenvironment/insert`, show, undefined),
                { absoluteUrl: () => `${baseURL}/show/internalenvironment/insert`, requestSizeLimit: 3145728 }
            ),
            InsertForExternalEnvironment: Object.assign(
                (show: server.dto.InsertShowExternalEnvironmentInstruction) => api.post<server.dto.CreateOutcome>(`/show/externalenvironment/insert`, show, undefined),
                { absoluteUrl: () => `${baseURL}/show/externalenvironment/insert`, requestSizeLimit: 3145728 }
            ),
            UpdateForInternalEnvironment: Object.assign(
                (show: server.dto.UpdateShowInternalEnvironmentInstruction) => api.post<server.dto.Outcome>(`/show/internalenvironment/update`, show, undefined),
                { absoluteUrl: () => `${baseURL}/show/internalenvironment/update`, requestSizeLimit: 3145728 }
            ),
            UpdateForExternalEnvironment: Object.assign(
                (show: server.dto.UpdateShowExternalEnvironmentInstruction) => api.post<server.dto.Outcome>(`/show/externalenvironment/update`, show, undefined),
                { absoluteUrl: () => `${baseURL}/show/externalenvironment/update`, requestSizeLimit: 3145728 }
            ),
            CopyShow: Object.assign(
                (show: server.dto.CopyShowInstruction) => api.post<server.dto.CopyShowOutcome>(`/show/copy`, show, undefined),
                { absoluteUrl: () => `${baseURL}/show/copy`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (showId: number) => api.delete(`/show/${showId}`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/show/${showId}`, requestSizeLimit: 3145728 }
            ),
            CountUnlockedShows: Object.assign(
                (dto: server.dto.BatchLockShowsInstruction) => api.post<server.dto.BatchLockShowsOutcome>(`/show/BatchLock/CountUnlocked`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/show/BatchLock/CountUnlocked`, requestSizeLimit: 3145728 }
            ),
            BatchLockShows: Object.assign(
                (dto: server.dto.BatchLockShowsInstruction) => api.post<server.dto.BatchLockShowsOutcome>(`/show/BatchLock/Lock`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/show/BatchLock/Lock`, requestSizeLimit: 3145728 }
            ),
            GetProcessedEmailTemplate: Object.assign(
                (emailTemplateId: number, showId: number) => api.get<server.dto.ProcessedEmailTemplate>(`/show/emails/templates/${emailTemplateId}/process/${showId}`, undefined),
                { absoluteUrl: (emailTemplateId: number, showId: number) => `${baseURL}/show/emails/templates/${emailTemplateId}/process/${showId}`, requestSizeLimit: 3145728 }
            ),
            ExportList: Object.assign(
                (fileName: string, page: number, templateId: number) => api.get<any>(`/show/export/list/${fileName}`, { params: { page, templateId } }),
                { absoluteUrl: (fileName: string, page: number, templateId: number) => `${baseURL}/show/export/list/${fileName}?page=${page}&templateId=${templateId}`, requestSizeLimit: 3145728 }
            ),
            ExportGenericListForExternal: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/show/export/externalenvironment/list/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/show/export/externalenvironment/list/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomShowListForHelderWerk: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/show/export/list/custom/helderwerk/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/show/export/list/custom/helderwerk/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomShowListForPlatinum: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/show/export/list/custom/platinum/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/show/export/list/custom/platinum/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomShowListForTemporarySecretary: Object.assign(
                (fileName: string, tsExportType: server.dto.TemporarySecretaryExportTypes, page: number) => api.get<any>(`/show/export/list/custom/temporarysecretary/${fileName}`, { params: { tsExportType, page } }),
                { absoluteUrl: (fileName: string, tsExportType: server.dto.TemporarySecretaryExportTypes, page: number) => `${baseURL}/show/export/list/custom/temporarysecretary/${fileName}?tsExportType=${tsExportType}&page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportCustomShowListForVanaf2: Object.assign(
                (fileName: string, page: number) => api.get<any>(`/show/export/list/custom/vanaf2/${fileName}`, { params: { page } }),
                { absoluteUrl: (fileName: string, page: number) => `${baseURL}/show/export/list/custom/vanaf2/${fileName}?page=${page}`, requestSizeLimit: 3145728 }
            ),
            ExportItem: Object.assign(
                (showId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => api.get<any>(`/show/export/item/${showId}/${exportAction}/${clientFileName}`, { params: { wordTemplateId, preferredCulture } }),
                { absoluteUrl: (showId: number, exportAction: server.dto.ExportActions, clientFileName: string, wordTemplateId: number, preferredCulture: server.dto.LCID) => `${baseURL}/show/export/item/${showId}/${exportAction}/${clientFileName}?wordTemplateId=${wordTemplateId}&preferredCulture=${preferredCulture}`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForListInternalEnvironment: Object.assign(
                (dto: server.dto.ListShowInternalInstruction) => api.post<server.dto.ExportOption[]>(`/show/internalenvironment/ExportOptionsForList`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/show/internalenvironment/ExportOptionsForList`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForListExternalEnvironment: Object.assign(
                (dto: server.dto.ListShowExternalInstruction) => api.post<server.dto.ExportOption[]>(`/show/externalenvironment/ExportOptionsForList`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/show/externalenvironment/ExportOptionsForList`, requestSizeLimit: 3145728 }
            ),
            ExportOptionsForDetail: Object.assign(
                (showId: number) => api.get<server.dto.ExportOption[]>(`/show/ExportOptionsForDetail/${showId}`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/show/ExportOptionsForDetail/${showId}`, requestSizeLimit: 3145728 }
            ),
            ListShowActivity: Object.assign(
                (dto: server.dto.ListItemActivityInstruction) => api.post<server.dto.PaginationWrapper<server.dto.ShowActivity[]>>(`/show/activity`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/show/activity`, requestSizeLimit: 3145728 }
            )
        },
        StateAlias: {
            ListUnmapped: Object.assign(
                () => api.get<server.dto.StateAlias[]>(`/statealias/unmapped`, undefined),
                { absoluteUrl: () => `${baseURL}/statealias/unmapped`, requestSizeLimit: 3145728 }
            ),
            SetStateId: Object.assign(
                (command: server.dto.SetStateAliasMappingInstruction) => api.post<any>(`/statealias/setstateid`, command, undefined),
                { absoluteUrl: () => `${baseURL}/statealias/setstateid`, requestSizeLimit: 3145728 }
            ),
            SetCannotMap: Object.assign(
                (command: server.dto.SetCannotMapStateAliasInstruction) => api.post<any>(`/statealias/setcannotmap`, command, undefined),
                { absoluteUrl: () => `${baseURL}/statealias/setcannotmap`, requestSizeLimit: 3145728 }
            )
        },
        Status: {
            ListSmall: Object.assign(
                (mainArea: server.dto.MainAreas) => api.get<server.dto.StatusSmall[]>(`/status/list/small`, { params: { mainArea } }),
                { absoluteUrl: (mainArea: server.dto.MainAreas) => `${baseURL}/status/list/small?mainArea=${mainArea}`, requestSizeLimit: 3145728 }
            ),
            List: Object.assign(
                (mainArea: server.dto.MainAreas) => api.get<server.dto.Status[]>(`/status/list`, { params: { mainArea } }),
                { absoluteUrl: (mainArea: server.dto.MainAreas) => `${baseURL}/status/list?mainArea=${mainArea}`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (instruction: server.dto.CreateStatusInstruction) => api.post<server.dto.CreateOutcome>(`/status/create`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/status/create`, requestSizeLimit: 3145728 }
            ),
            Update: Object.assign(
                (instruction: server.dto.UpdateStatusInstruction) => api.post<server.dto.Outcome>(`/status/update`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/status/update`, requestSizeLimit: 3145728 }
            ),
            Copy: Object.assign(
                (statusId: number) => api.post<server.dto.CreateOutcome>(`/status/copy`, undefined, { params: { statusId } }),
                { absoluteUrl: (statusId: number) => `${baseURL}/status/copy?statusId=${statusId}`, requestSizeLimit: 3145728 }
            ),
            DeleteAndMove: Object.assign(
                (statusId: number, targetId: number) => api.delete(`/status/deleteAndMove`, { params: { statusId, targetId } }),
                { absoluteUrl: (statusId: number, targetId: number) => `${baseURL}/status/deleteAndMove?statusId=${statusId}&targetId=${targetId}`, requestSizeLimit: 3145728 }
            ),
            StoreSorted: Object.assign(
                (instruction: server.dto.StoreSortedStatusInstruction) => api.post<any>(`/status/sort`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/status/sort`, requestSizeLimit: 3145728 }
            )
        },
        Stripe: {
            PaymentIntentRetry: Object.assign(
                (paymentIntentId: string) => api.get<server.dto.Outcome>(`/stripe/paymentintent/${paymentIntentId}/retry`, undefined),
                { absoluteUrl: (paymentIntentId: string) => `${baseURL}/stripe/paymentintent/${paymentIntentId}/retry`, requestSizeLimit: 3145728 }
            ),
            GetCustomer: Object.assign(
                (companyId: number) => api.get<server.dto.StripeCustomer>(`/stripe/${companyId}`, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/stripe/${companyId}`, requestSizeLimit: 3145728 }
            ),
            SyncCustomers: Object.assign(
                (take: number) => api.post<any>(`/stripe/customers/sync`, undefined, { params: { take } }),
                { absoluteUrl: (take: number) => `${baseURL}/stripe/customers/sync?take=${take}`, requestSizeLimit: 3145728 }
            ),
            CreateSetupIntent: Object.assign(
                (dto: server.dto.CreateStripeSetupIntentInstruction) => api.post<string>(`/stripe/setupintent/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/stripe/setupintent/create`, requestSizeLimit: 3145728 }
            ),
            ListStripePaymentProblem: Object.assign(
                (dto: server.dto.ListStripePaymentIntentInstruction) => api.post<server.dto.PaginationWrapper<server.dto.StripePaymentIntent[]>>(`/stripe/payment/problem/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/stripe/payment/problem/list`, requestSizeLimit: 3145728 }
            ),
            CancelPaymentIntent: Object.assign(
                (dto: server.dto.CancelStripePaymentIntentInstruction) => api.post<string>(`/stripe/paymentIntent/cancel`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/stripe/paymentIntent/cancel`, requestSizeLimit: 3145728 }
            )
        },
        Subscription: {
            CheckMaximumAccountsReached: Object.assign(
                () => api.get<server.dto.MaximumAccountsReachedResult>(`/subscription/CheckMaximumAccountsReached`, undefined),
                { absoluteUrl: () => `${baseURL}/subscription/CheckMaximumAccountsReached`, requestSizeLimit: 3145728 }
            ),
            SetClientPaymentMethodToInvoice: Object.assign(
                () => api.get<any>(`/subscription/paymentmethod/invoice/set`, undefined),
                { absoluteUrl: () => `${baseURL}/subscription/paymentmethod/invoice/set`, requestSizeLimit: 3145728 }
            )
        },
        Tag: {
            ListTags: Object.assign(
                (mainArea: server.dto.MainAreas) => api.get<server.dto.TagSmall[]>(`/tag/list/small`, { params: { mainArea } }),
                { absoluteUrl: (mainArea: server.dto.MainAreas) => `${baseURL}/tag/list/small?mainArea=${mainArea}`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListTagInstruction, mainArea: server.dto.MainAreas) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.TagForList[], server.dto.ListTagFilters>>(`/tag/list`, dto, { params: { mainArea } }),
                { absoluteUrl: (mainArea: server.dto.MainAreas) => `${baseURL}/tag/list?mainArea=${mainArea}`, requestSizeLimit: 3145728 }
            ),
            Create: Object.assign(
                (instruction: server.dto.CreateTagInstruction) => api.post<server.dto.CreateOutcome>(`/tag/create`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/tag/create`, requestSizeLimit: 3145728 }
            ),
            Update: Object.assign(
                (instruction: server.dto.UpdateTagInstruction) => api.post<server.dto.Outcome>(`/tag/update`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/tag/update`, requestSizeLimit: 3145728 }
            ),
            Copy: Object.assign(
                (tagId: number) => api.post<server.dto.CreateOutcome>(`/tag/copy`, undefined, { params: { tagId } }),
                { absoluteUrl: (tagId: number) => `${baseURL}/tag/copy?tagId=${tagId}`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (tagId: number) => api.delete(`/tag/delete`, { params: { tagId } }),
                { absoluteUrl: (tagId: number) => `${baseURL}/tag/delete?tagId=${tagId}`, requestSizeLimit: 3145728 }
            ),
            DeleteAndMove: Object.assign(
                (tagId: number, targetId: number) => api.delete(`/tag/deleteAndMove`, { params: { tagId, targetId } }),
                { absoluteUrl: (tagId: number, targetId: number) => `${baseURL}/tag/deleteAndMove?tagId=${tagId}&targetId=${targetId}`, requestSizeLimit: 3145728 }
            )
        },
        Timetable: {
            ListTimetableEntries: Object.assign(
                (showId: number) => api.get<server.dto.TimetableEntry[]>(`/timetable/${showId}/timetable`, undefined),
                { absoluteUrl: (showId: number) => `${baseURL}/timetable/${showId}/timetable`, requestSizeLimit: 3145728 }
            ),
            Duplicate: Object.assign(
                (dto: server.dto.DuplicateTimetableInstruction) => api.post<server.dto.Outcome>(`/timetable/duplicate`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/timetable/duplicate`, requestSizeLimit: 3145728 }
            ),
            CreateTimetableEntry: Object.assign(
                (dto: server.dto.CreateTimetableEntryInstruction) => api.put<server.dto.CreateOutcome>(`/timetable/entry/create`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/timetable/entry/create`, requestSizeLimit: 3145728 }
            ),
            UpdateEntry: Object.assign(
                (dto: server.dto.ModifyTimetableEntryInstruction) => api.post<server.dto.Outcome>(`/timetable/entry/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/timetable/entry/update`, requestSizeLimit: 3145728 }
            ),
            DeleteEntry: Object.assign(
                (dto: server.dto.DeleteTimetableEntryInstruction) => api.post<server.dto.Outcome>(`/timetable/entry/delete`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/timetable/entry/delete`, requestSizeLimit: 3145728 }
            )
        },
        Train: {
            Paginated: Object.assign(
                (dto: server.dto.ListTrainInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.TrainForList[], server.dto.ListTrainFilters>>(`/train/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/train/list`, requestSizeLimit: 3145728 }
            )
        },
        UserAction: {
            List: Object.assign(
                (dto: server.dto.ListUserActionInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.UserAction[], server.dto.ListUserActionFilters>>(`/useraction/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/useraction/list`, requestSizeLimit: 3145728 }
            )
        },
        User: {
            SetAvatar: Object.assign(
                (instruction: server.dto.SetUserAvatarInstruction, userId: number) => api.post<server.dto.FileUploadResult>(`/user/${userId}/avatar`, instruction, undefined),
                { absoluteUrl: (userId: number) => `${baseURL}/user/${userId}/avatar`, requestSizeLimit: 10485760 }
            ),
            ResetPassword: Object.assign(
                (userId: number) => api.get<server.dto.ResetPasswordOutcome>(`/user/${userId}/resetpassword`, undefined),
                { absoluteUrl: (userId: number) => `${baseURL}/user/${userId}/resetpassword`, requestSizeLimit: 3145728 }
            ),
            InviteUser: Object.assign(
                (environment: server.dto.Environments, dto: server.dto.AccountRegistrationInstruction) => api.put<server.dto.ApiResponseWrapper<server.dto.UserToClientSmall>>(`/user/${environment}/invite`, dto, undefined),
                { absoluteUrl: (environment: server.dto.Environments) => `${baseURL}/user/${environment}/invite`, requestSizeLimit: 3145728 }
            ),
            Deactivate: Object.assign(
                (dto: server.dto.DeactivateUserToClientInstruction) => api.post<any>(`/user/deactivate`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/user/deactivate`, requestSizeLimit: 3145728 }
            ),
            Reactivate: Object.assign(
                (dto: server.dto.ReactivateUserToClientInstruction) => api.post<server.dto.ReactivateUserToClientResult>(`/user/reactivate`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/user/reactivate`, requestSizeLimit: 3145728 }
            ),
            ListUserToClientSmall: Object.assign(
                (dto: server.dto.ListUserToClientInstruction) => api.post<server.dto.PaginationWrapper<server.dto.UserToClientSmall[]>>(`/user/small/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/user/small/list`, requestSizeLimit: 3145728 }
            )
        },
        UserEmailProfile: {
            GetUserEmailProfile: Object.assign(
                () => api.get<server.dto.UserEmailProfile>(`/useremailprofile`, undefined),
                { absoluteUrl: () => `${baseURL}/useremailprofile`, requestSizeLimit: 3145728 }
            ),
            UpdateEmailPreferences: Object.assign(
                (dto: server.dto.UpdateUserEmailProfileInstruction) => api.post<server.dto.Outcome>(`/useremailprofile/update`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/useremailprofile/update`, requestSizeLimit: 3145728 }
            )
        },
        UserForExternalAdmin: {
            UpdateExternalEnvironmentPermissions: Object.assign(
                (dto: server.dto.UpdateExternalEnvironmentPermissionsInstruction) => api.post<server.dto.Outcome>(`/userforexternaladmin/updatePermissions`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/userforexternaladmin/updatePermissions`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListExternalUserInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.UserForExternalAdminForList[], server.dto.ListExternalUserFilters>>(`/userforexternaladmin/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/userforexternaladmin/list`, requestSizeLimit: 3145728 }
            )
        },
        UserForInternalAdmin: {
            UpdateInternalEnvironmentPermissions: Object.assign(
                (dto: server.dto.UpdateInternalEnvironmentPermissionsInstruction) => api.post<server.dto.Outcome>(`/userforinternaladmin/internal/updatePermissions`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/userforinternaladmin/internal/updatePermissions`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListInternalUserInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.UserForInternalAdminForList[], server.dto.ListInternalUserFilters>>(`/userforinternaladmin/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/userforinternaladmin/list`, requestSizeLimit: 3145728 }
            ),
            SetAccessAllArtists: Object.assign(
                (dto: server.dto.SetUserForInternalAccessAllArtistsInstruction) => api.post<server.dto.Outcome>(`/userforinternaladmin/SetAccessAllArtists`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/userforinternaladmin/SetAccessAllArtists`, requestSizeLimit: 3145728 }
            )
        },
        UserPreference: {
            UpdateDefaults: Object.assign(
                (dto: server.dto.UpdateUserDefaultsPreferenceInstruction) => api.post<server.dto.Outcome>(`/userpreference/defaults`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/userpreference/defaults`, requestSizeLimit: 3145728 }
            ),
            UpdateDisplay: Object.assign(
                (dto: server.dto.UpdateUserDisplayPreferenceInstruction) => api.post<server.dto.Outcome>(`/userpreference/display`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/userpreference/display`, requestSizeLimit: 3145728 }
            ),
            UpdateRegional: Object.assign(
                (dto: server.dto.UpdateUserRegionalPreferenceInstruction) => api.post<server.dto.Outcome>(`/userpreference/regional`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/userpreference/regional`, requestSizeLimit: 3145728 }
            ),
            UpdateStyling: Object.assign(
                (dto: server.dto.UpdateUserStylingPreferenceInstruction) => api.post<server.dto.Outcome>(`/userpreference/styling`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/userpreference/styling`, requestSizeLimit: 3145728 }
            )
        },
        UserSignature: {
            GetUserSignature: Object.assign(
                () => api.get<server.dto.UserSignature>(`/usersignature/get`, undefined),
                { absoluteUrl: () => `${baseURL}/usersignature/get`, requestSizeLimit: 3145728 }
            ),
            SetUserSignature: Object.assign(
                (dto: server.dto.SetUserSignatureInstruction) => api.post<server.dto.Outcome>(`/usersignature/set`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/usersignature/set`, requestSizeLimit: 3145728 }
            ),
            DeleteUserSignature: Object.assign(
                () => api.delete(`/usersignature/delete`, undefined),
                { absoluteUrl: () => `${baseURL}/usersignature/delete`, requestSizeLimit: 3145728 }
            ),
            UploadSignatureFile: Object.assign(
                (instruction: server.dto.UploadSignatureInstruction) => api.post<server.dto.FileUploadResult>(`/usersignature/upload`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/usersignature/upload`, requestSizeLimit: 10485760 }
            ),
            DownloadSignatureFile: Object.assign(
                (fileId: string) => api.get<any>(`/usersignature/download/signature.png`, { params: { fileId } }),
                { absoluteUrl: (fileId: string) => `${baseURL}/usersignature/download/signature.png?fileId=${fileId}`, requestSizeLimit: 3145728 }
            )
        },
        UserTask: {
            List: Object.assign(
                (instruction: server.dto.ListUserTaskPerItemInstruction) => api.post<server.dto.PaginationWrapper<server.dto.UserTask[]>>(`/usertask/list`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/usertask/list`, requestSizeLimit: 3145728 }
            ),
            CreateUserTask: Object.assign(
                (instruction: server.dto.CreateUserTaskInstruction) => api.put<server.dto.CreateOutcome>(`/usertask/create`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/usertask/create`, requestSizeLimit: 3145728 }
            ),
            UpdateUserTask: Object.assign(
                (instruction: server.dto.UpdateUserTaskInstruction) => api.post<server.dto.Outcome>(`/usertask/update`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/usertask/update`, requestSizeLimit: 3145728 }
            ),
            DeleteUserTask: Object.assign(
                (userTaskId: number) => api.delete(`/usertask/${userTaskId}`, undefined),
                { absoluteUrl: (userTaskId: number) => `${baseURL}/usertask/${userTaskId}`, requestSizeLimit: 3145728 }
            ),
            ConvertToNote: Object.assign(
                (dto: server.dto.ConvertUserTaskToNoteInstruction) => api.post<server.dto.Note>(`/usertask/converttonote`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/usertask/converttonote`, requestSizeLimit: 3145728 }
            ),
            SetTaskReminderEmail: Object.assign(
                (dto: server.dto.SetUserTaskEmailReminderInstruction) => api.post<boolean>(`/usertask/reminderemail/set`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/usertask/reminderemail/set`, requestSizeLimit: 3145728 }
            ),
            SetTaskReminderPopup: Object.assign(
                (dto: server.dto.SetUserTaskPopupReminderInstruction) => api.post<boolean>(`/usertask/reminderpopup/set`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/usertask/reminderpopup/set`, requestSizeLimit: 3145728 }
            ),
            ReminderHealth: Object.assign(
                (type: server.dto.UserTaskReminderTypes) => api.get<server.dto.UserTaskReminderHealth>(`/usertask/reminder/${type}/health`, undefined),
                { absoluteUrl: (type: server.dto.UserTaskReminderTypes) => `${baseURL}/usertask/reminder/${type}/health`, requestSizeLimit: 3145728 }
            )
        },
        VatNumber: {
            RecheckCompanyVatNumber: Object.assign(
                (companyId: number) => api.get<server.dto.VatNumber>(`/vatnumber/company/${companyId}/recheck`, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/vatnumber/company/${companyId}/recheck`, requestSizeLimit: 3145728 }
            ),
            UpdateCompanyVatNumber: Object.assign(
                (companyId: number, number: string) => api.get<any>(`/vatnumber/company/${companyId}/update`, { params: { number } }),
                { absoluteUrl: (companyId: number, number: string) => `${baseURL}/vatnumber/company/${companyId}/update?number=${number}`, requestSizeLimit: 3145728 }
            ),
            RemoveCompanyVatNumber: Object.assign(
                (companyId: number) => api.get<boolean>(`/vatnumber/company/${companyId}/remove`, undefined),
                { absoluteUrl: (companyId: number) => `${baseURL}/vatnumber/company/${companyId}/remove`, requestSizeLimit: 3145728 }
            ),
            CompanyVatNumberHealth: Object.assign(
                () => api.get<server.dto.VatNumberHealth>(`/vatnumber/company/health`, undefined),
                { absoluteUrl: () => `${baseURL}/vatnumber/company/health`, requestSizeLimit: 3145728 }
            )
        },
        Venue: {
            Paginated: Object.assign(
                (dto: server.dto.ListVenueInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.VenueForList[], server.dto.ListVenueFilters>>(`/venue/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/venue/list`, requestSizeLimit: 3145728 }
            ),
            PaginatedSmall: Object.assign(
                (dto: server.dto.ListVenueInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.VenueSmall[], server.dto.ListVenueFilters>>(`/venue/listSmall`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/venue/listSmall`, requestSizeLimit: 3145728 }
            ),
            GetVenue: Object.assign(
                (venueId: number) => api.get<server.dto.Venue>(`/venue/${venueId}`, undefined),
                { absoluteUrl: (venueId: number) => `${baseURL}/venue/${venueId}`, requestSizeLimit: 3145728 }
            )
        },
        WebflowWebhook: {
            FormSubmission: Object.assign(
                (dto: server.dto.WebflowFormSubmissionInstruction) => api.post<any>(`/webhook/webflow`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/webhook/webflow`, requestSizeLimit: 3145728 }
            )
        },
        Webform: {
            Create: Object.assign(
                () => api.put<string>(`/webform/create`, undefined, undefined),
                { absoluteUrl: () => `${baseURL}/webform/create`, requestSizeLimit: 3145728 }
            ),
            SubmitBookingRequest: Object.assign(
                (instruction: server.dto.SubmitBookingRequestInstruction) => api.post<server.dto.Outcome>(`/webform/SubmitBookingRequest`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/webform/SubmitBookingRequest`, requestSizeLimit: 3145728 }
            ),
            CreateLog: Object.assign(
                (instruction: server.dto.CreateWebformLogInstruction) => api.post<void>(`/webform/log`, instruction, undefined),
                { absoluteUrl: () => `${baseURL}/webform/log`, requestSizeLimit: 3145728 }
            ),
            Paginated: Object.assign(
                (dto: server.dto.ListWebformInstruction) => api.post<server.dto.ApiResponseWrapperWithRequest<server.dto.WebformForList[], server.dto.ListWebformFilters>>(`/webform/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/webform/list`, requestSizeLimit: 3145728 }
            )
        },
        WordTemplate: {
            List: Object.assign(
                (dto: server.dto.ListWordTemplateInstruction) => api.post<server.dto.PaginationWrapper<server.dto.WordTemplate[]>>(`/wordtemplate/list`, dto, undefined),
                { absoluteUrl: () => `${baseURL}/wordtemplate/list`, requestSizeLimit: 3145728 }
            ),
            Copy: Object.assign(
                (wordTemplateId: number) => api.post<server.dto.CreateOutcome>(`/wordtemplate/${wordTemplateId}/copy`, undefined, undefined),
                { absoluteUrl: (wordTemplateId: number) => `${baseURL}/wordtemplate/${wordTemplateId}/copy`, requestSizeLimit: 3145728 }
            ),
            Delete: Object.assign(
                (wordTemplateId: number) => api.delete(`/wordtemplate/${wordTemplateId}/delete`, undefined),
                { absoluteUrl: (wordTemplateId: number) => `${baseURL}/wordtemplate/${wordTemplateId}/delete`, requestSizeLimit: 3145728 }
            ),
            DownloadFile: Object.assign(
                (wordTemplateId: number, fileName: string) => api.get<any>(`/wordtemplate/${wordTemplateId}/download/${fileName}`, undefined),
                { absoluteUrl: (wordTemplateId: number, fileName: string) => `${baseURL}/wordtemplate/${wordTemplateId}/download/${fileName}`, requestSizeLimit: 3145728 }
            )
        },
        WordTemplateForListView: {
            DeleteWordTemplateForListView: Object.assign(
                (wordTemplateForListViewId: number) => api.delete(`/wordtemplateforlistview/${wordTemplateForListViewId}`, undefined),
                { absoluteUrl: (wordTemplateForListViewId: number) => `${baseURL}/wordtemplateforlistview/${wordTemplateForListViewId}`, requestSizeLimit: 3145728 }
            ),
            CopyWordTemplateForListView: Object.assign(
                (wordTemplateForListViewId: number) => api.post<number>(`/wordtemplateforlistview/${wordTemplateForListViewId}/copy`, undefined, undefined),
                { absoluteUrl: (wordTemplateForListViewId: number) => `${baseURL}/wordtemplateforlistview/${wordTemplateForListViewId}/copy`, requestSizeLimit: 3145728 }
            ),
            EraseWordTemplateForListViewHistory: Object.assign(
                (wordTemplateForListViewId: number) => api.get<any>(`/wordtemplateforlistview/${wordTemplateForListViewId}/history/erase`, undefined),
                { absoluteUrl: (wordTemplateForListViewId: number) => `${baseURL}/wordtemplateforlistview/${wordTemplateForListViewId}/history/erase`, requestSizeLimit: 3145728 }
            ),
            DownloadFile: Object.assign(
                (fileId: string, fileName: string) => api.get<any>(`/wordtemplateforlistview/${fileId}/download/${fileName}`, undefined),
                { absoluteUrl: (fileId: string, fileName: string) => `${baseURL}/wordtemplateforlistview/${fileId}/download/${fileName}`, requestSizeLimit: 3145728 }
            )
        },
    };
    (window as any).CoreApi = apiFunctions;
    return apiFunctions;
}
export { createApiFunctions };