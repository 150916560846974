
/* tslint:disable:trailing-comma variable-name */
const WebApi = {

    Account: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/account/my/changename (POST)
        ChangeMyName: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/account/my/changename",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.Boolean>
        // /api/v1/account/changesecretquestion (POST)
        ChangeSecretQuestion: function (data) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/account/changesecretquestion",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/account/currentsecretanswer (GET)
        GetCurrentSecretAnswer: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/account/currentsecretanswer"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Int32>
        // /api/v1/account/currentsecretquestion (GET)
        GetCurrentSecretQuestion: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/account/currentsecretquestion"
            });
        },

        // Returns System.Web.Http.IHttpActionResult
        // /api/v1/account/secretquestionitem (GET)
        GetSecretQuestionItem: function (questionId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/account/secretquestionitem"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Boolean>
        // /api/v1/account/validatemypassword (POST)
        ValidateMyPassword: function (password) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/account/validatemypassword",
                data: JSON.stringify(password),
                processData: false
            });
        },

        // Returns SystemOne.Dto.ValidatePasswordFormattingResultDto
        // /api/v1/account/password/validateformatting (POST)
        ValidatePasswordFormatting: function (password) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/account/password/validateformatting",
                data: JSON.stringify(password),
                processData: false
            });
        },

    },

    Actions: {
        // Returns System.Boolean
        // /api/v1/isvalidurl (GET)
        IsValidUrl: function (url) {
            const apiUrl = "/api/v1/isvalidurl";
            const params = { "url": url };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

    },

    AdminOne: {
        // Returns System.Threading.Tasks.Task<System.Web.Http.IHttpActionResult>
        // /api/v1/adminone/createshowtodaterecords (POST)
        CreateShowToDateRecords: function (truncateExisting) {
            const apiUrl = "/api/v1/adminone/createshowtodaterecords";
            const params = { "truncateExisting": truncateExisting };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/adminone/redis/ping (GET)
        GetRedisPing: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/adminone/redis/ping"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/adminone/IndexStorageAccount (GET)
        IndexStorageAccount: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/adminone/IndexStorageAccount"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/adminone/GenerateAzCopyScript (GET)
        ListClientFileContainers: function (sourceSas, destinationSas) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/adminone/GenerateAzCopyScript"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.CheatingReprimandDto>>
        // /api/v1/adminone/Reprimands (GET)
        ListReprimands: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/adminone/Reprimands"
            });
        },

        // Returns System.String
        // /api/v1/adminone/MigrateRecipients (GET)
        MigrateRecipients: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/adminone/MigrateRecipients"
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/adminone/updateexternalidentities (POST)
        RefreshExternalIdentities: function (modifiedLongerThanHours) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/adminone/updateexternalidentities"
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/adminone/renewupcomingsubscriptions (GET)
        RenewUpcomingSubscriptions: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/adminone/renewupcomingsubscriptions"
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/adminone/sendemailreminders (GET)
        SendEmailReminders: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/adminone/sendemailreminders"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/adminone/sync/airlines (GET)
        SyncAirlinesFromFlightStats: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/adminone/sync/airlines"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/adminone/sync/airports (GET)
        SyncAirportsFromFlightStats: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/adminone/sync/airports"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/adminone/SyncUserLastActivity (GET)
        SyncUserLastActivity: function (withinMinutes) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/adminone/SyncUserLastActivity"
            });
        },

    },

    AdvancingTemplates: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/advancing/templates/{id} (DELETE)
        DeleteTemplate: function (id) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/advancing/templates/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.AdvancingFormTemplateDto>
        // /api/v1/advancing/templates/{id} (GET)
        GetTemplate: function (id) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/advancing/templates/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AdvancingFormTemplateDto>>
        // /api/v1/advancing/templates/details (GET)
        GetTemplates: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/advancing/templates/details"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.KeyValueSublabelDto>>
        // /api/v1/advancing/templates (GET)
        ListTemplates: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/advancing/templates"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.AdvancingFormTemplateDto>
        // /api/v1/advancing/templates/{id} (PUT)
        SaveTemplate: function (templateDto, id) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/advancing/templates/" + id,
                data: JSON.stringify(templateDto),
                processData: false
            });
        },

    },

    Airlines: {
        // Returns System.Collections.Generic.List<SystemOne.Dto.AirlineDto>
        // /api/v1/airlines/autocompletecodes (GET)
        AutocompleteWithCodes: function (term) {
            const apiUrl = "/api/v1/airlines/autocompletecodes";
            const params = { "term": term };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

    },

    Airports: {
        // Returns System.Collections.Generic.List<SystemOne.Dto.AirportDto>
        // /api/v1/airports/autocomplete (GET)
        Autocomplete: function (term, excludeKeys) {
            const apiUrl = "/api/v1/airports/autocomplete";
            const params = { "term": term, "excludeKeys": excludeKeys };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },
    },

    Artists: {
        // Returns System.Threading.Tasks.Task<System.Collections.Generic.IList<SystemOne.Dto.CompanySmallDto>>
        // /api/v1/artists/autocomplete (GET)
        Autocomplete: function (term, excludeKeys) {
            const apiUrl = "/api/v1/artists/autocomplete";
            const params = { "term": term, "excludeKeys": excludeKeys };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ArtistSmallDto>>
        // /api/v1/artists/allartists (GET)
        GetAllArtists: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/artists/allartists"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.LegacyArtistDto>>>
        // /api/v1/artists/myartists (POST)
        GetArtistsForCurrentUserPaginated: function (keyword, excludeIds, page, per_page) {
            const apiUrl = "/api/v1/artists/myartists";
            const params = { "keyword": keyword, "page": page, "per_page": per_page };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params),
                data: JSON.stringify(excludeIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.ArtistSmallDto>>>
        // /api/v1/artists/all (POST)
        GetArtistsPaginated: function (keyword, excludeIds, page, per_page) {
            const apiUrl = "/api/v1/artists/all";
            const params = { "keyword": keyword, "page": page, "per_page": per_page };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params),
                data: JSON.stringify(excludeIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.ArtistSmallDto>>>
        // /api/v1/artists (GET)
        GetMyArtists: function (page, per_page) {
            const apiUrl = "/api/v1/artists";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.KeyValueSublabelDto>>
        // /api/v1/artists/recentlyusedfordropdown (GET)
        GetRecentlyUsedForDropdown: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/artists/recentlyusedfordropdown"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.WebApi.LegacyDto.LegacyAppointmentDto>>>
        // /api/v1/artists/{artistId}/appointments (GET)
        ListAppointments: function (artistId, timeMin, timeMax, page, per_page, appointmentTypes) {
            const apiUrl = "/api/v1/artists/" + artistId + "/appointments";
            const params = { "timeMin": timeMin, "timeMax": timeMax, "page": page, "per_page": per_page, "appointmentTypes": appointmentTypes };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.UserThatCanAccessArtistDto>>
        // /api/v1/artists/{artistId}/UsersWithAccess (GET)
        ListUsersWithAccess: function (artistId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/artists/" + artistId + "/UsersWithAccess"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ArtistSmallDto>>
        // /api/v1/artists/recentlyused (GET)
        RecentlyUsed: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/artists/recentlyused"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.SemanticUiApiDto>
        // /api/v1/artists/all/search/{query} (GET)
        SearchAllArtists: function (query) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/artists/all/search/" + query
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.SemanticUiApiDto>
        // /api/v1/artists/myartists/search/{query} (GET)
        SearchMyArtists: function (query) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/artists/myartists/search/" + query
            });
        },
    },

    Availability: {
        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ArtistForAvailibilityDto>>
        // /api/v1/availability/artists (GET)
        Artists: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/availability/artists"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.Collections.Generic.List<SystemOne.Dto.AvailableDatesPerArtistDto>>>
        // /api/v1/availability (POST)
        GetAvailability: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/availability",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/availability/artists/{artistId}/hide (POST)
        HideArtist: function (artistId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/availability/artists/" + artistId + "/hide"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/availability/artists/{artistId}/unhide (POST)
        UnhideArtist: function (artistId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/availability/artists/" + artistId + "/unhide"
            });
        },

    },

    ChecklistPreset: {
        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateChecklistPresetItemOutcomeDto>
        // /api/v1/checklistPreset/item/create (POST)
        CreateChecklistPresetItem: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/checklistPreset/item/create",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/checklistPreset/item/{checklistPresetItemId}/delete (GET)
        DeleteChecklistPresetItem: function (checklistPresetItemId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/checklistPreset/item/" + checklistPresetItemId + "/delete"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ChecklistPresetItemDto>>
        // /api/v1/checklistPreset/item/list (POST)
        ListChecklistPresetItem: function (checklistArea) {
            const apiUrl = "/api/v1/checklistPreset/item/list";
            const params = { "checklistArea": checklistArea };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.UpdateChecklistPresetItemOutcomeDto>
        // /api/v1/checklistPreset/item/update (POST)
        UpdateChecklistPresetItem: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/checklistPreset/item/update",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/checklistPreset/item/sort (POST)
        UpdateChecklistPresetItemOrder: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/checklistPreset/item/sort",
                data: JSON.stringify(dto),
                processData: false
            });
        },

    },

    Companies: {
        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.FileDto>>>
        // /api/v1/companies/{companyId}/files (GET)
        FilesGet: function (companyId, page, per_page) {
            const apiUrl = "/api/v1/companies/" + companyId + "/files";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/companies/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/companies/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/companies/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/companies/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.CompanyFiltersDto>>
        // /api/v1/companies/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/companies/{companyId}/markers (POST)
        CachePointOfInterestMarkers: function (companyId, markers) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/companies/" + companyId + "/markers",
                data: JSON.stringify(markers),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/companies/{companyId}/delete (DELETE)
        Delete: function (companyId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/companies/" + companyId + "/delete"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/companies/export/emails/{fileName} (GET)
        ExportEmails: function (fileName) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/export/emails/" + fileName
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/companies/export/list/{fileName} (GET)
        ExportList: function (fileName, page, templateId) {
            const apiUrl = "/api/v1/companies/export/list/" + fileName;
            const params = { "page": page, "templateId": templateId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.CompanyForListDto>>>
        // /api/v1/companies (GET)
        GetCompanies: function (page, per_page, filters) {
            const apiUrl = "/api/v1/companies";
            const params = { "page": page, "per_page": per_page, "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CompanyFiltersDto>
        // /api/v1/companies/filters (GET)
        GetFilters: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/filters"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.LegacyCompanyDto>
        // /api/v1/companies/{companyId} (GET)
        GetLegacyDetails: function (companyId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/" + companyId
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AirportDto>>
        // /api/v1/companies/{companyId}/nearbyAirports (GET)
        GetNearbyAirports: function (companyId, radius) {
            const apiUrl = "/api/v1/companies/" + companyId + "/nearbyAirports";
            const params = { "radius": radius };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AddressMarkerDto>>
        // /api/v1/companies/{companyId}/markers (GET)
        GetPointOfInterestMarkers: function (companyId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/" + companyId + "/markers"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProcessedEmailTemplateDto>
        // /api/v1/companies/emails/templates/{emailTemplateId}/process/{companyId} (GET)
        GetProcessedEmailTemplate: function (emailTemplateId, companyId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/emails/templates/" + emailTemplateId + "/process/" + companyId
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/companies/{companyId}/riskinfo (GET)
        GetRiskInfo: function (companyId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/" + companyId + "/riskinfo"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CompanyTooltipDto>
        // /api/v1/companies/{companyId}/tooltip (GET)
        GetTooltip: function (companyId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/" + companyId + "/tooltip"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ExportOptionDto>>
        // /api/v1/companies/exports (GET)
        ListExports: function (filters) {
            const apiUrl = "/api/v1/companies/exports";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.RelatedEmailAddressEntryDto>>
        // /api/v1/companies/{companyId}/related/emailaddress (GET)
        ListRelatedEmailAddress: function (companyId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/" + companyId + "/related/emailaddress"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.RelatedFilesDto>
        // /api/v1/companies/{companyId}/files/related (GET)
        ListRelatedFiles: function (companyId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/" + companyId + "/files/related"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Boolean>
        // /api/v1/companies/nameexists (GET)
        NameExists: function (name, excludeCompanyId) {
            const apiUrl = "/api/v1/companies/nameexists";
            const params = { "name": name, "excludeCompanyId": excludeCompanyId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AddressBookEntryDto>>
        // /api/v1/companies/{companyId}/relatedcontacts (GET)
        RelatedContacts: function (companyId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/" + companyId + "/relatedcontacts"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Web.Http.IHttpActionResult>
        // /api/v1/companies/{companyId}/marker (POST)
        UpdateMarker: function (companyId, dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/companies/" + companyId + "/marker",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/companies/exceltemplateforlistview (POST)
        CreateExcelTemplateForListView: function (excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/companies/exceltemplateforlistview",
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ExcelTemplateForListViewDto>
        // /api/v1/companies/exceltemplateforlistview/{id} (GET)
        ExcelTemplateForListView: function (id) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/companies/exceltemplateforlistview/{id} (DELETE)
        ExcelTemplateForListViewDelete: function (id) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/companies/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/companies/export/item/{companyId}/{exportAction}/{clientFileName} (GET)
        ExportItem: function (companyId, exportAction, clientFileName, wordTemplateId, preferredCulture) {
            const apiUrl = "/api/v1/companies/export/item/" + companyId + "/" + exportAction + "/" + clientFileName;
            const params = { "wordTemplateId": wordTemplateId, "preferredCulture": preferredCulture };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/companies/exceltemplateforlistview/{id} (POST)
        UpdateExcelTemplateForListView: function (id, excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/companies/exceltemplateforlistview/" + id,
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/companies/{companyId}/emails/{emailId} (DELETE)
        DeleteEmail: function (companyId, emailId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/companies/" + companyId + "/emails/" + emailId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.EmailMessageDto>>>
        // /api/v1/companies/{companyId}/emails (GET)
        GetEmails: function (companyId, page, per_page) {
            const apiUrl = "/api/v1/companies/" + companyId + "/emails";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.KeyValueSublabelDto>>
        // /api/v1/companies/emails/templates (GET)
        ListEmailTemplates: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/companies/emails/templates"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/companies/{companyId}/emails (POST)
        SendEmail: function (email, companyId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/companies/" + companyId + "/emails",
                data: JSON.stringify(email),
                processData: false
            });
        },
    },

    Contacts: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contacts/{contactId}/delete (DELETE)
        Delete: function (contactId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/contacts/" + contactId + "/delete"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/contacts/export/emails/{fileName} (GET)
        ExportEmails: function (fileName) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/export/emails/" + fileName
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/contacts/export/list/{fileName} (GET)
        ExportList: function (fileName, page, templateId) {
            const apiUrl = "/api/v1/contacts/export/list/" + fileName;
            const params = { "page": page, "templateId": templateId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Web.Http.IHttpActionResult>
        // /api/v1/contacts (GET)
        GetContacts: function (page, per_page, filters) {
            const apiUrl = "/api/v1/contacts";
            const params = { "page": page, "per_page": per_page, "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ContactSmallDto>
        // /api/v1/contacts/{contactId} (GET)
        GetDetails: function (contactId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/" + contactId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ContactFiltersDto>
        // /api/v1/contacts/filters (GET)
        GetFilters: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/filters"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProcessedEmailTemplateDto>
        // /api/v1/contacts/emails/templates/{emailTemplateId}/process/{contactId} (GET)
        GetProcessedEmailTemplate: function (emailTemplateId, contactId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/emails/templates/" + emailTemplateId + "/process/" + contactId
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ExportOptionDto>>
        // /api/v1/contacts/exports (GET)
        ListExports: function (filters) {
            const apiUrl = "/api/v1/contacts/exports";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.RelatedEmailAddressEntryDto>>
        // /api/v1/contacts/{contactId}/related/emailaddress (GET)
        ListRelatedEmailAddress: function (contactId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/" + contactId + "/related/emailaddress"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.RelatedFilesDto>
        // /api/v1/contacts/{contactId}/files/related (GET)
        ListRelatedFiles: function (contactId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/" + contactId + "/files/related"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Web.Http.IHttpActionResult>
        // /api/v1/contacts/nameexists (GET)
        NameExists: function (name) {
            const apiUrl = "/api/v1/contacts/nameexists";
            const params = { "name": name };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ContactSmallDto>>
        // /api/v1/contacts/recentlyused (GET)
        RecentlyUsed: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/recentlyused"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AddressBookEntryDto>>
        // /api/v1/contacts/{contactId}/relatedcontacts (GET)
        RelatedContacts: function (contactId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/" + contactId + "/relatedcontacts"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AddressBookEntryDto>>
        // /api/v1/contacts/addressbook/search (GET)
        SearchAddressBook: function (keyword) {
            const apiUrl = "/api/v1/contacts/addressbook/search";
            const params = { "keyword": keyword };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.RelatedEmailAddressEntryDto>>
        // /api/v1/contacts/emailaddress/search (GET)
        SearchEmailAddress: function (keyword) {
            const apiUrl = "/api/v1/contacts/emailaddress/search";
            const params = { "keyword": keyword };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },


        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/contacts/exceltemplateforlistview (POST)
        CreateExcelTemplateForListView: function (excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contacts/exceltemplateforlistview",
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ExcelTemplateForListViewDto>
        // /api/v1/contacts/exceltemplateforlistview/{id} (GET)
        ExcelTemplateForListView: function (id) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contacts/exceltemplateforlistview/{id} (DELETE)
        ExcelTemplateForListViewDelete: function (id) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/contacts/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/contacts/export/item/{contactId}/{exportAction}/{clientFileName} (GET)
        ExportItem: function (contactId, exportAction, clientFileName, wordTemplateId, preferredCulture) {
            const apiUrl = "/api/v1/contacts/export/item/" + contactId + "/" + exportAction + "/" + clientFileName;
            const params = { "wordTemplateId": wordTemplateId, "preferredCulture": preferredCulture };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/contacts/exceltemplateforlistview/{id} (POST)
        UpdateExcelTemplateForListView: function (id, excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contacts/exceltemplateforlistview/" + id,
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.FileDto>>>
        // /api/v1/contacts/{contactId}/files (GET)
        FilesGet: function (contactId, page, per_page) {
            const apiUrl = "/api/v1/contacts/" + contactId + "/files";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contacts/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/contacts/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contacts/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/contacts/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.ContactFiltersDto>>
        // /api/v1/contacts/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contacts/{contactId}/emails/{emailId} (DELETE)
        DeleteEmail: function (contactId, emailId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/contacts/" + contactId + "/emails/" + emailId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.EmailMessageDto>>>
        // /api/v1/contacts/{contactId}/emails (GET)
        GetEmails: function (contactId, page, per_page) {
            const apiUrl = "/api/v1/contacts/" + contactId + "/emails";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.KeyValueSublabelDto>>
        // /api/v1/contacts/emails/templates (GET)
        ListEmailTemplates: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contacts/emails/templates"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contacts/{contactId}/emails (POST)
        SendEmail: function (email, contactId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contacts/" + contactId + "/emails",
                data: JSON.stringify(email),
                processData: false
            });
        },
    },

    Contracts: {
        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.DocumentForListDto>>>
        // /api/v1/contracts (GET)
        GetContracts: function (page, per_page, filters) {
            const apiUrl = "/api/v1/contracts";
            const params = { "page": page, "per_page": per_page, "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ContractFiltersDto>
        // /api/v1/contracts/filters (GET)
        GetFilters: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/filters"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProcessedEmailTemplateDto>
        // /api/v1/contracts/emails/templates/{emailTemplateId}/process/{contractId} (GET)
        GetProcessedEmailTemplate: function (emailTemplateId, contractId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/emails/templates/" + emailTemplateId + "/process/" + contractId
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ExportOptionDto>>
        // /api/v1/contracts/exports (GET)
        ListExports: function (filters) {
            const apiUrl = "/api/v1/contracts/exports";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.RelatedEmailAddressEntryDto>>
        // /api/v1/contracts/{documentId}/related/emailaddress (GET)
        ListRelatedEmailAddress: function (documentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/" + documentId + "/related/emailaddress"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.RelatedFilesDto>
        // /api/v1/contracts/{contractId}/files/related (GET)
        ListRelatedFiles: function (contractId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/" + contractId + "/files/related"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contracts/{contractId}/lock (POST)
        Lock: function (contractId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contracts/" + contractId + "/lock"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AddressBookEntryDto>>
        // /api/v1/contracts/{documentId}/relatedcontacts (GET)
        RelatedContacts: function (documentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/" + documentId + "/relatedcontacts"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contracts/{contractId}/unlock (POST)
        Unlock: function (contractId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contracts/" + contractId + "/unlock"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contracts/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/contracts/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contracts/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/contracts/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.ContractFiltersDto>>
        // /api/v1/contracts/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.FileDto>>>
        // /api/v1/contracts/{contractId}/files (GET)
        FilesGet: function (contractId, page, per_page) {
            const apiUrl = "/api/v1/contracts/" + contractId + "/files";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contracts/{contractId}/emails/{emailId} (DELETE)
        DeleteEmail: function (contractId, emailId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/contracts/" + contractId + "/emails/" + emailId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.EmailMessageDto>>>
        // /api/v1/contracts/{contractId}/emails (GET)
        GetEmails: function (contractId, page, per_page) {
            const apiUrl = "/api/v1/contracts/" + contractId + "/emails";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.KeyValueSublabelDto>>
        // /api/v1/contracts/emails/templates (GET)
        ListEmailTemplates: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/emails/templates"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contracts/{contractId}/emails (POST)
        SendEmail: function (email, contractId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contracts/" + contractId + "/emails",
                data: JSON.stringify(email),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/contracts/myselection/tags/add (PUT)
        BatchAddTags: function (tagIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/contracts/myselection/tags/add",
                data: JSON.stringify(tagIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/contracts/myselection/tags/replace (PUT)
        BatchReplaceTags: function (tagIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/contracts/myselection/tags/replace",
                data: JSON.stringify(tagIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.DocumentDto>
        // /api/v1/contracts/{contractId}/copy (PUT)
        Copy: function (contractId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/contracts/" + contractId + "/copy",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.DocumentDto>
        // /api/v1/contracts (PUT)
        Create: function (contract) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/contracts",
                data: JSON.stringify(contract),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/contracts/signing/fromshow/{showId}/create (POST)
        CreateESignFromShow: function (showId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contracts/signing/fromshow/" + showId + "/create"
            });
        },


        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/contracts/export/list/custom/general/{fileName} (GET)
        ExportCustomListGeneral: function (fileName, page) {
            const apiUrl = "/api/v1/contracts/export/list/custom/general/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/contracts/export/list/{fileName} (GET)
        ExportList: function (fileName, page, templateId) {
            const apiUrl = "/api/v1/contracts/export/list/" + fileName;
            const params = { "page": page, "templateId": templateId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.GroupedDocumentTotalsResultDto>
        // /api/v1/contracts/stats/totalsdata (GET)
        GetTotalsData: function (bookkeepingId, period) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/stats/totalsdata"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TotalsPerOwnerDto>
        // /api/v1/contracts/stats/totalperowner (GET)
        GetTotalsPerOwner: function (bookkeepingId, period, selectionStart, selectionEnd) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/stats/totalperowner"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contracts/{contractId}/shows/link (PUT)
        LinkShows: function (contractId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/contracts/" + contractId + "/shows/link",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ShowsByParentDto>
        // /api/v1/contracts/{contractId}/shows/{showId}/unlink (PUT)
        UnlinkShow: function (contractId, showId) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/contracts/" + contractId + "/shows/" + showId + "/unlink"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.DocumentDto>
        // /api/v1/contracts/{contractId} (POST)
        Update: function (contractId, contract) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contracts/" + contractId,
                data: JSON.stringify(contract),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/contracts/exceltemplateforlistview (POST)
        CreateExcelTemplateForListView: function (excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contracts/exceltemplateforlistview",
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ExcelTemplateForListViewDto>
        // /api/v1/contracts/exceltemplateforlistview/{id} (GET)
        ExcelTemplateForListView: function (id) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/contracts/exceltemplateforlistview/{id} (DELETE)
        ExcelTemplateForListViewDelete: function (id) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/contracts/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/contracts/export/item/{contractId}/{exportAction}/{clientFileName} (GET)
        ExportItem: function (contractId, exportAction, clientFileName, wordTemplateId, preferredCulture) {
            const apiUrl = "/api/v1/contracts/export/item/" + contractId + "/" + exportAction + "/" + clientFileName;
            const params = { "wordTemplateId": wordTemplateId, "preferredCulture": preferredCulture };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/contracts/exceltemplateforlistview/{id} (POST)
        UpdateExcelTemplateForListView: function (id, excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contracts/exceltemplateforlistview/" + id,
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/contracts/{contractId}/products (PUT)
        ProductCreate: function (contractId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/contracts/" + contractId + "/products",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/contracts/{contractId}/products/{productId} (DELETE)
        ProductDelete: function (contractId, productId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/contracts/" + contractId + "/products/" + productId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProductsByParentDto>
        // /api/v1/contracts/{contractId}/products (GET)
        ProductsGet: function (contractId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/contracts/" + contractId + "/products"
            });
        },

        // Returns System.Threading.Tasks.Task
        // /api/v1/contracts/{contractId}/products/sort (POST)
        ProductsUpdateSort: function (contractId, ids) {
            const apiUrl = "/api/v1/contracts/" + contractId + "/products/sort";
            const params = { "ids": ids };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/contracts/{contractId}/products/{productId} (POST)
        ProductUpdate: function (contractId, productId, data) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/contracts/" + contractId + "/products/" + productId,
                data: JSON.stringify(data),
                processData: false
            });
        },


    },

    Document: {
        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ShowsByParentDto>
        // /api/v1/document/{documentId}/shows (GET)
        ListShows: function (documentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/document/" + documentId + "/shows"
            });
        },

    },

    EmailTemplates: {
        // Returns System.Threading.Tasks.Task<System.Int32>
        // /api/v1/emailtemplates/{emailTemplateId}/copy (POST)
        Copy: function (emailTemplateId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/emailtemplates/" + emailTemplateId + "/copy"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/emailtemplates/{emailTemplateId} (DELETE)
        Delete: function (emailTemplateId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/emailtemplates/" + emailTemplateId
            });
        },

    },

    ExcelTemplate: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/exceltemplates/{templateId} (DELETE)
        DeleteColumn: function (templateId, mergeField) {
            const apiUrl = "/api/v1/exceltemplates/" + templateId;
            const params = { "mergeField": mergeField };
            return $.ajax({
                type: "DELETE",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/exceltemplates/{templateId}/columns (POST)
        StoreColumn: function (templateId, column) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/exceltemplates/" + templateId + "/columns",
                data: JSON.stringify(column),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/exceltemplates/{templateId}/columnorder (POST)
        UpdateColumnOrder: function (templateId, columns) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/exceltemplates/" + templateId + "/columnorder",
                data: JSON.stringify(columns),
                processData: false
            });
        },

    },

    ExternalCalendars: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/externalcalendars/{externalCalendarId} (DELETE)
        Delete: function (externalCalendarId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/externalcalendars/" + externalCalendarId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.WebApi.LegacyDto.LegacyExternalEventDto>
        // /api/v1/externalcalendars/{externalCalendarId}/events/{externalEventId} (GET)
        GetExternalEvent: function (externalCalendarId, externalEventId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/externalcalendars/" + externalCalendarId + "/events/" + externalEventId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TooltipDto>
        // /api/v1/externalcalendars/{externalEventId}/tooltip (GET)
        GetToolTip: function (externalEventId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/externalcalendars/" + externalEventId + "/tooltip"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.GoogleCalendarDto>>
        // /api/v1/externalcalendars/googlecalendars/list (GET)
        ListMyGoogleCalendars: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/externalcalendars/googlecalendars/list"
            });
        },

    },

    ExternalEventsources: {
        // Returns System.Threading.Tasks.Task<SystemOne.WebApi.LegacyDto.LegacyExternalEventDto>
        // /api/v1/externaleventsources/{externalCalendarId}/events/{externalEventId} (GET)
        GetExternalEvent: function (externalCalendarId, externalEventId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/externaleventsources/" + externalCalendarId + "/events/" + externalEventId
            });
        },

    },

    ExternalShows: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/externalshows/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/externalshows/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/externalshows/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/externalshows/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.ExternalShowFiltersDto>>
        // /api/v1/externalshows/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/externalshows/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.ShowForListDto>>>
        // /api/v1/externalshows (GET)
        GetShows: function (filters, page, per_page) {
            const apiUrl = "/api/v1/externalshows";
            const params = { "filters": filters, "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.MiniCalendarDto>
        // /api/v1/externalshows/countformonth/{year}/{month} (GET)
        GetShowsCountForMonth: function (year, month, filters) {
            const apiUrl = "/api/v1/externalshows/countformonth/" + year + "/" + month;
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ExportOptionDto>>
        // /api/v1/externalshows/exports (GET)
        ListExports: function (filters) {
            const apiUrl = "/api/v1/externalshows/exports";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

    },

    Flights: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/flights/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/flights/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/flights/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/flights/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.FlightFiltersDto>>
        // /api/v1/flights/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/flights/filterpresets/"
            });
        },


        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FlightDto>
        // /api/v1/flights (POST)
        CreateFlight: function (flight) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/flights",
                data: JSON.stringify(flight),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/flights/{flightId} (DELETE)
        Delete: function (flightId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/flights/" + flightId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FlightDto>
        // /api/v1/flights/segments/{flightSegmentId}/delete (POST)
        DeleteSegment: function (flightSegmentId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/flights/segments/" + flightSegmentId + "/delete"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FlightDto>
        // /api/v1/flights/{flightId} (GET)
        GetFlight: function (flightId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/flights/" + flightId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FlightDto>
        // /api/v1/flights/bysegment/{segmentId} (GET)
        GetFlightBySegment: function (segmentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/flights/bysegment/" + segmentId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.Collections.Generic.List<SystemOne.Dto.FlightJourneyDto>, SystemOne.WebApi.Request.FlightListRequestDto>>
        // /api/v1/flights (GET)
        GetFlights: function (filters, page, per_page) {
            const apiUrl = "/api/v1/flights";
            const params = { "filters": filters, "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.FrequentFlyerProgramDto>>
        // /api/v1/flights/frequentflyerprograms (GET)
        GetFrequentFlyerPrograms: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/flights/frequentflyerprograms"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.WebApi.LegacyDto.LegacyFlightJourneyDto>
        // /api/v1/flights/{flightId}/segments/{flightSegmentId} (GET)
        GetLegacyFlightJourney: function (flightId, flightSegmentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/flights/" + flightId + "/segments/" + flightSegmentId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TooltipDto>
        // /api/v1/flights/segments/{flightSegmentId}/tooltip (GET)
        GetToolTip: function (flightSegmentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/flights/segments/" + flightSegmentId + "/tooltip"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.FileDto>>
        // /api/v1/flights/{flightId}/files (GET)
        ListFlightFiles: function (flightId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/flights/" + flightId + "/files"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.FlightSegmentDto>>
        // /api/v1/flights/flightdetails (GET)
        RetrieveFlightDetails: function (flightNumber, airlineId, departure) {
            const apiUrl = "/api/v1/flights/flightdetails";
            const params = { "flightNumber": flightNumber, "airlineId": airlineId, "departure": departure };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FlightDto>
        // /api/v1/flights/{flightId} (PUT)
        UpdateFlight: function (flightId, flight) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/flights/" + flightId,
                data: JSON.stringify(flight),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FileUploadResultDto[]>
        // /api/v1/flights/{flightId}/files/upload (POST)
        UploadFlightFiles: function (flightId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/flights/" + flightId + "/files/upload"
            });
        },

    },

    FreeFieldCategories: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/freefieldcategories/{freeFieldCategoryId} (DELETE)
        Delete: function (freeFieldCategoryId, targetId) {
            const apiUrl = "/api/v1/freefieldcategories/" + freeFieldCategoryId;
            const params = { "targetId": targetId };
            return $.ajax({
                type: "DELETE",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/freefieldcategories/sort (POST)
        StoreSorted: function (mainArea, keys) {
            const apiUrl = "/api/v1/freefieldcategories/sort";
            const params = { "mainArea": mainArea, "keys": keys };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/freefieldcategories/{freeFieldCategoryId}/definitions/sort (POST)
        StoreSortedDefinitions: function (freeFieldCategoryId, keys) {
            const apiUrl = "/api/v1/freefieldcategories/" + freeFieldCategoryId + "/definitions/sort";
            const params = { "keys": keys };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

    },

    FreeFieldDefinitions: {
        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.FreeFieldProfileWithPermissionDto>>
        // /api/v1/freefielddefinitions/{freeFieldDefinitionId}/profiles/assignlevels (POST)
        AssignProfileLevelsToFreeFieldDefinition: function (freeFieldDefinitionId, instruction) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/freefielddefinitions/" + freeFieldDefinitionId + "/profiles/assignlevels",
                data: JSON.stringify(instruction),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/freefielddefinitions/{freefieldDefinitionId} (DELETE)
        Delete: function (freefieldDefinitionId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/freefielddefinitions/" + freefieldDefinitionId
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.FreeFieldProfileWithPermissionDto>>
        // /api/v1/freefielddefinitions/{freeFieldDefinitionId}/profiles (GET)
        ListProfilesByDefinition: function (freeFieldDefinitionId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/freefielddefinitions/" + freeFieldDefinitionId + "/profiles"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Boolean>
        // /api/v1/freefielddefinitions/referenceexists (GET)
        ReferenceExists: function (mainArea, reference, excludeId) {
            const apiUrl = "/api/v1/freefielddefinitions/referenceexists";
            const params = { "mainArea": mainArea, "reference": reference, "excludeId": excludeId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

    },

    FreeFieldProfiles: {
        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FreeFieldProfileDto>
        // /api/v1/freefieldprofiles/{environment} (PUT)
        CreateProfile: function (environment, instruction) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/freefieldprofiles/" + environment,
                data: JSON.stringify(instruction),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/freefieldprofiles/{freeFieldProfileId} (DELETE)
        DeleteFreeFieldProfile: function (freeFieldProfileId, moveToFreefieldProfileId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/freefieldprofiles/" + freeFieldProfileId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.FreeFieldProfileDto>>>
        // /api/v1/freefieldprofiles/{environment} (GET)
        Paginate: function (environment, page, per_page, keyword) {
            const apiUrl = "/api/v1/freefieldprofiles/" + environment;
            const params = { "page": page, "per_page": per_page, "keyword": keyword };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.UserSmallDto>>
        // /api/v1/freefieldprofiles/{freeFieldProfileId}/users/{environment}/{userId}/remove (POST)
        RemoveFreeFieldProfileForUser: function (freeFieldProfileId, environment, userId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/freefieldprofiles/" + freeFieldProfileId + "/users/" + environment + "/" + userId + "/remove"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/freefieldprofiles/{freeFieldProfileId}/level (POST)
        SetLevelForFreeFields: function (freeFieldProfileId, instruction) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/freefieldprofiles/" + freeFieldProfileId + "/level",
                data: JSON.stringify(instruction),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FreeFieldProfileDto>
        // /api/v1/freefieldprofiles/{freeFieldProfileId} (POST)
        UpdateProfile: function (freeFieldProfileId, instruction) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/freefieldprofiles/" + freeFieldProfileId,
                data: JSON.stringify(instruction),
                processData: false
            });
        },

    },

    GeoCode: {
        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.GeoCodeHealthDto>>
        // /api/v1/geocode/health (GET)
        GeoCodeHealth: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/geocode/health"
            });
        },

    },

    GermanTaxOffice: {
        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.GermanTaxOfficeConfirmationDto, SystemOne.Dto.GermanTaxOfficeRequestDto>>
        // /api/v1/germantaxoffice/validate (POST)
        Validate: function (req) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/germantaxoffice/validate",
                data: JSON.stringify(req),
                processData: false
            });
        },

    },

    GooglePlaces: {
        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.GooglePlaceAutocompleteDto>>
        // /api/v1/googleplaces/autocomplete (GET)
        Autocomplete: function (query) {
            const apiUrl = "/api/v1/googleplaces/autocomplete";
            const params = { "query": query };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.GooglePlaceDto>
        // /api/v1/googleplaces/places/{placeId} (GET)
        GetPlace: function (placeId, calledFrom) {
            const apiUrl = "/api/v1/googleplaces/places/" + placeId;
            const params = { "calledFrom": calledFrom };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.GooglePlaceAutocompleteDto>>
        // /api/v1/googleplaces/textsearch (GET)
        TextSearch: function (query, type) {
            const apiUrl = "/api/v1/googleplaces/textsearch";
            const params = { "query": query, "type": type };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

    },

    GroundTransports: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/groundtransports/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/groundtransports/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/groundtransports/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/groundtransports/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.GroundTransportFiltersDto>>
        // /api/v1/groundtransports/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/groundtransports/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.GroundTransportDto>
        // /api/v1/groundtransports (POST)
        CreateGroundTransport: function (groundTransport) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/groundtransports",
                data: JSON.stringify(groundTransport),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/groundtransports/{groundTransportId} (DELETE)
        Delete: function (groundTransportId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/groundtransports/" + groundTransportId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.Collections.Generic.List<SystemOne.Dto.GroundTransportDto>, SystemOne.WebApi.Request.GroundTransportListRequestDto>>
        // /api/v1/groundtransports (GET)
        GetGroundTransports: function (filters, page, per_page) {
            const apiUrl = "/api/v1/groundtransports";
            const params = { "filters": filters, "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TooltipDto>
        // /api/v1/groundtransports/{groundTransportId}/tooltip (GET)
        GetToolTip: function (groundTransportId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/groundtransports/" + groundTransportId + "/tooltip"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.GoogleDirectionsDto>
        // /api/v1/groundtransports/googledirections (GET)
        GoogleDirections: function (startPlaceId, endPlaceId) {
            const apiUrl = "/api/v1/groundtransports/googledirections";
            const params = { "startPlaceId": startPlaceId, "endPlaceId": endPlaceId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.GroundTransportDto>
        // /api/v1/groundtransports/{groundTransportId} (PUT)
        UpdateGroundTransport: function (groundTransportId, groundTransport) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/groundtransports/" + groundTransportId,
                data: JSON.stringify(groundTransport),
                processData: false
            });
        },

    },

    Guestlist: {
        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.String, System.Object>>
        // /api/v1/guestlist/place/{placeId}/guest (PUT)
        AddGuest: function (dto, placeId) {
            const apiUrl = "/api/v1/guestlist/place/" + placeId + "/guest";
            const params = { "placeId": placeId };
            return $.ajax({
                type: "PUT",
                url: window.urlTools.addParameters(apiUrl, params),
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.String, System.Object>>
        // /api/v1/guestlist/{showId} (PUT)
        AddGuestlist: function (showId) {
            const apiUrl = "/api/v1/guestlist/" + showId;
            const params = { "showId": showId };
            return $.ajax({
                type: "PUT",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.String, System.Object>>
        // /api/v1/guestlist/place (PUT)
        AddGuestlistPlace: function (dto, guestlistId) {
            const apiUrl = "/api/v1/guestlist/place";
            const params = { "guestlistId": guestlistId };
            return $.ajax({
                type: "PUT",
                url: window.urlTools.addParameters(apiUrl, params),
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.String, System.Object>>
        // /api/v1/guestlist/{guestlistId} (DELETE)
        DeleteGuestlist: function (guestlistId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/guestlist/" + guestlistId
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/guestlist/export/xlsx/{showId} (GET)
        ExportXslx: function (showId) {
            const apiUrl = "/api/v1/guestlist/export/xlsx/" + showId;
            const params = { "showId": showId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.GuestlistDto, System.Object>>
        // /api/v1/guestlist/{showId} (GET)
        GetGuestlistByShow: function (showId) {
            const apiUrl = "/api/v1/guestlist/" + showId;
            const params = { "showId": showId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.String, System.Object>>
        // /api/v1/guestlist/place/guest (DELETE)
        LegacyRemoveGuest: function (guestId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/guestlist/place/guest",
                data: JSON.stringify(guestId),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.String, System.Object>>
        // /api/v1/guestlist/place (DELETE)
        LegacyRemoveGuestlistPlace: function (placeId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/guestlist/place",
                data: JSON.stringify(placeId),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.String, System.Object>>
        // /api/v1/guestlist/place/guest/{guestId} (DELETE)
        RemoveGuest: function (guestId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/guestlist/place/guest/" + guestId
            });
        },

        // Returns System.Threading.Tasks.Task<System.Web.Http.IHttpActionResult>
        // /api/v1/guestlist/place/{placeId} (DELETE)
        RemoveGuestlistPlace: function (placeId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/guestlist/place/" + placeId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.String, System.Object>>
        // /api/v1/guestlist/place/guest (POST)
        UpdateGuest: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/guestlist/place/guest",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.String, System.Object>>
        // /api/v1/guestlist (POST)
        UpdateGuestlist: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/guestlist",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.Web.Http.IHttpActionResult>
        // /api/v1/guestlist/place (POST)
        UpdateGuestlistPlace: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/guestlist/place",
                data: JSON.stringify(dto),
                processData: false
            });
        },

    },

    ICalendarTemplates: {
        // Returns System.Threading.Tasks.Task<System.Int32>
        // /api/v1/icalendartemplates/{iCalendarTemplateId}/copy (POST)
        Copy: function (iCalendarTemplateId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/icalendartemplates/" + iCalendarTemplateId + "/copy"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/icalendartemplates/{iCalendarTemplateId} (DELETE)
        Delete: function (iCalendarTemplateId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/icalendartemplates/" + iCalendarTemplateId
            });
        },

    },

    Invoices: {
        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/invoices/myselection/tags/add (PUT)
        BatchAddTags: function (tagIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/invoices/myselection/tags/add",
                data: JSON.stringify(tagIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/invoices/myselection/tags/replace (PUT)
        BatchReplaceTags: function (tagIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/invoices/myselection/tags/replace",
                data: JSON.stringify(tagIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/invoices/{invoiceId}/products (PUT)
        ProductCreate: function (invoiceId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/invoices/" + invoiceId + "/products",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/invoices/{invoiceId}/products/{productId} (DELETE)
        ProductDelete: function (invoiceId, productId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/invoices/" + invoiceId + "/products/" + productId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProductsByParentDto>
        // /api/v1/invoices/{invoiceId}/products (GET)
        ProductsGet: function (invoiceId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/" + invoiceId + "/products"
            });
        },

        // Returns System.Threading.Tasks.Task
        // /api/v1/invoices/{invoiceId}/products/sort (POST)
        ProductsUpdateSort: function (invoiceId, ids) {
            const apiUrl = "/api/v1/invoices/" + invoiceId + "/products/sort";
            const params = { "ids": ids };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/invoices/{invoiceId}/products/{productId} (POST)
        ProductUpdate: function (invoiceId, productId, data) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/invoices/" + invoiceId + "/products/" + productId,
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/invoices/exceltemplateforlistview (POST)
        CreateExcelTemplateForListView: function (excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/invoices/exceltemplateforlistview",
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ExcelTemplateForListViewDto>
        // /api/v1/invoices/exceltemplateforlistview/{id} (GET)
        ExcelTemplateForListView: function (id) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/invoices/exceltemplateforlistview/{id} (DELETE)
        ExcelTemplateForListViewDelete: function (id) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/invoices/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/invoices/export/item/{invoiceId}/{exportAction}/{clientFileName} (GET)
        ExportItem: function (invoiceId, exportAction, clientFileName, wordTemplateId, preferredCulture) {
            const apiUrl = "/api/v1/invoices/export/item/" + invoiceId + "/" + exportAction + "/" + clientFileName;
            const params = { "wordTemplateId": wordTemplateId, "preferredCulture": preferredCulture };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/invoices/exceltemplateforlistview/{id} (POST)
        UpdateExcelTemplateForListView: function (id, excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/invoices/exceltemplateforlistview/" + id,
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.InvoiceDto>
        // /api/v1/invoices/{sourceDocumentId}/copy (PUT)
        Copy: function (sourceDocumentId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/invoices/" + sourceDocumentId + "/copy",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.InvoiceDto>
        // /api/v1/invoices (PUT)
        Create: function (invoice) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/invoices",
                data: JSON.stringify(invoice),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.Int32>
        // /api/v1/invoices/{invoiceId}/payments (PUT)
        CreatePayment: function (invoiceId, obj) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/invoices/" + invoiceId + "/payments",
                data: JSON.stringify(obj),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/invoices/{invoiceId}/payments/{paymentId} (DELETE)
        DeletePayment: function (invoiceId, paymentId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/invoices/" + invoiceId + "/payments/" + paymentId
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/invoices/export/list/custom/temporarysecretary1/{fileName} (GET)
        ExportCustomInvoiceListForTemporarySecretary1: function (fileName, page) {
            const apiUrl = "/api/v1/invoices/export/list/custom/temporarysecretary1/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/invoices/export/list/custom/temporarysecretary2/{fileName} (GET)
        ExportCustomInvoiceListForTemporarySecretary2: function (fileName, page) {
            const apiUrl = "/api/v1/invoices/export/list/custom/temporarysecretary2/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/invoices/export/list/custom/salestax/{fileName} (GET)
        ExportCustomInvoiceListSalesTax: function (fileName, page) {
            const apiUrl = "/api/v1/invoices/export/list/custom/salestax/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/invoices/export/list/custom/general/{fileName} (GET)
        ExportCustomListGeneral: function (fileName, page) {
            const apiUrl = "/api/v1/invoices/export/list/custom/general/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/invoices/export/list/{fileName} (GET)
        ExportList: function (fileName, page, templateId) {
            const apiUrl = "/api/v1/invoices/export/list/" + fileName;
            const params = { "page": page, "templateId": templateId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.InvoiceFiltersDto>
        // /api/v1/invoices/filters (GET)
        GetFilters: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/filters"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.InvoiceForListDto>>>
        // /api/v1/invoices (GET)
        GetInvoices: function (page, per_page, filters) {
            const apiUrl = "/api/v1/invoices";
            const params = { "page": page, "per_page": per_page, "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProcessedEmailTemplateDto>
        // /api/v1/invoices/emails/templates/{emailTemplateId}/process/{invoiceId} (GET)
        GetProcessedEmailTemplate: function (emailTemplateId, invoiceId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/emails/templates/" + emailTemplateId + "/process/" + invoiceId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.GroupedDocumentTotalsResultDto>
        // /api/v1/invoices/stats/totalsdata (GET)
        GetTotalsData: function (bookkeepingId, period) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/stats/totalsdata"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TotalsPerOwnerDto>
        // /api/v1/invoices/stats/totalperowner (GET)
        GetTotalsPerOwner: function (bookkeepingId, period, selectionStart, selectionEnd) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/stats/totalperowner"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/invoices/{invoiceId}/shows/link (PUT)
        LinkShows: function (invoiceId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/invoices/" + invoiceId + "/shows/link",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ExportOptionDto>>
        // /api/v1/invoices/exports (GET)
        ListExports: function (filters) {
            const apiUrl = "/api/v1/invoices/exports";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.PaymentsDto>
        // /api/v1/invoices/{invoiceId}/payments (GET)
        ListPayments: function (invoiceId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/" + invoiceId + "/payments"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.RelatedEmailAddressEntryDto>>
        // /api/v1/invoices/{documentId}/related/emailaddress (GET)
        ListRelatedEmailAddress: function (documentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/" + documentId + "/related/emailaddress"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.RelatedFilesDto>
        // /api/v1/invoices/{invoiceId}/files/related (GET)
        ListRelatedFiles: function (invoiceId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/" + invoiceId + "/files/related"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/invoices/{invoiceId}/lock (POST)
        Lock: function (invoiceId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/invoices/" + invoiceId + "/lock"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/invoices/lockpermonth (POST)
        LockPerMonth: function (bookkeepingId, month, year) {
            const apiUrl = "/api/v1/invoices/lockpermonth";
            const params = { "bookkeepingId": bookkeepingId, "month": month, "year": year };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Web.Http.IHttpActionResult>
        // /api/v1/invoices/{invoiceId}/makecredit (POST)
        MakeCredit: function (invoiceId, makeCredit) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/invoices/" + invoiceId + "/makecredit",
                data: JSON.stringify(makeCredit),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AddressBookEntryDto>>
        // /api/v1/invoices/{documentId}/relatedcontacts (GET)
        RelatedContacts: function (documentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/" + documentId + "/relatedcontacts"
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/invoices/sendsubscriptioninvoice/{invoiceId} (GET)
        SendSubscriptionInvoice: function (invoiceId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/sendsubscriptioninvoice/" + invoiceId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ShowsByParentDto>
        // /api/v1/invoices/{invoiceId}/shows/{showId}/unlink (PUT)
        UnlinkShow: function (invoiceId, showId) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/invoices/" + invoiceId + "/shows/" + showId + "/unlink"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/invoices/{invoiceId}/unlock (POST)
        Unlock: function (invoiceId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/invoices/" + invoiceId + "/unlock"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.InvoiceDto>
        // /api/v1/invoices/{invoiceId} (POST)
        Update: function (invoiceId, invoice) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/invoices/" + invoiceId,
                data: JSON.stringify(invoice),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.Int32>
        // /api/v1/invoices/{invoiceId}/payments/{paymentId} (POST)
        UpdatePayment: function (invoiceId, paymentId, data) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/invoices/" + invoiceId + "/payments/" + paymentId,
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/invoices/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/invoices/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/invoices/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/invoices/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.InvoiceFiltersDto>>
        // /api/v1/invoices/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/invoices/{invoiceId}/emails/{emailId} (DELETE)
        DeleteEmail: function (invoiceId, emailId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/invoices/" + invoiceId + "/emails/" + emailId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.EmailMessageDto>>>
        // /api/v1/invoices/{invoiceId}/emails (GET)
        GetEmails: function (invoiceId, page, per_page) {
            const apiUrl = "/api/v1/invoices/" + invoiceId + "/emails";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.KeyValueSublabelDto>>
        // /api/v1/invoices/emails/templates (GET)
        ListEmailTemplates: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/invoices/emails/templates"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/invoices/{invoiceId}/emails (POST)
        SendEmail: function (email, invoiceId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/invoices/" + invoiceId + "/emails",
                data: JSON.stringify(email),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.FileDto>>>
        // /api/v1/invoices/{invoiceId}/files (GET)
        FilesGet: function (invoiceId, page, per_page) {
            const apiUrl = "/api/v1/invoices/" + invoiceId + "/files";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },
    },

    Itinerary: {
        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/itinerary/export/{fileName} (POST)
        Export: function (fileName, dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/itinerary/export/" + fileName,
                data: JSON.stringify(dto),
                processData: false
            });
        },

    },

    MessageTemplate: {
        // Returns System.Threading.Tasks.Task<System.Int32>
        // /api/v1/messagetemplate/create (POST)
        CreateMessageTemplate: function (type) {
            const apiUrl = "/api/v1/messagetemplate/create";
            const params = { "type": type };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/messagetemplate/{messageTemplateId}/delete (DELETE)
        DeleteMessageTemplate: function (messageTemplateId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/messagetemplate/" + messageTemplateId + "/delete"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/messagetemplate/translation/delete (POST)
        DeleteMessageTemplateTranslation: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/messagetemplate/translation/delete",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.MessageTemplateDto>
        // /api/v1/messagetemplate/{messageTemplateId} (GET)
        GetMessageTemplate: function (messageTemplateId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/messagetemplate/" + messageTemplateId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.MessageTemplateDto>>>
        // /api/v1/messagetemplate/list (POST)
        ListMessageTemplate: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/messagetemplate/list",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.SetMessageTemplateTranslationOutcomeDto>
        // /api/v1/messagetemplate/translation/set (POST)
        SetMessageTemplateTranslation: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/messagetemplate/translation/set",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.UpdateMessageTemplateOutcomeDto>
        // /api/v1/messagetemplate/update (POST)
        UpdateMessageTemplate: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/messagetemplate/update",
                data: JSON.stringify(dto),
                processData: false
            });
        },

    },

    NorwegianCompanyRegister: {
        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.NorwegianCompanyRegistrationDto>>
        // /api/v1/norwegiancompanyregister/textsearch (GET)
        TextSearch: function (query, size) {
            const apiUrl = "/api/v1/norwegiancompanyregister/textsearch";
            const params = { "query": query, "size": size };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

    },

    Notifications: {
        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.NotificationDto>>>
        // /api/v1/notifications (GET)
        GetNotifications: function (forMobile, page, per_page) {
            const apiUrl = "/api/v1/notifications";
            const params = { "forMobile": forMobile, "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/notifications/hide (POST)
        Hide: function (notifications) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/notifications/hide",
                data: JSON.stringify(notifications),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/notifications/allread (POST)
        MarkAllRead: function (channel) {
            const apiUrl = "/api/v1/notifications/allread";
            const params = { "channel": channel };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/notifications/allseen (POST)
        MarkAllSeen: function (forMobile) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/notifications/allseen"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/notifications/read/{read} (POST)
        MarkRead: function (notifications, read) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/notifications/read/" + read,
                data: JSON.stringify(notifications),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/notifications/sendbadge (POST)
        SendBadge: function (recipientId, badgeCount) {
            const apiUrl = "/api/v1/notifications/sendbadge";
            const params = { "recipientId": recipientId, "badgeCount": badgeCount };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/notifications/sendtestnotification (POST)
        SendTestNotification: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/notifications/sendtestnotification",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.NotificationSettingsDto>
        // /api/v1/notifications/settings (POST)
        UpdateSettings: function (settings) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/notifications/settings",
                data: JSON.stringify(settings),
                processData: false
            });
        },

    },

    ProductGroups: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/productgroups/{productGroupId} (DELETE)
        Delete: function (productGroupId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/productgroups/" + productGroupId
            });
        },

    },

    Productions: {
        // Returns System.Threading.Tasks.Task<System.Int32>
        // /api/v1/productions/{productionId}/copy (POST)
        Copy: function (productionId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/productions/" + productionId + "/copy"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/productions/exceltemplateforlistview (POST)
        CreateExcelTemplateForListView: function (excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/productions/exceltemplateforlistview",
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ExcelTemplateForListViewDto>
        // /api/v1/productions/exceltemplateforlistview/{id} (GET)
        ExcelTemplateForListView: function (id) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/productions/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/productions/exceltemplateforlistview/{id} (DELETE)
        ExcelTemplateForListViewDelete: function (id) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/productions/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/productions/export/list/{fileName} (GET)
        ExportList: function (fileName, page, templateId) {
            const apiUrl = "/api/v1/productions/export/list/" + fileName;
            const params = { "page": page, "templateId": templateId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.ProductionForListDto>>>
        // /api/v1/productions (GET)
        GetProductions: function (page, per_page, filters) {
            const apiUrl = "/api/v1/productions";
            const params = { "page": page, "per_page": per_page, "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ExportOptionDto>>
        // /api/v1/productions/exports (GET)
        ListExports: function (filters) {
            const apiUrl = "/api/v1/productions/exports";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/productions/exceltemplateforlistview/{id} (POST)
        UpdateExcelTemplateForListView: function (id, excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/productions/exceltemplateforlistview/" + id,
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/productions/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/productions/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/productions/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/productions/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.ProductionFiltersDto>>
        // /api/v1/productions/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/productions/filterpresets/"
            });
        },
    },

    ProductTemplates: {
        // Returns System.Threading.Tasks.Task<System.Int32>
        // /api/v1/producttemplates/{productTemplateId}/copy (POST)
        Copy: function (productTemplateId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/producttemplates/" + productTemplateId + "/copy"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/producttemplates/{productTemplateId} (DELETE)
        Delete: function (productTemplateId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/producttemplates/" + productTemplateId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.ProductTemplateDto>>>
        // /api/v1/producttemplates (GET)
        GetProductTemplates: function (keyword, productGroupId, page, per_page) {
            const apiUrl = "/api/v1/producttemplates";
            const params = { "keyword": keyword, "productGroupId": productGroupId, "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

    },

    Promoters: {
        // Returns System.Threading.Tasks.Task<SystemOne.Dto.PromoterDto>
        // /api/v1/promoters/{promoterId} (GET)
        GetDetails: function (promoterId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/promoters/" + promoterId
            });
        },

    },

    PurchaseAgreements: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/purchaseagreements/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/purchaseagreements/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/purchaseagreements/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/purchaseagreements/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.PurchaseAgreementFiltersDto>>
        // /api/v1/purchaseagreements/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/purchaseagreements/exceltemplateforlistview (POST)
        CreateExcelTemplateForListView: function (excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/purchaseagreements/exceltemplateforlistview",
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ExcelTemplateForListViewDto>
        // /api/v1/purchaseagreements/exceltemplateforlistview/{id} (GET)
        ExcelTemplateForListView: function (id) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/purchaseagreements/exceltemplateforlistview/{id} (DELETE)
        ExcelTemplateForListViewDelete: function (id) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/purchaseagreements/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/purchaseagreements/export/item/{purchaseAgreementId}/{exportAction}/{clientFileName} (GET)
        ExportItem: function (purchaseAgreementId, exportAction, clientFileName, wordTemplateId, preferredCulture) {
            const apiUrl = "/api/v1/purchaseagreements/export/item/" + purchaseAgreementId + "/" + exportAction + "/" + clientFileName;
            const params = { "wordTemplateId": wordTemplateId, "preferredCulture": preferredCulture };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/purchaseagreements/exceltemplateforlistview/{id} (POST)
        UpdateExcelTemplateForListView: function (id, excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/purchaseagreements/exceltemplateforlistview/" + id,
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.PurchaseAgreementFiltersDto>
        // /api/v1/purchaseagreements/filters (GET)
        GetFilters: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/filters"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProcessedEmailTemplateDto>
        // /api/v1/purchaseagreements/emails/templates/{emailTemplateId}/process/{purchaseAgreementId} (GET)
        GetProcessedEmailTemplate: function (emailTemplateId, purchaseAgreementId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/emails/templates/" + emailTemplateId + "/process/" + purchaseAgreementId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.DocumentForListDto>>>
        // /api/v1/purchaseagreements (GET)
        GetPurchaseAgreements: function (page, per_page, filters) {
            const apiUrl = "/api/v1/purchaseagreements";
            const params = { "page": page, "per_page": per_page, "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ExportOptionDto>>
        // /api/v1/purchaseagreements/exports (GET)
        ListExports: function (filters) {
            const apiUrl = "/api/v1/purchaseagreements/exports";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.RelatedEmailAddressEntryDto>>
        // /api/v1/purchaseagreements/{documentId}/related/emailaddress (GET)
        ListRelatedEmailAddress: function (documentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/" + documentId + "/related/emailaddress"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.RelatedFilesDto>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/files/related (GET)
        ListRelatedFiles: function (purchaseAgreementId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId + "/files/related"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AddressBookEntryDto>>
        // /api/v1/purchaseagreements/{documentId}/relatedcontacts (GET)
        RelatedContacts: function (documentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/" + documentId + "/relatedcontacts"
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/purchaseagreements/myselection/tags/add (PUT)
        BatchAddTags: function (tagIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/purchaseagreements/myselection/tags/add",
                data: JSON.stringify(tagIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/purchaseagreements/myselection/tags/replace (PUT)
        BatchReplaceTags: function (tagIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/purchaseagreements/myselection/tags/replace",
                data: JSON.stringify(tagIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/emails/{emailId} (DELETE)
        DeleteEmail: function (purchaseAgreementId, emailId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId + "/emails/" + emailId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.EmailMessageDto>>>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/emails (GET)
        GetEmails: function (purchaseAgreementId, page, per_page) {
            const apiUrl = "/api/v1/purchaseagreements/" + purchaseAgreementId + "/emails";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.KeyValueSublabelDto>>
        // /api/v1/purchaseagreements/emails/templates (GET)
        ListEmailTemplates: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/emails/templates"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/emails (POST)
        SendEmail: function (email, purchaseAgreementId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId + "/emails",
                data: JSON.stringify(email),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.FileDto>>>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/files (GET)
        FilesGet: function (purchaseAgreementId, page, per_page) {
            const apiUrl = "/api/v1/purchaseagreements/" + purchaseAgreementId + "/files";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/products (PUT)
        ProductCreate: function (purchaseAgreementId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId + "/products",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/products/{productId} (DELETE)
        ProductDelete: function (purchaseAgreementId, productId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId + "/products/" + productId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProductsByParentDto>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/products (GET)
        ProductsGet: function (purchaseAgreementId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId + "/products"
            });
        },

        // Returns System.Threading.Tasks.Task
        // /api/v1/purchaseagreements/{purchaseAgreementId}/products/sort (POST)
        ProductsUpdateSort: function (purchaseAgreementId, ids) {
            const apiUrl = "/api/v1/purchaseagreements/" + purchaseAgreementId + "/products/sort";
            const params = { "ids": ids };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/products/{productId} (POST)
        ProductUpdate: function (purchaseAgreementId, productId, data) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId + "/products/" + productId,
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.DocumentDto>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/copy (PUT)
        Copy: function (purchaseAgreementId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId + "/copy",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.DocumentDto>
        // /api/v1/purchaseagreements (PUT)
        Create: function (purchaseAgreement) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/purchaseagreements",
                data: JSON.stringify(purchaseAgreement),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/purchaseagreements/signing/fromshow/{showId}/create (POST)
        CreateESignFromShow: function (showId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/purchaseagreements/signing/fromshow/" + showId + "/create"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/purchaseagreements/export/list/custom/general/{fileName} (GET)
        ExportCustomListGeneral: function (fileName, page) {
            const apiUrl = "/api/v1/purchaseagreements/export/list/custom/general/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/purchaseagreements/export/list/{fileName} (GET)
        ExportList: function (fileName, page, templateId) {
            const apiUrl = "/api/v1/purchaseagreements/export/list/" + fileName;
            const params = { "page": page, "templateId": templateId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.GroupedDocumentTotalsResultDto>
        // /api/v1/purchaseagreements/stats/totalsdata (GET)
        GetTotalsData: function (bookkeepingId, period) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/stats/totalsdata"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TotalsPerOwnerDto>
        // /api/v1/purchaseagreements/stats/totalperowner (GET)
        GetTotalsPerOwner: function (bookkeepingId, period, selectionStart, selectionEnd) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/purchaseagreements/stats/totalperowner"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/shows/link (PUT)
        LinkShows: function (purchaseAgreementId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId + "/shows/link",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ShowsByParentDto>
        // /api/v1/purchaseagreements/{purchaseAgreementId}/shows/{showId}/unlink (PUT)
        UnlinkShow: function (purchaseAgreementId, showId) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId + "/shows/" + showId + "/unlink"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.DocumentDto>
        // /api/v1/purchaseagreements/{purchaseAgreementId} (POST)
        Update: function (purchaseAgreementId, purchaseAgreement) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/purchaseagreements/" + purchaseAgreementId,
                data: JSON.stringify(purchaseAgreement),
                processData: false
            });
        },

    },

    Quotes: {

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/quotes/myselection/tags/add (PUT)
        BatchAddTags: function (tagIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/quotes/myselection/tags/add",
                data: JSON.stringify(tagIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/quotes/myselection/tags/replace (PUT)
        BatchReplaceTags: function (tagIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/quotes/myselection/tags/replace",
                data: JSON.stringify(tagIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/quotes/{quoteId}/emails/{emailId} (DELETE)
        DeleteEmail: function (quoteId, emailId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/quotes/" + quoteId + "/emails/" + emailId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.EmailMessageDto>>>
        // /api/v1/quotes/{quoteId}/emails (GET)
        GetEmails: function (quoteId, page, per_page) {
            const apiUrl = "/api/v1/quotes/" + quoteId + "/emails";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.KeyValueSublabelDto>>
        // /api/v1/quotes/emails/templates (GET)
        ListEmailTemplates: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/emails/templates"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/quotes/{quoteId}/emails (POST)
        SendEmail: function (email, quoteId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/quotes/" + quoteId + "/emails",
                data: JSON.stringify(email),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/quotes/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/quotes/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/quotes/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/quotes/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.QuoteFiltersDto>>
        // /api/v1/quotes/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/quotes/exceltemplateforlistview (POST)
        CreateExcelTemplateForListView: function (excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/quotes/exceltemplateforlistview",
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ExcelTemplateForListViewDto>
        // /api/v1/quotes/exceltemplateforlistview/{id} (GET)
        ExcelTemplateForListView: function (id) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/quotes/exceltemplateforlistview/{id} (DELETE)
        ExcelTemplateForListViewDelete: function (id) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/quotes/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/quotes/export/item/{quoteId}/{exportAction}/{clientFileName} (GET)
        ExportItem: function (quoteId, exportAction, clientFileName, wordTemplateId, preferredCulture) {
            const apiUrl = "/api/v1/quotes/export/item/" + quoteId + "/" + exportAction + "/" + clientFileName;
            const params = { "wordTemplateId": wordTemplateId, "preferredCulture": preferredCulture };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/quotes/exceltemplateforlistview/{id} (POST)
        UpdateExcelTemplateForListView: function (id, excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/quotes/exceltemplateforlistview/" + id,
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/quotes/{quoteId}/products (PUT)
        ProductCreate: function (quoteId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/quotes/" + quoteId + "/products",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/quotes/{quoteId}/products/{productId} (DELETE)
        ProductDelete: function (quoteId, productId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/quotes/" + quoteId + "/products/" + productId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProductsByParentDto>
        // /api/v1/quotes/{quoteId}/products (GET)
        ProductsGet: function (quoteId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/" + quoteId + "/products"
            });
        },

        // Returns System.Threading.Tasks.Task
        // /api/v1/quotes/{quoteId}/products/sort (POST)
        ProductsUpdateSort: function (quoteId, ids) {
            const apiUrl = "/api/v1/quotes/" + quoteId + "/products/sort";
            const params = { "ids": ids };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/quotes/{quoteId}/products/{productId} (POST)
        ProductUpdate: function (quoteId, productId, data) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/quotes/" + quoteId + "/products/" + productId,
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.QuoteFiltersDto>
        // /api/v1/quotes/filters (GET)
        GetFilters: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/filters"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProcessedEmailTemplateDto>
        // /api/v1/quotes/emails/templates/{emailTemplateId}/process/{quoteId} (GET)
        GetProcessedEmailTemplate: function (emailTemplateId, quoteId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/emails/templates/" + emailTemplateId + "/process/" + quoteId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.DocumentForListDto>>>
        // /api/v1/quotes (GET)
        GetQuotes: function (page, per_page, filters) {
            const apiUrl = "/api/v1/quotes";
            const params = { "page": page, "per_page": per_page, "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ExportOptionDto>>
        // /api/v1/quotes/exports (GET)
        ListExports: function (filters) {
            const apiUrl = "/api/v1/quotes/exports";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.RelatedEmailAddressEntryDto>>
        // /api/v1/quotes/{documentId}/related/emailaddress (GET)
        ListRelatedEmailAddress: function (documentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/" + documentId + "/related/emailaddress"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.RelatedFilesDto>
        // /api/v1/quotes/{quoteId}/files/related (GET)
        ListRelatedFiles: function (quoteId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/" + quoteId + "/files/related"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AddressBookEntryDto>>
        // /api/v1/quotes/{documentId}/relatedcontacts (GET)
        RelatedContacts: function (documentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/" + documentId + "/relatedcontacts"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.FileDto>>>
        // /api/v1/quotes/{quoteId}/files (GET)
        FilesGet: function (quoteId, page, per_page) {
            const apiUrl = "/api/v1/quotes/" + quoteId + "/files";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.DocumentDto>
        // /api/v1/quotes/{quoteId}/copy (PUT)
        Copy: function (quoteId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/quotes/" + quoteId + "/copy",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.DocumentDto>
        // /api/v1/quotes (PUT)
        Create: function (quote) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/quotes",
                data: JSON.stringify(quote),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/quotes/signing/fromshow/{showId}/create (POST)
        CreateESignFromShow: function (showId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/quotes/signing/fromshow/" + showId + "/create"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/quotes/export/list/custom/general/{fileName} (GET)
        ExportCustomListGeneral: function (fileName, page) {
            const apiUrl = "/api/v1/quotes/export/list/custom/general/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/quotes/export/list/{fileName} (GET)
        ExportList: function (fileName, page, templateId) {
            const apiUrl = "/api/v1/quotes/export/list/" + fileName;
            const params = { "page": page, "templateId": templateId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.GroupedDocumentTotalsResultDto>
        // /api/v1/quotes/stats/totalsdata (GET)
        GetTotalsData: function (bookkeepingId, period) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/stats/totalsdata"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TotalsPerOwnerDto>
        // /api/v1/quotes/stats/totalperowner (GET)
        GetTotalsPerOwner: function (bookkeepingId, period, selectionStart, selectionEnd) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/quotes/stats/totalperowner"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/quotes/{quoteId}/shows/link (PUT)
        LinkShows: function (quoteId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/quotes/" + quoteId + "/shows/link",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ShowsByParentDto>
        // /api/v1/quotes/{quoteId}/shows/{showId}/unlink (PUT)
        UnlinkShow: function (quoteId, showId) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/quotes/" + quoteId + "/shows/" + showId + "/unlink"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.DocumentDto>
        // /api/v1/quotes/{quoteId} (POST)
        Update: function (quoteId, quote) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/quotes/" + quoteId,
                data: JSON.stringify(quote),
                processData: false
            });
        },

    },

    Register: {
        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/register/pns (DELETE)
        DeleteByPns: function (pns) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/register/pns",
                data: JSON.stringify(pns),
                processData: false
            });
        },

    },

    Registration: {
        // Returns System.Threading.Tasks.Task<SystemOne.Dto.SelfRegisterResultDto>
        // /api/v1/registration (POST)
        SelfRegisterFromInvitation: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/registration",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.UsernameValidationResultDto>
        // /api/v1/registration/usernameexists/{username} (GET)
        ValidateUsername: function (username) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/registration/usernameexists/" + username
            });
        },

    },

    SalesData: {
        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/salesdata/export/bookkeeping/{bookkeepingId}/{year}/{month}/{type} (GET)
        Export: function (bookkeepingId, year, month, type) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/salesdata/export/bookkeeping/" + bookkeepingId + "/" + year + "/" + month + "/" + type
            });
        },

    },

    Schedule: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/month/filterpresets/ (POST)
        AddMonthFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/schedule/month/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/week/filterpresets/ (POST)
        AddWeekFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/schedule/week/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/year/filterpresets/ (POST)
        AddYearFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/schedule/year/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/month/filterpresets/default/ (DELETE)
        ClearDefaultMonthPreset: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/schedule/month/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/week/filterpresets/default/ (DELETE)
        ClearDefaultWeekPreset: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/schedule/week/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/year/filterpresets/default/ (DELETE)
        ClearDefaultYearPreset: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/schedule/year/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/month/filterpresets/{presetId} (DELETE)
        DeleteMonthPreset: function (presetId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/schedule/month/filterpresets/" + presetId
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/week/filterpresets/{presetId} (DELETE)
        DeleteWeekPreset: function (presetId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/schedule/week/filterpresets/" + presetId
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/year/filterpresets/{presetId} (DELETE)
        DeleteYearPreset: function (presetId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/schedule/year/filterpresets/" + presetId
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/schedule/export/quarterly/list/{fileName} (GET)
        ExportQuarterlyPlanningCommandHandler: function (fileName, page) {
            const apiUrl = "/api/v1/schedule/export/quarterly/list/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Web.Http.IHttpActionResult>
        // /api/v1/schedule/day/ (GET)
        GetByDay: function (filters) {
            const apiUrl = "/api/v1/schedule/day/";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.PlanningByMonthFiltersDto>>
        // /api/v1/schedule/month/filterpresets/ (GET)
        GetMonthPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/schedule/month/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.String, SystemOne.WebApi.Request.PlannerRequestDto>>
        // /api/v1/schedule/month/ (GET)
        GetMonthSchedule: function (filters) {
            const apiUrl = "/api/v1/schedule/month/";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.Collections.Generic.List<SystemOne.Dto.PlannerDayDto>, SystemOne.WebApi.Request.PlannerRequestDto>>
        // /api/v1/schedule/year/ (GET)
        GetQuarterlyPlanner: function (filters) {
            const apiUrl = "/api/v1/schedule/year/";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.PlanningByWeekFiltersDto>>
        // /api/v1/schedule/week/filterpresets/ (GET)
        GetWeekPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/schedule/week/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Web.Http.IHttpActionResult>
        // /api/v1/schedule/week/ (GET)
        GetWeekSchedule: function (filters) {
            const apiUrl = "/api/v1/schedule/week/";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.PlanningByQuarterFiltersDto>>
        // /api/v1/schedule/year/filterpresets/ (GET)
        GetYearPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/schedule/year/filterpresets/"
            });
        },

        // Returns System.Collections.Generic.List<SystemOne.Dto.ExportOptionDto>
        // /api/v1/schedule/year/exports (GET)
        ListExports: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/schedule/year/exports"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.PeekItineraryExportResultDto, SystemOne.Dto.ItineraryExportRequestDto>>
        // /api/v1/schedule/itinerary/export/peek (GET)
        PeekItineraryExport: function (start, end, phases, types, artistIds, contactIds) {
            const apiUrl = "/api/v1/schedule/itinerary/export/peek";
            const params = { "start": start, "end": end, "phases": phases, "types": types, "artistIds": artistIds, "contactIds": contactIds };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/month/filterpresets/default/{presetId} (POST)
        SetDefaultMonthPreset: function (presetId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/schedule/month/filterpresets/default/" + presetId
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/week/filterpresets/default/{presetId} (POST)
        SetDefaultWeekPreset: function (presetId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/schedule/week/filterpresets/default/" + presetId
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/schedule/year/filterpresets/default/{presetId} (POST)
        SetDefaultYearPreset: function (presetId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/schedule/year/filterpresets/default/" + presetId
            });
        },

    },

    Shows: {

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/shows/exceltemplateforlistview (POST)
        CreateExcelTemplateForListView: function (excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/exceltemplateforlistview",
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ExcelTemplateForListViewDto>
        // /api/v1/shows/exceltemplateforlistview/{id} (GET)
        ExcelTemplateForListView: function (id) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/exceltemplateforlistview/{id} (DELETE)
        ExcelTemplateForListViewDelete: function (id) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/shows/exceltemplateforlistview/" + id
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/shows/export/item/{showId}/{exportAction}/{clientFileName} (GET)
        ExportItem: function (showId, exportAction, clientFileName, wordTemplateId, preferredCulture) {
            const apiUrl = "/api/v1/shows/export/item/" + showId + "/" + exportAction + "/" + clientFileName;
            const params = { "wordTemplateId": wordTemplateId, "preferredCulture": preferredCulture };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CreateUpdateExcelTemplateForListViewResultDto>
        // /api/v1/shows/exceltemplateforlistview/{id} (POST)
        UpdateExcelTemplateForListView: function (id, excelTemplateForListView) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/exceltemplateforlistview/" + id,
                data: JSON.stringify(excelTemplateForListView),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.FileDto>>>
        // /api/v1/shows/{showId}/files (GET)
        FilesGet: function (showId, page, per_page) {
            const apiUrl = "/api/v1/shows/" + showId + "/files";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/shows/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/shows/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.ShowFiltersDto>>
        // /api/v1/shows/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/shows/{showId}/products (PUT)
        ProductCreate: function (showId, data) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/shows/" + showId + "/products",
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/shows/{showId}/products/{productId} (DELETE)
        ProductDelete: function (showId, productId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/shows/" + showId + "/products/" + productId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProductsByParentDto>
        // /api/v1/shows/{showId}/products (GET)
        ProductsGet: function (showId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/" + showId + "/products"
            });
        },

        // Returns System.Threading.Tasks.Task
        // /api/v1/shows/{showId}/products/sort (POST)
        ProductsUpdateSort: function (showId, ids) {
            const apiUrl = "/api/v1/shows/" + showId + "/products/sort";
            const params = { "ids": ids };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ProductsByParentDto>>
        // /api/v1/shows/{showId}/products/{productId} (POST)
        ProductUpdate: function (showId, productId, data) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/" + showId + "/products/" + productId,
                data: JSON.stringify(data),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/shows/myselection/tags/add (PUT)
        BatchAddTags: function (tagIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/shows/myselection/tags/add",
                data: JSON.stringify(tagIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/shows/myselection/tags/replace (PUT)
        BatchReplaceTags: function (tagIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/shows/myselection/tags/replace",
                data: JSON.stringify(tagIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/emails/{emailId} (DELETE)
        DeleteEmail: function (showId, emailId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/shows/" + showId + "/emails/" + emailId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.EmailMessageDto>>>
        // /api/v1/shows/{showId}/emails (GET)
        GetEmails: function (showId, page, per_page) {
            const apiUrl = "/api/v1/shows/" + showId + "/emails";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.KeyValueSublabelDto>>
        // /api/v1/shows/emails/templates (GET)
        ListEmailTemplates: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/emails/templates"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/emails (POST)
        SendEmail: function (email, showId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/" + showId + "/emails",
                data: JSON.stringify(email),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId} (POST)
        Approve: function (showId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/" + showId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.ShowConflictsDto, SystemOne.Dto.ShowConflictFiltersDto>>
        // /api/v1/shows/showconflicts (GET)
        CheckShowConflicts: function (query) {
            const apiUrl = "/api/v1/shows/showconflicts";
            const params = { "query": query };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CopyShowResultDto>
        // /api/v1/shows/copy (POST)
        CopyShow: function (show) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/copy"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.CountUnlockedResultDto>
        // /api/v1/shows/countunlocked (GET)
        CountUnlockedShows: function (before, statusIds, bookkeepingIds) {
            const apiUrl = "/api/v1/shows/countunlocked";
            const params = { "before": before, "statusIds": statusIds, "bookkeepingIds": bookkeepingIds };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/shows/{showId}/document (POST)
        CreateDocument: function (showId, doc) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/" + showId + "/document",
                data: JSON.stringify(doc),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.TimetableEntryDto>>
        // /api/v1/shows/{showId}/timetable/entries/add (PUT)
        CreateTimetableEntry: function (showId, dto) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/shows/" + showId + "/timetable/entries/add",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId} (DELETE)
        Delete: function (showId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/shows/" + showId
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/exclusivityclause (DELETE)
        DeleteExclusivityClause: function (showId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/shows/" + showId + "/exclusivityclause"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/shows/export/list/custom/helderwerk/{fileName} (GET)
        ExportCustomShowListForHelderWerk: function (fileName, page) {
            const apiUrl = "/api/v1/shows/export/list/custom/helderwerk/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/shows/export/list/custom/platinum/{fileName} (GET)
        ExportCustomShowListForPlatinum: function (fileName, page) {
            const apiUrl = "/api/v1/shows/export/list/custom/platinum/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/shows/export/list/custom/temporarysecretary/{fileName} (GET)
        ExportCustomShowListForTemporarySecretary: function (fileName, page) {
            const apiUrl = "/api/v1/shows/export/list/custom/temporarysecretary/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/shows/export/list/custom/vanaf2/{fileName} (GET)
        ExportCustomShowListForVanaf2: function (fileName, page) {
            const apiUrl = "/api/v1/shows/export/list/custom/vanaf2/" + fileName;
            const params = { "page": page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Net.Http.HttpResponseMessage>
        // /api/v1/shows/export/list/{fileName} (GET)
        ExportList: function (fileName, page, templateId) {
            const apiUrl = "/api/v1/shows/export/list/" + fileName;
            const params = { "page": page, "templateId": templateId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.GroupedShowTotalsResultDto>
        // /api/v1/shows/totals (POST)
        GetGroupedData: function (request) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/totals",
                data: JSON.stringify(request),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.WebApi.LegacyDto.LegacyShowDto>
        // /api/v1/shows/{showId} (GET)
        GetItem: function (showId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/" + showId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.ShowLastModifiedDto>>>
        // /api/v1/shows/lastmodified (GET)
        GetLastModified: function (page, per_page) {
            const apiUrl = "/api/v1/shows/lastmodified";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.LinkedTravelDto>
        // /api/v1/shows/{showid}/linkedtravel (GET)
        GetLinkedTravel: function (showid) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/" + showid + "/linkedtravel"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ProcessedEmailTemplateDto>
        // /api/v1/shows/emails/templates/{emailTemplateId}/process/{showId} (GET)
        GetProcessedEmailTemplate: function (emailTemplateId, showId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/emails/templates/" + emailTemplateId + "/process/" + showId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.Collections.Generic.List<SystemOne.Dto.ShowForListDto>, SystemOne.WebApi.Request.ShowListRequestDto>>
        // /api/v1/shows (GET)
        GetShows: function (filters, page, per_page) {
            const apiUrl = "/api/v1/shows";
            const params = { "filters": filters, "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.MiniCalendarDto>
        // /api/v1/shows/countformonth/{year}/{month} (GET)
        GetShowsCountForMonth: function (year, month, filters) {
            const apiUrl = "/api/v1/shows/countformonth/" + year + "/" + month;
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TooltipDto>
        // /api/v1/shows/{showId}/tooltip (GET)
        GetToolTip: function (showId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/" + showId + "/tooltip"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/flightsegment/link (GET)
        LinkFlightSegment: function (showId, flightSegmentId) {
            const apiUrl = "/api/v1/shows/" + showId + "/flightsegment/link";
            const params = { "flightSegmentId": flightSegmentId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/groundTransport/link (GET)
        LinkGroundTransport: function (showId, groundTransportId) {
            const apiUrl = "/api/v1/shows/" + showId + "/groundTransport/link";
            const params = { "groundTransportId": groundTransportId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/trainsegment/link (GET)
        LinkTrainSegment: function (showId, trainSegmentId) {
            const apiUrl = "/api/v1/shows/" + showId + "/trainsegment/link";
            const params = { "trainSegmentId": trainSegmentId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ExportOptionDto>>
        // /api/v1/shows/exports (GET)
        ListExports: function (filters) {
            const apiUrl = "/api/v1/shows/exports";
            const params = { "filters": filters };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.RelatedEmailAddressEntryDto>>
        // /api/v1/shows/{showId}/related/emailaddress (GET)
        ListRelatedEmailAddress: function (showId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/" + showId + "/related/emailaddress"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.RelatedFilesDto>
        // /api/v1/shows/{showId}/files/related (GET)
        ListRelatedFiles: function (showId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/" + showId + "/files/related"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ShowWithProductsDto>>
        // /api/v1/shows/showswithproducts (GET)
        ListShowsWithProducts: function (showIds) {
            const apiUrl = "/api/v1/shows/showswithproducts";
            const params = { "showIds": showIds };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.TimetableEntryDto>>
        // /api/v1/shows/{showId}/timetable/entries (GET)
        ListTimetableEntries: function (showId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/" + showId + "/timetable/entries"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ExclusivityClauseDto>
        // /api/v1/shows/{showId}/exclusivityclause (GET)
        LoadExclusivityClause: function (showId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/" + showId + "/exclusivityclause"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Web.Http.IHttpActionResult>
        // /api/v1/shows/{showId}/exclusivityconflicts (GET)
        LoadExclusivityConflicts: function (showId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/" + showId + "/exclusivityconflicts"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/lock (POST)
        Lock: function (showId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/" + showId + "/lock"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.LockResultDto>
        // /api/v1/shows/lock (POST)
        LockShowSelection: function (before, statusIds, bookkeepingIds) {
            const apiUrl = "/api/v1/shows/lock";
            const params = { "before": before, "statusIds": statusIds, "bookkeepingIds": bookkeepingIds };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.AddressBookEntryDto>>
        // /api/v1/shows/{showId}/relatedcontacts (GET)
        RelatedContacts: function (showId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/shows/" + showId + "/relatedcontacts"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ExclusivityClauseDto>
        // /api/v1/shows/exclusivityclauses (POST)
        SaveExclusivityClause: function (clause) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/exclusivityclauses"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/flightsegment/unlink (GET)
        UnlinkFlightSegment: function (showId, flightSegmentId) {
            const apiUrl = "/api/v1/shows/" + showId + "/flightsegment/unlink";
            const params = { "flightSegmentId": flightSegmentId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/groundTransport/unlink (GET)
        UnlinkGroundTransport: function (showId, groundTransportId) {
            const apiUrl = "/api/v1/shows/" + showId + "/groundTransport/unlink";
            const params = { "groundTransportId": groundTransportId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/trainsegment/unlink (GET)
        UnlinkTrainSegment: function (showId, trainSegmentId) {
            const apiUrl = "/api/v1/shows/" + showId + "/trainsegment/unlink";
            const params = { "trainSegmentId": trainSegmentId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/shows/{showId}/unlock (POST)
        Unlock: function (showId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/shows/" + showId + "/unlock"
            });
        },
    },

    StateAlias: {
        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.StateAliasDto>>
        // /api/v1/statealias/unmapped (GET)
        ListUnmapped: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/statealias/unmapped"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/statealias/setcannotmap (POST)
        SetCannotMap: function (command) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/statealias/setcannotmap",
                data: JSON.stringify(command),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/statealias/setstateid (POST)
        SetStateId: function (command) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/statealias/setstateid",
                data: JSON.stringify(command),
                processData: false
            });
        },

    },

    Subscription: {
        // Returns System.Threading.Tasks.Task<SystemOne.Dto.MaximumAccountsReachedResultDto>
        // /api/v1/subscription/CheckMaximumAccountsReached (GET)
        CheckMaximumAccountsReached: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/subscription/CheckMaximumAccountsReached"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.StripeCustomerDto>
        // /api/v1/subscription/paymentmethod/list (GET)
        ListPaymentMethods: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/subscription/paymentmethod/list"
            });
        },

    },

    Tests: {
        // Returns System.String
        // /api/v1/tests/hi (GET)
        HelloWorld: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/tests/hi"
            });
        },

        // Returns System.String
        // /api/v1/tests/exception/sync (GET)
        TestException: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/tests/exception/sync"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/tests/exception/async (GET)
        TestExceptionAsync: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/tests/exception/async"
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/tests/exception/mediator (GET)
        TestExceptionInMediator: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/tests/exception/mediator"
            });
        },

    },

    Timetable: {
        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.TimetableEntryDto>>
        // /api/v1/timetable/entries/{timetableEntryId}/delete (POST)
        DeleteEntry: function (timetableEntryId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/timetable/entries/" + timetableEntryId + "/delete"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.TimetableEntryDto>>
        // /api/v1/timetable/entries/{timetableEntryId}/update (POST)
        UpdateEntry: function (timetableEntryId, dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/timetable/entries/" + timetableEntryId + "/update",
                data: JSON.stringify(dto),
                processData: false
            });
        },

    },

    Trains: {
        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/trains/filterpresets/ (POST)
        AddFilterPreset: function (filterName, filters) {
            const apiUrl = "/api/v1/trains/filterpresets/";
            const params = { "filterName": filterName, "filters": filters };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/trains/filterpresets/default/ (DELETE)
        ClearDefaultFilter: function () {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/trains/filterpresets/default/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.GitHubSelectionTool.PresetInfo<SystemOne.Dto.TrainFiltersDto>>
        // /api/v1/trains/filterpresets/ (GET)
        GetPresets: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/trains/filterpresets/"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TrainDto>
        // /api/v1/trains/{trainId}/segments/create (PUT)
        CreateSegment: function (trainId, dto) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/trains/" + trainId + "/segments/create",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TrainDto>
        // /api/v1/trains/create (POST)
        CreateTrain: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/trains/create",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/trains/{trainId} (DELETE)
        Delete: function (trainId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/trains/" + trainId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TrainDto>
        // /api/v1/trains/segments/{trainSegmentId} (DELETE)
        DeleteSegment: function (trainSegmentId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/trains/segments/" + trainSegmentId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TooltipDto>
        // /api/v1/trains/segments/{trainSegmentId}/tooltip (GET)
        GetToolTip: function (trainSegmentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/trains/segments/" + trainSegmentId + "/tooltip"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TrainDto>
        // /api/v1/trains/{trainId} (GET)
        GetTrain: function (trainId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/trains/" + trainId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TrainDto>
        // /api/v1/trains/segments/{trainSegmentId} (GET)
        GetTrainBySegment: function (trainSegmentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/trains/segments/" + trainSegmentId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.Collections.Generic.List<SystemOne.Dto.TrainJourneyDto>, SystemOne.WebApi.Request.TrainListRequestDto>>
        // /api/v1/trains (GET)
        GetTrains: function (filters, page, per_page) {
            const apiUrl = "/api/v1/trains";
            const params = { "filters": filters, "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.WebApi.LegacyDto.LegacyTrainJourneyDto>
        // /api/v1/trains/{trainId}/segments/{trainSegmentId} (GET)
        GetTrainSegment: function (trainId, trainSegmentId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/trains/" + trainId + "/segments/" + trainSegmentId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TrainStationDto>
        // /api/v1/trains/trainstation (GET)
        GetTrainStation: function (placeId) {
            const apiUrl = "/api/v1/trains/trainstation";
            const params = { "placeId": placeId };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.FileDto>>
        // /api/v1/trains/{trainId}/files (GET)
        ListTrainFiles: function (trainId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/trains/" + trainId + "/files"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TrainDto>
        // /api/v1/trains/segments/{trainSegmentId}/update (POST)
        UpdateSegment: function (trainSegmentId, dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/trains/segments/" + trainSegmentId + "/update",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TrainDto>
        // /api/v1/trains/{trainId}/update (PUT)
        UpdateTrainDetails: function (trainId, dto) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/trains/" + trainId + "/update",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FileUploadResultDto[]>
        // /api/v1/trains/{trainId}/files/upload (POST)
        UploadTrainFiles: function (trainId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/trains/" + trainId + "/files/upload"
            });
        },

    },

    Users: {
        // Returns System.Threading.Tasks.Task<SystemOne.WebApi.LegacyDto.LegacyUserDto>
        // /api/v1/users/user/sociallogin (GET)
        ConnectSocial: function (issuer, token) {
            const apiUrl = "/api/v1/users/user/sociallogin";
            const params = { "issuer": issuer, "token": token };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/users/{environment}/{userId}/deactivate (GET)
        DeactivateAccount: function (environment, userId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/users/" + environment + "/" + userId + "/deactivate"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/users/{userId}/{environment}/artists/{artistId} (DELETE)
        DeleteArtistLink: function (userId, environment, artistId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/users/" + userId + "/" + environment + "/artists/" + artistId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.WebApi.LegacyDto.LegacyUserDto>
        // /api/v1/users/user (GET)
        GetCurrentUser: function () {
            return $.ajax({
                type: "GET",
                url: "/api/v1/users/user"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.UserDto>
        // /api/v1/users/{userId} (GET)
        GetDetails: function (userId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/users/" + userId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.UserUsageDto>
        // /api/v1/users/{userId}/usage (GET)
        GetUserUsage: function (userId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/users/" + userId + "/usage"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/users/{userId}/{environment}/artists/{artistId} (PUT)
        InsertArtistLink: function (userId, environment, artistId) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/users/" + userId + "/" + environment + "/artists/" + artistId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<SystemOne.Dto.UserSmallDto>>
        // /api/v1/users/{environment}/invite (PUT)
        InviteUser: function (environment, dto) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/users/" + environment + "/invite",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ArtistSmallDto>>
        // /api/v1/users/{userId}/{environment}/artists (GET)
        ListArtists: function (userId, environment) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/users/" + userId + "/" + environment + "/artists"
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.UserSmallDto>>
        // /api/v1/users/small/list (POST)
        ListUserSmall: function (dto) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/users/small/list",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.UserForExternalAdminDto>>>
        // /api/v1/users/external (POST)
        PaginatedExternalUsers: function (filters, page, per_page) {
            const apiUrl = "/api/v1/users/external";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params),
                data: JSON.stringify(filters),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.UserForInternalAdminDto>>>
        // /api/v1/users/internal (POST)
        PaginatedInternalUsers: function (filters, page, per_page) {
            const apiUrl = "/api/v1/users/internal";
            const params = { "page": page, "per_page": per_page };
            return $.ajax({
                type: "POST",
                url: window.urlTools.addParameters(apiUrl, params),
                data: JSON.stringify(filters),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.ReactivateAccountResultDto>
        // /api/v1/users/{environment}/{userId}/reactivate (GET)
        ReactivateAccount: function (environment, userId, upgradeSubscription) {
            const apiUrl = "/api/v1/users/" + environment + "/" + userId + "/reactivate";
            const params = { "upgradeSubscription": upgradeSubscription };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/users/{userId}/resetpassword (GET)
        ResetPassword: function (userId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/users/" + userId + "/resetpassword"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FreeFieldProfileDto>
        // /api/v1/users/{userId}/freefieldprofile (PUT)
        SetUserFreeFieldProfile: function (userId, freeFieldProfileId) {
            const apiUrl = "/api/v1/users/" + userId + "/freefieldprofile";
            const params = { "freeFieldProfileId": freeFieldProfileId };
            return $.ajax({
                type: "PUT",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/users/internal/{userId}/bookkeepings/store (PUT)
        StoreBookkeepingsToUser: function (userId, bookkeepingIds) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/users/internal/" + userId + "/bookkeepings/store",
                data: JSON.stringify(bookkeepingIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.TransferUserUsageResultDto>
        // /api/v1/users/{sourceUserId}/usage/transfer (PUT)
        TransferUserUsage: function (sourceUserId, dto) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/users/" + sourceUserId + "/usage/transfer"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.FileUploadResultDto>
        // /api/v1/users/{userId}/avatar (POST)
        UpdateAvatar: function (userId) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/users/" + userId + "/avatar"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/users/{userId}/{environment}/defaultbookkeeping (PUT)
        UpdateDefaultBookkeeping: function (userId, environment, bookkeepingId) {
            const apiUrl = "/api/v1/users/" + userId + "/" + environment + "/defaultbookkeeping";
            const params = { "bookkeepingId": bookkeepingId };
            return $.ajax({
                type: "PUT",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/users/{userId}/external/permissions (PUT)
        UpdateExternalEnvironmentPermissions: function (userId, dto) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/users/" + userId + "/external/permissions",
                data: JSON.stringify(dto),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.UserSmallDto>>
        // /api/v1/users/freefieldprofile (POST)
        UpdateFreeFieldProfileForUsers: function (instruction) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/users/freefieldprofile",
                data: JSON.stringify(instruction),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/users/{userId}/internal/permissions (PUT)
        UpdateInternalEnvironmentPermissions: function (userId, dto) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/users/" + userId + "/internal/permissions",
                data: JSON.stringify(dto),
                processData: false
            });
        },

    },

    UserTasks: {
        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.ApiResponseWrapper<System.Collections.Generic.List<SystemOne.Dto.TasksByUserDto>, System.String>>
        // /api/v1/tasks/byuser (GET)
        ByUser: function (local) {
            const apiUrl = "/api/v1/tasks/byuser";
            const params = { "local": local };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },
    },

    Venues: {
        // Returns System.Threading.Tasks.Task<SystemOne.WebApi.LegacyDto.LegacyVenueDto>
        // /api/v1/venues/{venueId} (GET)
        GetDetails: function (venueId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/venues/" + venueId
            });
        },

    },

    Webforms: {
        // Returns System.Threading.Tasks.Task<System.String>
        // /api/v1/webforms (PUT)
        Create: function () {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/webforms"
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/webforms/{webformId} (DELETE)
        Delete: function (webformId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/webforms/" + webformId
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/webforms/{webformId}/artists/{artistId} (DELETE)
        DeleteArtistLink: function (webformId, artistId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/webforms/" + webformId + "/artists/" + artistId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.WebformDto>
        // /api/v1/webforms/{webformId}/freefields/{freeFieldDefinitionId} (DELETE)
        DeleteFreeFieldForWebform: function (webformId, freeFieldDefinitionId) {
            return $.ajax({
                type: "DELETE",
                url: "/api/v1/webforms/" + webformId + "/freefields/" + freeFieldDefinitionId
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/webforms/{webformId}/artists/{artistId} (PUT)
        InsertArtistLink: function (webformId, artistId) {
            return $.ajax({
                type: "PUT",
                url: "/api/v1/webforms/" + webformId + "/artists/" + artistId
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Infrastructure.PaginationWrapper<System.Collections.Generic.List<SystemOne.Dto.WebformDto>>>
        // /api/v1/webforms (GET)
        List: function (keyword, page, per_page) {
            const apiUrl = "/api/v1/webforms";
            const params = { "keyword": keyword, "page": page, "per_page": per_page };
            return $.ajax({
                type: "GET",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<System.Collections.Generic.List<SystemOne.Dto.ArtistSmallDto>>
        // /api/v1/webforms/{webformId}/artists (GET)
        ListArtists: function (webformId) {
            return $.ajax({
                type: "GET",
                url: "/api/v1/webforms/" + webformId + "/artists"
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.WebformDto>
        // /api/v1/webforms/{webformId}/freefields/{freeFieldDefinitionId} (PUT)
        StoreFreeFieldForWebform: function (webformId, freeFieldDefinitionId, isMandatory) {
            const apiUrl = "/api/v1/webforms/" + webformId + "/freefields/" + freeFieldDefinitionId;
            const params = { "isMandatory": isMandatory };
            return $.ajax({
                type: "PUT",
                url: window.urlTools.addParameters(apiUrl, params)
            });
        },

        // Returns System.Threading.Tasks.Task<SystemOne.Dto.WebformDto>
        // /api/v1/webforms/{webformId}/freefields/sort (POST)
        StoreFreeFieldForWebformSort: function (webformId, freefieldDefinitionIds) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/webforms/" + webformId + "/freefields/sort",
                data: JSON.stringify(freefieldDefinitionIds),
                processData: false
            });
        },

        // Returns System.Threading.Tasks.Task<MediatR.Unit>
        // /api/v1/webforms/{webformId}/css (POST)
        UpdateCSS: function (webformId, value) {
            return $.ajax({
                type: "POST",
                url: "/api/v1/webforms/" + webformId + "/css",
                data: JSON.stringify(value),
                processData: false
            });
        },

    },
};

export { WebApi };

(window as any).WebApi = WebApi;
